import { useSelector } from "react-redux";
import * as adminCreditColDef from "./admin/credited";
import * as adminPendingColDef from "./admin/pending";
import * as adminWithdrawnColDef from "./admin/withdrawn";
import * as hostCreditColDef from "./host/credited";
import * as hostPendingColDef from "./host/pending";
import * as hostWithdrawnColDef from "./host/withdrawn";


export const adminColDef = {
    credited: adminCreditColDef.creditedColDef,
    pending: adminPendingColDef.pendingColDef,
    withdrawn: adminWithdrawnColDef.withdrawnColDef
}
export const hostColDef = {
    credited: hostCreditColDef.creditedColDef,
    pending: hostPendingColDef.pendingColDef,
    withdrawn: hostWithdrawnColDef.withdrawnColDef
}
export const tableLabels = {
    credited: 'Earnings',
    pending: 'Requests',
    withdrawn: 'Withdrawn'
}

export const useColDef = () => {
    const { role } = useSelector(state => state.profile)
    const colDefs = role === 'admin' ? adminColDef : hostColDef
    return colDefs
}
import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Alert from '../shared/alerts';
import xMark from '../../Assets/imgs/x.svg';
import checkMark from '../../Assets/imgs/check.svg';
import MainBackground from "../shared/mainBackground/index";

import { closeAlert } from "../../reduxModules/auth"
import AdminLoginBack from "../shared/AdminLoginBack";
import firebaseAuth from "./firebase";


// const { host: hostname } = window.location;

class Auth extends PureComponent {
    render() {

        let verifyEmailFind = window.location.href;
        let included = verifyEmailFind.includes('mode=verifyEmail');

        let alreadyLoggedIn = false;

        // || included

        if (this.props.loggedIn) {
            // console.log("Logged in");

            if (firebaseAuth().currentUser) {
                alreadyLoggedIn = true
            }

            if (window.location.pathname != '/emailActions' || (included && alreadyLoggedIn)) {
                return (
                    <Redirect to='/bookings' />
                );
            }
        }

        if (this.props.adminLogin) {
            return (
                <div className="container-fluid auth-component">
                    <div className="row auth-row">
                        <div className="col-md-6">
                            <form onSubmit={(e) => e.preventDefault()}>
                                {this.props.children}
                            </form>
                        </div>
                        <div className="col-md-6">
                            <div className="container-fluid pt-5">
                                <AdminLoginBack />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }


        return (
            <div>
                {
                    this.props.error && !this.props.alertClosed ?
                        <div className="container">
                            <Alert onClick={() => this.props.closeAlert()} type="danger" alertImg={xMark} heading="Error" body={this.props.error} />
                        </div>
                        :
                        this.props.verificationSuccess && !this.props.alertClosed ?
                            <div className="container">
                                <Alert onClick={() => this.props.closeAlert()} alertImg={checkMark} type="success" heading="Success" body={this.props.reverification ? "Verification E-mail has been sent again. Please verify your e-mail." : "Verification E-mail has been sent. Please verify your e-mail."} />
                            </div>
                            :
                            null
                }

                <div className="container-fluid auth-component">

                    <div className="row auth-row">
                        <div className="col-md-6">
                            <MainBackground />
                        </div>
                        <div className="col-md-6">
                            <form className="min-100-height h-100" onSubmit={(e) => { e.preventDefault() }}>{this.props.children}</form>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { loggedIn, error, verificationSuccess, reverification, alertClosed } = state.auth;
    return {
        loggedIn,
        error,
        verificationSuccess,
        reverification,
        alertClosed
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeAlert: () => dispatch(closeAlert(true))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
import React, { createRef, useEffect } from 'react';
import $ from 'jquery';
import './style.scss';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer'

/**
 * @deprecated Use `ModalView` with optional `useModalView` hook instead
 */
class Modal extends React.PureComponent {
    constructor(props) {
        super(props);
        /*
        this.loading = this.props.creatingAction;
        this.onSuccess = this.props.successAction;
        this.onError = this.props.errorAction;
        */
        this.container = createRef();
        this.closeModal = this.closeModal.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.props.clearFunction();
    }

    closeModal() {
        this.props.showFlagSetFunction(false);
    }

    handleClickOutside(event) {
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.props.showFlagSetFunction(false);
        }
    }

    render() {
        if (this.props.successAction && !this.props.errorAction) {
            this.closeModal();
        }
        return (
            <div style={(this.props.style == undefined) ? null : this.props.style} className="container profile-container overflow-auto border-radius-0" ref={this.container}>
                <div className={this.props.back_color ? this.props.back_color : "green-back p-3"}>
                    <p className="font-m primary-text-color semi-bold mb-0">{this.props.title}
                        <i className="fa fa-close float-right pointer" onClick={this.closeModal}></i>
                    </p>
                </div>
                {this.props.children}
                <div className="">
                    <div className="px-4 text-right">
                        <a className="global_link-text semi-bold mr-3 pointer" onClick={this.closeModal}>Cancel</a>
                        <button disabled={this.props.creatingAction} className={this.props.button_style !== undefined ? this.props.button_style : "btn global_btn semi-bold modal_width add_min_height no-border-radius"} onClick={this.props.saveFunc}>{this.props.button_text ? this.props.button_text : 'Save'}</button>
                        {
                            this.props.errorAction && !this.props.successAction && !this.props.creatingAction ?
                                <p><small className="text-muted text-danger">Error in Data</small></p>
                            :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal;

/**
 * @usage Use anywhere like this: `useModalView(id).show({children,data})`
 * @usage Modal content can also passed as child function to modal . `<ModalView>({hide,data})=>{<Render>me</Render>}</ModalView>`
 * @returns 
 */
export function ModalView({id, children=null}) {
    const {children:body,hide,data} = useModalView(id);
    if(typeof children !== 'function' && children !== null) {
        throw new Error('ModalView children must be a function')
    }
    const child = children?.({hide, data, children:body}) || typeof body === 'function' ? body?.({hide, data}) : body;
    return <div data-backdrop="true" className="modal fade" tabIndex="2" role="dialog" id={id}>
    <div className="modal-dialog" role="document" style={{width: '50vw', maxWidth: '50vw'}}>
      <div className="modal-content w-100">
            {child}
      </div>
    </div>
  </div>
}
export const useModalView = (modalId='modal-global') => {
    const {show,hide,modalState} = useModalViewStore(state => ({show:state.show, hide:state.hide, modalState:state.modals[modalId]}))
    const showModal = ({children,data}={}) => show(modalId, {children,data})
    const hideModal = () => hide(modalId)
    
    useEffect(() => {
        const show = () => !$(`#${modalId}`).hasClass('show') ? showModal() : null;
        const hide = () => $(`#${modalId}`).hasClass('show') ? hideModal() : null;
        $(`#${modalId}`).on('shown.bs.modal', show);
        $(`#${modalId}`).on('hidden.bs.modal.', hide);
        return () => {
            $(`#${modalId}`).off('shown.bs.modal', show);
            $(`#${modalId}`).off('hidden.bs.modal', hide);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalId])

    return {
        show: showModal,
        hide: hideModal,
        ...modalState
    }
}
const useModalViewStore = create(immer((set, get) => ({
    modals: {},
    show: (modalId, {children,data}) => {
        set(state => {
            $(`#${modalId}`).modal({show:true})
            state.modals[modalId] = state.modals[modalId] || {};
            state.modals[modalId].children = children;
            if(data) {
                state.modals[modalId].data = data
            }
            state.modals[modalId].modalId = modalId;
            state.modals[modalId].shown = true;
        })
    },
    hide: (modalId) => {
            set(state => {
                $(`#${modalId}`).modal('hide')
            state.modals[modalId].children = null;
            state.modals[modalId].shown = false;
        })
    }
})))
import API from "../config/api";

// Action Types

export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';



export const GETTING_PROFILE = 'GETTING_PROFILE';
export const GOT_PROFILE_DATA = 'GOT_PROFILE_DATA';
export const GOT_PROFILE_ERROR = 'GOT_PROFILE_ERROR';



export const ACTION_PROFILE_IMAGE = 'ACTION_PROFILE_IMAGE';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const PAYPAL_ACTION = 'PAYPAL_ACTION';
const UPLOADING = 'UPLOADING';
const UPLOADED_SUCCESS = 'UPLOADED_SUCCESS';
const UPLOADED_ERROR = 'UPLOADED_ERROR';
const CLEAR_PROFILE_DATA = 'CLEAR_PROFILE_DATA';

export const clearProfileData = () => {
    return {
        type: CLEAR_PROFILE_DATA,
    };
};

// Actions

export const getProfile = (token) => {

    return (dispatch, getState) => {
        dispatch({
            type: GETTING_PROFILE,
        });

        if (!token || typeof token !== "string") {
            dispatch({
                type: GOT_PROFILE_ERROR,
                payload: {
                    errorMessage: "Error",
                },
            });
            return
        }


        API.get('/profile', {
            headers: {
                'X-AUTH-TOKEN': token,
            },
        })
            .then(({ data }) => {
                let auth_code = getState().auth;
                // console.log("rememberMe");
                // console.log(auth_code);

                dispatch({
                    type: GOT_PROFILE_DATA,
                    payload: {
                        ...data.result,
                    },
                    rememberMe: auth_code.rememberMe
                });
            })
            .catch(err => {
                console.error("GETTING PROFILES ERROR: ", err);
                dispatch({
                    type: GOT_PROFILE_ERROR,
                    payload: {
                        err: err.response ? err.response.data : err,
                        errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                    },
                });
            });
    };
};


export const updateProfile = (token) => {
    return (dispatch, getState) => {
        dispatch({
            type: UPLOADING,
        });
        let profile = getState().profile;

        let parcel = {
            first_name: profile.first_name,
            last_name: profile.last_name,
            profile_pic: profile.profile_pic
        }

        API.put('/profile', parcel, {
            headers: {
                'X-AUTH-TOKEN': token,
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: UPLOADED_SUCCESS,
                    payload: {
                        ...data,
                    },
                });
            })
            .catch(err => {
                dispatch({
                    type: UPLOADED_ERROR,
                    payload: {
                        err: err.response ? err.response.data : err,
                        errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                    },
                });
            });
    };
};




export const uploadDocs = (token, hostId = null) => {

    return (dispatch, getState) => {
        dispatch({
            type: UPLOADING,
        });

        const state = getState();
        const docs = state.uploadDetails;

        let parcel = {};

        if (docs.profile_img) {
            parcel.profile_pic = docs.profile_img;
        }
        if (docs.paypal) {
            parcel.paypal = docs.paypal;
        }


        let url = `/profile`;
        if (hostId) {
            url += `?user_id=${hostId}`;
            parcel.profile_pic = docs.parking_profile_img;
            parcel.paypal = docs.parking_payPal;
        }

        
        API.put(url, parcel, {
            headers: {
                'X-AUTH-TOKEN': token,
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: UPLOADED_SUCCESS,
                    payload: data
                });

                if (!hostId) {
                    dispatch(getProfile(token));
                }
            })
            .catch(err => {
                console.error("UPLOAD DOCS ERROR: ", err);
                dispatch({
                    type: UPLOADED_ERROR,
                    payload: {
                        err: err.response ? err.response.data : err,
                        errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                    },
                });
            });
    };
};

/*


    REDUCERS


*/

const role = localStorage.getItem('userRole') ? localStorage.getItem('userRole') : "";


const initState = {
    first_name: null,
    last_name: null,
    email: null,
    email_verified: false,
    mobile_number: null,
    mobile_verified: null,
    profile_pic: null,
    paypal: null,
    role: role,
    loading: false,
    error: null,
};
export default function (state = initState, action) {
    switch (action.type) {
        case SET_FIRST_NAME:
        case SET_LAST_NAME:
            const get_ = action.type.indexOf('_');
            const stateHolder = action.type.slice(get_ + 1).toLowerCase();
            return {
                ...state,
                [stateHolder]: action.payload.val,
            };
        case GETTING_PROFILE:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case UPDATE_PROFILE_IMAGE + "_UPLOAD_RUNNING":
            return {
                ...state,
                loading: true,
                error: null,
            };

        case UPDATE_PROFILE_IMAGE + "_UPLOAD_SUCCESS":
            return {
                ...state,
                profile_pic: action.payload.url,
                loading: false,
                error: null,
            };

        case GOT_PROFILE_DATA:

            // console.log("action");
            // console.log(action);

            let isAdmin = window.localStorage.getItem("userRole") === 'admin' ? true : false;

            if (action.payload[0] && action.payload[0].role) {
                window.localStorage.setItem('userRole', action.payload[0].role);
                window.localStorage.setItem('userEmail', action.payload[0].email);
            }
            else {
                window.localStorage.removeItem('userRole');
                window.localStorage.removeItem('userEmail');
            }

            return {
                ...state,
                loading: false,
                error: null,
                ...action.payload[0],
            };

        case GOT_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.errorMessage,
            };
        default:
            return state;
    }
}












const uploadDocsInitState = {
    profile_img: null,
    profile_img_name: null,
    paypal: initState.paypal,
    uploading: false,
    success: false,
    file_progress: 0,
    file_error: null,
    error: null,
    parking_payPal: null,
    parking_profile_img: null
};
export const uploadDocsReducer = (state = uploadDocsInitState, action) => {

    let isAdmin = window.localStorage.getItem("userRole") === 'admin' ? true : false;

    switch (action.type) {

        case CLEAR_PROFILE_DATA:
            return {
                ...state,
                uploading: false,
                error: null,
                success: false,
            };
        case UPLOADING:
            return {
                ...state,
                uploading: true,
                error: null,
                success: false,
            };

        case GOT_PROFILE_DATA:
            // console.log("action paylaod");
            // console.log(action.payload);

            let normalObj = {
                paypal: null,
                profile_img: null
            }
            if (action.payload && action.payload[0]) {
                if (action.payload[0].paypal) {
                    normalObj.paypal = action.payload[0].paypal;
                }
                if (action.payload[0].profile_pic) {
                    normalObj.profile_img = action.payload[0].profile_pic
                }
            }

            return {
                ...state,
                ...normalObj,
                // paypal: action.payload[0].paypal,
                // profile_img: action.payload[0].profile_pic,
            }

        case UPLOADED_SUCCESS:

            return {
                ...state,
                uploading: false,
                success: true,
                error: null,
                paypal: isAdmin ? null : action.payload.result[1].paypal,
                first_name: isAdmin ? null : action.payload.result[1].first_name,
                last_name: isAdmin ? null : action.payload.result[1].last_name,
            };

        case UPLOADED_ERROR:
            return {
                ...state,
                uploading: false,
                success: false,
                error: action.payload.errorMessage,
            };

        case ACTION_PROFILE_IMAGE:

            let obj;
            if (isAdmin) {
                obj = {
                    parking_profile_img: action.payload.files[0],
                    parking_profile_img_name: action.payload.files[0].name,
                }
            } else {
                obj = {
                    profile_img: action.payload.files[0],
                    profile_img_name: action.payload.files[0].name,
                }
            }
            return {
                ...state,
                ...obj,
                uploading: false,
                error: null,
                success: false,
            };

        case ACTION_PROFILE_IMAGE + '_UPLOAD_RUNNING':
            return {
                ...state,
                file_progress: action.payload.progress,
                file_error: null,
                uploading: false,
                error: null,
                success: false,
            };
        case ACTION_PROFILE_IMAGE + '_UPLOAD_ERROR':
            return {
                ...state,
                file_progress: 100,
                file_error: action.payload.errorMessage,
                uploading: false,
                error: null,
                success: false,
            };


        case ACTION_PROFILE_IMAGE + '_UPLOAD_SUCCESS':

            return {
                ...state,
                parking_profile_img: isAdmin ? action.payload.url : state.parking_profile_img,
                profile_img: !isAdmin ? action.payload.url : state.profile_img,
                file_progress: 100,
                file_error: null,
                uploading: false,
                error: null,
                success: false,
            };

        case ACTION_PROFILE_IMAGE + '_REMOVE_IMAGE':
            return {
                ...state,
                profile_img: isAdmin ? state.profile_img : null,
                parking_profile_img: isAdmin ? null : state.parking_profile_img,
                file_progress: 0,
                file_error: null,
                uploading: false,
                error: null,
                success: false,
            };

        case PAYPAL_ACTION:
            return {
                ...state,
                paypal: isAdmin ? state.paypal : action.payload.val,
                parking_payPal: isAdmin ? action.payload.val : state.parking_payPal,
                uploading: false,
                error: null,
                success: false,
            };

        case "ADMIN_PARKING_PROFILE_DETAILS":
            const profile = action.payload;
            // console.log({ a: profile });
            let nwPayPal = {
                paypal: null,
                profile_img: null
            }
            if (profile) {
                if (profile.paypal) {
                    nwPayPal.parking_payPal = profile.paypal;
                }
                if (profile.profilePic) {
                    nwPayPal.parking_profile_img = profile.profilePic
                }
            }
            return {
                ...state,
                ...nwPayPal,
            }
        default:
            return state;
    }
};
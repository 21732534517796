import API from "../config/api";
import { toUTCStamp } from "../lib/timestamp";

// Action Types

const PARKING_STATE_TOGGLE = "PARKING_STATE_TOGGLE";
export const GETTING_PARKINGS = "GETTING_PARKINGS";
export const GET_PARKINGS_ERROR = "GET_PARKINGS_ERROR";
export const GET_PARKINGS_SUCCESS = "GET_PARKINGS_SUCCESS";
const PARKINGS_CACHE_HIT = "PARKINGS_CACHE_HIT";
const PARKINGS_CACHE_MISS = "PARKINGS_CACHE_MISS";
const NOW_GO_TO_PENDING = "NOW_GO_TO_PENDING";

export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const UPDATE_ZIPCODE = "UPDATE_ZIPCODE";
export const UPDATE_TIMEZONE = "UPDATE_TIMEZONE";
export const UPDATE_CITY = "UPDATE_CITY";
export const UPDATE_STATE = "UPDATE_STATE";
export const UPDATE_STREET = "UPDATE_STREET";
export const UPDATE_HOME = "UPDATE_HOME";
export const UPLOAD_HOME_PIC = "HOME_UPLOAD";
export const UPDATE_LAT_LONG = "UPDATE_LAT_LONG";
export const ERROR_LAT_LONG = "ERROR_LAT_LONG";

export const UPDATE_PARKING = "UPDATE_PARKING";
export const UPDATE_RENT = "UPDATE_RENT";
export const UPDATE_DAILY_PRICE_CAP = "UPDATE_DAILY_PRICE_CAP";
export const UPDATE_MINIMUM_BOOKING_TIME = "UPDATE_MINIMUM_BOOKING_TIME";
export const UPDATE_TYPE = "UPDATE_TYPE";
export const UPDATE_INSTRUCTIONS = "UPDATE_INSTRUCTIONS";
export const UPDATE_OPERATOR = "UPDATE_OPERATOR";
export const UPDATE_SPOT_IMAGES = "UPDATE_SPOT_IMAGES";
export const UPDATE_NO_SLOTS = "UPDATE_NO_SPOTS";
export const UPDATE_EVENT_INFORMATION = "UPDATE_EVENT_INFORMATION";
const UPDATE_AVAILABILITY = "UPDATE_AVAILABILITY";
const UPDATE_247 = "UPDATE_247";
const SET_OPERATOR = "SET_OPERATOR";
const MAKING_NEW_PARKING = "MAKING_NEW_PARKING";
const MADE_NEW_PARKING = "MADE_NEW_PARKING";
const NEW_PARKING_ERROR = "NEW_PARKING_ERROR";
const UPDATE_AVAILABILITY_BULK = "UPDATE_AVAILABILITY_BULK";
const EVENT_SELECTED = "EVENT_SELECTED";
const EVENT_REMOVED = "EVENT_REMOVED";
export const CLEAR_PARKING_DETAILS = "CLEAR_PARKING_DETAILS";
export const CLEAR_SLOT_DETAILS = "CLEAR_SLOT_DETAILS";

const GET_HOST_PARKINGS = "GET_HOST_PARKINGS";
const ADMIN_HOSTS_PARKINGS_RESTORE = "ADMIN_HOSTS_PARKINGS_RESTORE";

const SWITCHING_TOGGLE = "SWITCHING_TOGGLE";
export const SWITCHED_STATE = "SWITCHED_STATE";
const SWITCHING_ERROR = "SWITCHING_ERROR";

const UPDATE_TTP = "UPDATE_TTP";
const UPDATE_TTP_SUCCESS = "UPDATE_TTP_SUCCESS";
const UPDATE_TTP_ERROR = "UPDATE_TTP_ERROR";

export const GETTING_TIMEZONE = "GETTING_TIMEZONE";
const GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS";

// Actions

export const toggleParkingAvailability = (parkingId) => {
  return {
    type: PARKING_STATE_TOGGLE,
    payload: {
      parkingId,
    },
  };
};

export const set24Availability = (data) => {
  return {
    type: UPDATE_AVAILABILITY,
    payload: {
      availability: data,
    },
  };
};

export const set24AvailabilityBulk = (data) => {
  return {
    type: UPDATE_AVAILABILITY_BULK,
    payload: {
      availability: data,
    },
  };
};

export const set247 = (bool) => {
  return {
    type: UPDATE_247,
    payload: {
      val: bool,
    },
  };
};

export const setOperator = (name) => {
  return {
    type: SET_OPERATOR,
    payload: {
      val: name,
    },
  };
};

export const restoreParkings = () => {
  return {
    type: ADMIN_HOSTS_PARKINGS_RESTORE,
  };
};

export const clearPopupState = () => {
  return {
    type: "CLEAR_TOASTER_TTP",
  };
};

export const selectEvent = (event_id, id = null) => {
  return {
    type: EVENT_SELECTED,
    payload: {
      id,
      event_id,
    },
  };
};

export const removeEvent = (id) => {
  return {
    type: EVENT_REMOVED,
    payload: {
      id,
    },
  };
};

export const updateEventInfo = (event_id, price, id) => {
  return {
    type: UPDATE_EVENT_INFORMATION,
    payload: {
      event_id,
      price,
      id,
    },
  };
};

export const clearParkingInfo = (type) => {
  return {
    type,
  };
};

/* Async Actions */

export const switchOn = (token, id, bool, hostId = null) => {
  return (dispatch) => {
    dispatch({
      type: SWITCHING_TOGGLE,
      payload: {
        hostId,
        parkingId: id,
        is_unavailable: bool,
      },
    });
    const qParams = new URLSearchParams();
    if (hostId) {
      qParams.append("host_id", hostId);
    }
    qParams.append("parkingId", id);
    API.put(
      `/parkings?${qParams.toString()}`,
      {
        data: {
          addressData: {},
          parkingData: {
            is_unavailable: bool,
          },
        },
      },
      {
        headers: {
          "X-AUTH-TOKEN": token,
        },
      }
    )
      .then(({ data }) => {
        dispatch({
          type: SWITCHED_STATE,
          payload: {
            ...data.result,
          },
        });
        if (hostId) {
          dispatch(getHostParkings(hostId, token));
        }
      })
      .catch((err) => {
        dispatch({
          type: SWITCHING_ERROR,
          payload: {
            err: err.response ? err.response.data : err,
            errorMessage: err.response
              ? err.response.data
                ? err.response.data.error
                  ? err.response.data.error.info
                  : "Error"
                : "Error"
              : "Error",
          },
        });
      });
  };
};

export const deleteSpotImage = async (id) => {
  try {
    let localToken = window.localStorage.getItem("AUTH_TOKEN");
    let sessionToken = window.sessionStorage.getItem("AUTH_TOKEN");
    let token = localToken ? localToken : sessionToken;

    if (!id) {
      console.log("Id is required!");
      return;
    }

    let data = await API.delete(`parkings/spotImage?id=${id}`, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
    });

    // console.log("Deleted");
    // console.log(data);
  } catch (error) {
    console.log("Error");
    console.log(error);
  }
};

export const getParkings = () => {
  return (dispatch,getState) => {
    const token = getState().auth.auth_token
    dispatch({
      type: GETTING_PARKINGS,
    });
    // console.log("Calling");
    // console.log("getParkings");

    API.get(`/parkings`, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: GET_PARKINGS_SUCCESS,
          payload: {
            parkings: {
              ...data,
            },
          },
        });
      })
      .catch((err) => {
        console.error("ERROR while receiving parking data", err);
        dispatch({
          type: GET_PARKINGS_ERROR,
          payload: {
            err: err.response ? err.response.data : err,
            errorMessage: err.response
              ? err.response.data
                ? err.response.data.error
                  ? err.response.data.error.info
                  : "Error"
                : "Error"
              : "Error",
          },
        });
      });
  };
};

export const getParkingsCached = (token, id) => {
  return (dispatch, getState) => {
    // console.log("Calling");
    // console.log("getParkingsCached");

    const { parkings } = getState();
    if (parkings[id] && typeof parkings[id] !== "undefined") {
      dispatch({
        type: PARKINGS_CACHE_HIT,
        payload: {
          val: parkings[id],
        },
      });
    } else {
      dispatch({
        type: PARKINGS_CACHE_MISS,
      });
      dispatch({
        type: GETTING_PARKINGS,
      });

      API.get(`/parkings`, {
        headers: {
          "X-AUTH-TOKEN": token,
        },
      })
        .then(({ data }) => {
          dispatch({
            type: GET_PARKINGS_SUCCESS,

            payload: {
              parkings: {
                ...data,
              },
            },
          });
        })
        .catch((err) => {
          console.error("ERROR while receiving parking data", err);
          dispatch({
            type: GET_PARKINGS_ERROR,
            payload: {
              err: err.response ? err.response.data : err,
              errorMessage: err.response
                ? err.response.data
                  ? err.response.data.error
                    ? err.response.data.error.info
                    : "Error"
                  : "Error"
                : "Error",
            },
          });
        });
    }
  };
};

export const getHostParkings = (hostId, token, parkingId = null) => {
  return (dispatch) => {
    dispatch({
      type: GET_HOST_PARKINGS,
    });
    API.get(`/parkings?host_id=${hostId}`, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: GET_PARKINGS_SUCCESS,
          payload: {
            parkings: {
              ...data,
            },
          },
        });

        if (data.success && data.result && parkingId) {
          let parkings = data.result;
          dispatch({
            type: "HOST_PARKINGS_CACHE_HIT",
            payload: parkings[parkingId],
          });
        }
      })
      .catch((err) => {
        console.error("ERROR WHILE GETTING HOST RELATED PARKINGS", err);
       dispatch({
          type: GET_PARKINGS_ERROR,
          payload: {
            errorMessage: err.message,
          },
        });
      });
  };
};
const GET_HOST_PARKINGS_BASIC = "GET_HOST_PARKINGS_BASIC";
const GET_HOST_PARKINGS_BASIC_SUCCESS = "GET_HOST_PARKINGS_BASIC_SUCCESS";
const GET_HOST_PARKINGS_BASIC_ERROR = "GET_HOST_PARKINGS_BASIC_ERROR";
const initialStateBasicParkings = {
  loading: false,
  error: false,
  errorMessage: "",
  parkings: {},
}
export const adminParkingsBasic = (state = initialStateBasicParkings, action) => {
  switch (action.type) {
  case GET_HOST_PARKINGS_BASIC:
    return {
      ...state,
      loading: true,
      error: false,
      errorMessage: "",
    };
  case GET_HOST_PARKINGS_BASIC_SUCCESS:
    return {
      ...state,
      loading: false,
      error: false,
      errorMessage: "",
      parkings: action.parkings.result,
      
    };
  case GET_HOST_PARKINGS_BASIC_ERROR:
    return {
      ...state,
      loading: false,
      error: true,
      errorMessage: action.payload.errorMessage,
    };
  default:
    return state;
  }
};
export const getAdminParkings = (parkingId = null) => {
  return async (dispatch,getState) => {
    dispatch({
      type: GET_HOST_PARKINGS_BASIC
    });
    const token = getState().auth.auth_token;
    try {
      const {data} = await API.get(`/parkings/admindata?limit=1000`, {
        headers: {
          "X-AUTH-TOKEN": token,
        },
      });
      dispatch({
        type: GET_HOST_PARKINGS_BASIC_SUCCESS,
          parkings: {
            spots: true,
            result:  Object.fromEntries(data.result.spotCodes.map((x) => [x.id, {...x, host_name: `${x.last_name}, ${x.first_name}`}]))
          }
        })
    } catch (err) {
        console.error("ERROR WHILE GETTING HOST RELATED PARKINGS", err);
        dispatch({
          type: GET_HOST_PARKINGS_BASIC_ERROR,
          payload: {
            errorMessage: err.message,
          },
        });
      }
  };
};

export const getHostParkingsCached = (hostId, parkingId, token) => {
  return (dispatch, getState) => {
    const parkings = getState().parkings;

    if (parkings[parkingId]) {
      let availability = parkings[parkingId].availability;

      for (const obj of availability) {
        if (obj.is_event) {
          dispatch(updateEventInfo(obj.event_id, obj.fare, obj.id));
        }
      }

      dispatch({
        type: "HOST_PARKINGS_CACHE_HIT",
        payload: parkings[parkingId],
      });
    } else {
      dispatch(getHostParkings(hostId, token, parkingId));
    }
  };
};

export const updateTTP = (token, parkingId, val) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TTP,
    });
    const parcel = {
      data: {
        addressData: {},
        parkingData: {
          ttp_enrolled: String(val),
        },
      },
    };
    API.put(`/parkings?parkingId=${parkingId}`, parcel, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_TTP_SUCCESS,
        });
        // dispatch(getParkings(token));
      })
      .catch((e) => {
        console.log(e);
        dispatch({
          type: UPDATE_TTP_ERROR,
          payload: {
            err: e.response ? e.response.data : e,
            errorMessage: e.response
              ? e.response.data
                ? e.response.data.error
                  ? e.response.data.error.info
                  : "Error"
                : "Error"
              : "Error",
          },
        });
      });
  };
};

export const makeNewParking = (token) => {
  /* Verification */
  return (dispatch, getState) => {
    dispatch({
      type: MAKING_NEW_PARKING,
    });

    // console.log("Calling");
    // console.log("makeNewParking");

    const newParkingDetails = getState().parkingDetails;
    const newSlotDetails = getState().slotDetails;

    for (let i = 0; i < newSlotDetails.availability.length; i++) {
      delete newSlotDetails.availability[i].id;
    }

    let { availability } = newSlotDetails;
    // Sid asked me to comment this for latest changes in backend - Vishnu (Aug 4)
    // availability.forEach((item) => {
    //   if (!item.available_24) {
    //     item.open_time = toUTCStamp(item.open_time);
    //     item.close_time = toUTCStamp(item.close_time);
    //   }
    // });

    availability = [...availability, ...Object.values(newSlotDetails.events)];

    const parcel = {
      data: {
        addressData: {
          country: newParkingDetails.country,
          pincode: newParkingDetails.zipcode,
          city: newParkingDetails.city,
          street: newParkingDetails.street,
          lat: newParkingDetails.lat,
          lng: newParkingDetails.lng,
          state: newParkingDetails.state,
          house: newParkingDetails.home,
          home: newParkingDetails.home,
          home_img: newParkingDetails.home_image,
        },
        parkingData: {
          name: newSlotDetails.parking,
          parking_instructions: newSlotDetails.instructions,
          capacity: newSlotDetails.no_slots,
          price_per_hour: newSlotDetails.rent,
          daily_price_cap: newSlotDetails.daily_price_cap || null,
          area_type: newSlotDetails.type,
          is_business:
            newSlotDetails.operator_type === "Individual host" ? false : true,
          is_unavailable: newSlotDetails.is_unavailable,
          available_247: newSlotDetails.available_247,
          availability: availability,
          slot_image: newSlotDetails.new_solt_images_added,
          status: newParkingDetails.status,
          timezone_id: newSlotDetails.timezone_id || null,
        },
      },
    };

    API.post("/parkings", parcel, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: MADE_NEW_PARKING,
          payload: {
            ...data.result,
          },
        });
        dispatch({
          type: "NEW_PARKING_DETAILS",
          payload: {
            ...data.result,
          },
        });

        dispatch(getParkings(token));
      })
      .catch((err) => {
        console.log(err.response);

        dispatch({
          type: NEW_PARKING_ERROR,
          payload: {
            err: err.response ? err.response.data : err,
            errorMessage: err.response
              ? err.response.data
                ? err.response.data.error
                  ? err.response.data.error.info
                  : "Error"
                : "Error"
              : "Error",
          },
        });
      });
  };
};

export const searchForParkingNames = (string, token) => {
  return (dispatch) => {
    dispatch({
      type: "SEARCHING_FOR_PARKING_NAMES",
    });

    API.get(`/search?type=area&name=${string}`, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
    })
      .then(({ data }) => {
        // console.log('after searching', data);

        if (data.result.searchResults.length > 0) {
          for (const result of data.result.searchResults) {
            if (
              result &&
              result.name &&
              result.name.toLowerCase() === string.toLowerCase()
            ) {
              dispatch({
                type: "SEARCH_FOR_PARKINGS_FAILED",
                payload: {
                  errorMessage: "Parking name already in use.",
                },
              });
              return;
            }
          }
        }
        dispatch({
          type: "SEARCH_FOR_PARKINGS_SUCCESS",
          payload: {
            results: data.result.searchResults,
          },
        });
      })
      .catch((err) => {
        console.error("getting search results error", err);
        dispatch({
          type: "SEARCH_FOR_PARKINGS_FAILED",
        });
      });
  };
};

export const updateParking = (
  token,
  id,
  host_id = null,
  nowGoToPending = false
) => {
  return (dispatch, getState) => {
    dispatch({
      type: MAKING_NEW_PARKING,
    });

    const newParkingDetails = getState().parkingDetails;
    const newSlotDetails = getState().slotDetails;

    let { availability } = newSlotDetails;
    // Sid asked me to comment this for latest changes in backend - Vishnu (Aug 4)
    // availability.forEach((item) => {
    //   if (!item.available_24) {
    //     item.open_time = toUTCStamp(item.open_time);
    //     item.close_time = toUTCStamp(item.close_time);
    //   }
    // });

    availability = [...availability, ...Object.values(newSlotDetails.events)];
    let parcel = {
      data: {
        areaEvents: newSlotDetails.events,
        addressData: {
          country: newParkingDetails.country,
          pincode: newParkingDetails.zipcode,
          city: newParkingDetails.city,
          street: newParkingDetails.street,
          lat: newParkingDetails.lat,
          lng: newParkingDetails.lng,
          state: newParkingDetails.state,
          house: newParkingDetails.home,
          home: newParkingDetails.home,
          home_img: newParkingDetails.home_image,
        },
        parkingData: {
          name: newSlotDetails.parking,
          parking_instructions: newSlotDetails.instructions,
          capacity: newSlotDetails.no_slots,
          price_per_hour: newSlotDetails.rent,
          daily_price_cap: newSlotDetails.daily_price_cap || null,
          area_type: newSlotDetails.type,
          is_business:
            newSlotDetails.operator_type === "Individual host" ? false : true,
          is_unavailable: newSlotDetails.is_unavailable,
          available_247: newSlotDetails.available_247,
          availability: availability,
          slot_image: newSlotDetails.new_solt_images_added,
          status: newParkingDetails.status,
          timezone_id: newSlotDetails.timezone_id || null,
          default_booking_time:
            Number(newSlotDetails.default_booking_time) || null,
        },
      },
    };

    for (let key in parcel.data.addressData) {
      if (
        !(
          parcel.data.addressData[key] &&
          typeof parcel.data.addressData[key] !== "undefined" &&
          parcel.data.addressData[key] !== null &&
          parcel.data.addressData[key] !== ""
        )
      ) {
        delete parcel.data.addressData[key];
      }
    }

    for (let key in parcel.data.parkingData) {
      if (
        !(
          (parcel.data.parkingData[key] ||
            parcel.data.parkingData[key] === false) &&
          typeof parcel.data.parkingData[key] !== "undefined" &&
          parcel.data.parkingData[key] !== null &&
          parcel.data.parkingData[key] !== ""
        )
      ) {
        delete parcel.data.parkingData[key];
      }
    }

    parcel.data.parkingData.parking_instructions = newSlotDetails.instructions;
    parcel.data.parkingData.daily_price_cap =
      newSlotDetails.daily_price_cap || null;
    parcel.data.addressData.house = newParkingDetails.home || null;
    parcel.data.addressData.home = newParkingDetails.home || null;

    parcel.data.area_events = newSlotDetails.events;

    // console.log("Posting parcle");
    // console.log(parcel);
    window.scrollTo({top: 0});
    let uri;
    if (!host_id) {
      uri = `/parkings?parkingId=${id}`;
    } else {
      uri = `/parkings?parkingId=${id}&host_id=${host_id}`;
    }

    API.put(uri, parcel, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: NOW_GO_TO_PENDING,
          payload: {
            nowGoToPending: nowGoToPending,
            withId: id,
          },
        });

        dispatch({
          type: MADE_NEW_PARKING,
          payload: {
            ...data.result,
          },
        });

        let isAdmin =
          window.localStorage.getItem("userRole") === "admin" ? true : false;

        if (host_id && isAdmin) {
          setTimeout(() => {
            window.location.href = "/hosts/info/" + host_id;
          }, 300);
          return;
        }
      })
      .catch((err) => {
        console.log("ERROR IN MODIFYING PARKING");
        console.log(err.response);

        dispatch({
          type: NEW_PARKING_ERROR,
          payload: {
            err: err.response ? err.response.data : err,
            errorMessage: err.response
              ? err.response.data
                ? err.response.data.error
                  ? err.response.data.error
                  : "Error"
                : "Error"
              : "Error",
          },
        });
      });
  };
};

export const getTimezone = (token) => {
  return (dispatch) => {
    dispatch({
      type: GETTING_TIMEZONE,
    });
    API.get(`/miscellaneous/masters/timezones`, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: GET_TIMEZONE_SUCCESS,
          payload: {
            timezones: data.result.timezones,
          },
        });
      })
      .catch((err) => {
        console.error("ERROR while receiving parking data", err);
        dispatch({
          type: GET_PARKINGS_ERROR,
          payload: {
            err: err.response ? err.response.data : err,
            errorMessage: err.response
              ? err.response.data
                ? err.response.data.error
                  ? err.response.data.error.info
                  : "Error"
                : "Error"
              : "Error",
          },
        });
      });
  };
};

/* Reducers */

const parkingState = {
  total_no_parkings: null,
  verified: [],
  pending: [],
  rejected: [],
  loading: false,
  error: null,
  ttp_updated: false,
  nowGoToPending: false,
  nowGoToPendingWithId: false,
  parkingNameError: null,
  timezones: [],
};
export default function parkingReducer(state = parkingState, action) {
  switch (action.type) {
    case GET_HOST_PARKINGS:
    case GETTING_PARKINGS:
      return {
        ...state,
        loading: true,
        error: null,
        ttp_updated: false,
      };
    case GET_TIMEZONE_SUCCESS:
      return {
        ...state,
        timezones: action.payload.timezones,
      };
    case GET_PARKINGS_SUCCESS:
      const { result, spots } = action.payload.parkings;
      let extender = {};
      let length = 0;
      let verified = [];
      let pending = [];
      let rejected = [];

      try {
        Object.keys(result).map((id) => {
          extender[id] = {
            id,
            name: result[id].name,
            type: result[id].area_type,
            home: result[id].home,
            verified: result[id].status,
            status: result[id].status,
            isCustomPricing: result[id].isCustomPricing,
            instructions: result[id].parking_instructions,
            rent: result[id].price_per_hour,
            daily_price_cap: result[id].daily_price_cap,
            street: result[id].street,
            operator_type: result[id].is_business ? "Business" : "Individual",
            country: result[id].country,
            zipcode: result[id].pincode,
            city: result[id].city,
            state: result[id].state,
            no_slots: result[id].capacity,
            available_247: result[id].available_247,
            availability: result[id].availability,
            unique_code: result[id].unique_code,
            lat: result[id].lat,
            lng: result[id].lng,
            is_unavailable: result[id].is_unavailable,
            img: result[id].img,
            imgObj: result[id].imgObj || {},
            parking_imgs_with_ids: result[id].imgObj || {},
            ttp_enrolled: result[id].ttp_enrolled,
            rejection_reason: result[id].rejection_reason,
            price_per_hour: result[id].price_per_hour,
            timezone: result[id].timezone,
            default_booking_time: result[id].default_booking_time,
            area_events: result[id].area_events || [],
            host_name: result[id].first_name ?`${result[id].first_name} ${result[id].last_name}` : null
          };

          length++;
          if (result[id].status === "approved") {
            verified.push(id);
          }
          if (result[id].status === "pending") {
            pending.push(id);
          }
          if (result[id].status === "rejected") {
            rejected.push(id);
          }
          return id;
        });
      } catch (e) {
        length = 0;
        pending = verified = rejected = [];
        console.error("ERROR WHILE PARSING PARKING DATA", e);
      }

      return {
        ...parkingState,
        timezones: state.timezones,
        loading: false,
        error: null,
        ...extender,
        complete:!spots,
        total_no_parkings: length,
        verified,
        pending,
        rejected,
        ttp_updated: false,
      };

    case GET_PARKINGS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        ttp_updated: false,
      };

    case UPDATE_TTP:
      return {
        ...state,
        loading: true,
        error: null,
        ttp_updated: false,
      };
    case "CLEAR_TOASTER_TTP":
      return {
        ...state,
        loading: false,
        error: null,
        ttp_updated: false,
      };
    case UPDATE_TTP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ttp_updated: true,
      };

    case UPDATE_TTP_ERROR:
      return {
        ...state,
        ttp_updated: false,
        loading: false,
        error: action.payload.errorMessage,
      };

    case NOW_GO_TO_PENDING:
      return {
        ...state,
        nowGoToPending: action.payload.nowGoToPending,
        nowGoToPendingWithId: action.payload.withId,
      };
    case "SEARCH_FOR_PARKINGS_FAILED":
      const errorMessage =
        action.payload != undefined && action.payload.errorMessage != undefined
          ? action.payload.errorMessage
          : null;
      return {
        ...state,
        parkingNameError: errorMessage,
      };
    case "SEARCH_FOR_PARKINGS_SUCCESS":
      return {
        ...state,
        parkingNameError: null,
      };
    case SWITCHING_TOGGLE:
      if (!action.payload.parkingId) {
        return state;
      }
      if (!state[action.payload.parkingId]) {
        return state;
      }
      let updatedparking = JSON.parse(
        JSON.stringify(state[action.payload.parkingId])
      );
      updatedparking.is_unavailable = action.payload.is_unavailable;
      return {
        ...state,
        [action.payload.parkingId]: updatedparking,
      };

    case ADMIN_HOSTS_PARKINGS_RESTORE:
      return parkingState;
    default:
      return state;
  }
}

const newParkingDetailsInitState = {
  country: "",
  zipcode: "",
  city: "",
  street: "",
  home: "",
  state: "",
  house_img: null,
  house_img_name: null,
  upload_progress: 0,
  file_error: null,
  lat: null,
  lng: null,
  status: null,
};
export const newParkingDetailsReducer = (
  state = newParkingDetailsInitState,
  action
) => {
  switch (action.type) {
    case UPDATE_COUNTRY:
    case UPDATE_HOME:
    case UPDATE_STREET:
    case UPDATE_ZIPCODE:
    case UPDATE_CITY:
    case UPDATE_STATE:
      const stateHolder = action.type.split("_")[1].toLowerCase();
      return {
        ...state,
        [stateHolder]: action.payload.val,
      };
    case UPLOAD_HOME_PIC:
      return {
        ...state,
        house_img: action.payload.files[0],
        house_img_name: action.payload.files[0].name,
      };
    case UPLOAD_HOME_PIC + "_UPLOAD_RUNNING":
      return {
        ...state,
        file_error: null,
        upload_progress: action.payload.progress,
      };
    case UPLOAD_HOME_PIC + "_UPLOAD_ERROR":
      return {
        ...state,
        upload_progress: 100,
        file_error: action.payload.errorMessage,
      };
    case UPLOAD_HOME_PIC + "_UPLOAD_SUCCESS":
      return {
        ...state,
        upload_progress: 100,
        file_error: null,
        house_img: action.payload.url,
      };
    case UPDATE_LAT_LONG:
      return {
        ...state,
        lat: action.payload.val.coords.latitude,
        lng: action.payload.val.coords.longitude,
      };
    case ERROR_LAT_LONG:
      return {
        ...state,
        lat: action.payload.val,
        lng: action.payload.val,
      };
    case PARKINGS_CACHE_HIT:
      return {
        ...state,
        ...action.payload.val,
        error: null,
        file_error: null,
      };
    case CLEAR_PARKING_DETAILS:
      return newParkingDetailsInitState;
    default:
      return state;
  }
};

const newParkingSlotInitState = {
  parking: null,
  type: "Driveway",
  is_unavailable: false,
  available_247: true,
  availability: [
    {
      isodayofweek: 1,
      open_time: "00:00",
      close_time: "00:00",
      available_24: true,
      is_disabled: false,
    },
    {
      isodayofweek: 2,
      open_time: "00:00",
      close_time: "00:00",
      available_24: true,
      is_disabled: false,
    },
    {
      isodayofweek: 3,
      open_time: "00:00",
      close_time: "00:00",
      available_24: true,
      is_disabled: false,
    },
    {
      isodayofweek: 4,
      open_time: "00:00",
      close_time: "00:00",
      available_24: true,
      is_disabled: false,
    },
    {
      isodayofweek: 5,
      open_time: "00:00",
      close_time: "00:00",
      available_24: true,
      is_disabled: false,
    },
    {
      isodayofweek: 6,
      open_time: "00:00",
      close_time: "00:00",
      available_24: true,
      is_disabled: false,
    },
    {
      isodayofweek: 7,
      open_time: "00:00",
      close_time: "00:00",
      available_24: true,
      is_disabled: false,
    },
  ],
  instructions: "",
  rent: null,
  daily_price_cap: null,
  no_slots: null,
  operator_type: "Individual host",
  parking_imgs: [],
  new_solt_images_added: [],
  parking_imgs_with_ids: {},
  uploading: false,
  events: {},
  upload_progress: 0,
  file_error: null,
  error: null,
  status: null,
  success: false,
};
export const newParkingSlotReducer = (
  state = newParkingSlotInitState,
  action
) => {
  switch (action.type) {
    case UPDATE_PARKING:
    case UPDATE_TYPE:
    case UPDATE_INSTRUCTIONS:
    case UPDATE_RENT:
      const first_ = action.type.indexOf("_");
      const stateHolder = action.type.slice(first_ + 1).toLowerCase();
      return {
        ...state,
        [stateHolder]: action.payload.val,
      };
    case UPDATE_DAILY_PRICE_CAP:
      return {
        ...state,
        daily_price_cap: action.payload.val,
      };
    case UPDATE_MINIMUM_BOOKING_TIME:
      return {
        ...state,
        default_booking_time: action.payload.val,
      };
    case UPDATE_TIMEZONE:
      return {
        ...state,
        timezone_id: action.payload.val,
      };

    case UPDATE_NO_SLOTS:
      return {
        ...state,
        no_slots: parseInt(action.payload.val),
      };

    case MAKING_NEW_PARKING:
      return {
        ...state,
        error: null,
        uploading: true,
        success: false,
      };

    case "HOST_PARKINGS_CACHE_HIT":
    case PARKINGS_CACHE_HIT:
      let data = action.payload;
      if (action.payload.val) {
        data = action.payload.val;
      }
      let details = {
        parking: data.name,
        type: data.area_type,
        is_business: data.is_business,
        is_unavailable: data.is_unavailable,
        instructions: data.parking_instructions,
        no_slots: data.capacity,
        parking_imgs: data.img,
        parking_imgs_with_ids: data.imgObj,
        price_per_hour: data.price_per_hour,
        rent: data.price_per_hour,
        daily_price_cap: data.daily_price_cap || null,
        new_solt_images_added: [],
        timezone_id: data.timezone.id,
      };

      return { ...state, ...details };

    case MADE_NEW_PARKING:
      return {
        ...state,
        error: null,
        uploading: false,
        success: true,
      };

    case NEW_PARKING_ERROR:
      return {
        ...state,
        error: action.payload.errorMessage,
        uploading: false,
        success: false,
      };

    case SET_OPERATOR:
      return {
        ...state,
        operator_type: action.payload.val,
      };

    case UPDATE_AVAILABILITY:
      let actionAvailibilty = action.payload.availability;
      let shouldUpdate = true;
      const { index, close_time, open_time, is_disabled, available_24 } =
        actionAvailibilty;

      if (
        !(
          close_time &&
          open_time &&
          is_disabled !== null &&
          is_disabled !== undefined &&
          available_24 !== null &&
          available_24 !== undefined
        )
      ) {
        shouldUpdate = false;
      }

      let intialAvailabilty = state.availability;

      let is24 = false;
      if (
        close_time &&
        open_time &&
        close_time === open_time &&
        (close_time === "00:00" || close_time === "00:00:00")
      ) {
        is24 = true;
      }

      let availabilityObj =
        intialAvailabilty && intialAvailabilty.length
          ? intialAvailabilty.reduce((acc, obj) => {
              acc[obj.isodayofweek] = acc[obj.isodayofweek]
                ? acc[obj.isodayofweek]
                : obj;
              return acc;
            }, {})
          : {};

      if (
        index &&
        (open_time || open_time === "" || close_time || close_time === "")
      ) {
        if (availabilityObj[index]) {
          availabilityObj[index].available_24 = is24;
          if (close_time) {
            availabilityObj[index].close_time = close_time;
          }
          if (open_time) {
            availabilityObj[index].open_time = open_time;
          }
        } else {
          availabilityObj[index] = {
            isodayofweek: index,
            available_24: is24,
            close_time,
            open_time,
            is_disabled,
          };
        }
      }

      if (index && is_disabled !== undefined && is_disabled !== null) {
        availabilityObj[index].is_disabled = is_disabled;
      }

      return {
        ...state,
        availability: Object.values(availabilityObj),
      };

    case UPDATE_AVAILABILITY_BULK:
      let actionAvailibiltyData = action.payload.availability;

      let availabilityArray = state.availability;
      let tempavailabilityArrayObj = availabilityArray.reduce((acc, obj) => {
        acc[obj.isodayofweek] = acc[obj.isodayofweek]
          ? acc[obj.isodayofweek]
          : obj;
        return acc;
      }, {});

      for (let availability of actionAvailibiltyData) {
        let is24 = false;
        const { id, index, close_time, open_time } = availability;
        let is_disabled = availability.is_disabled ? true : false;

        if (
          close_time === open_time &&
          (close_time === "00:00" || close_time === "00:00:00")
        ) {
          is24 = true;
        }

        tempavailabilityArrayObj[index] = {
          id,
          isodayofweek: index,
          close_time: close_time || "00:00",
          open_time: open_time || "00:00",
          available_24: is24,
          is_disabled,
        };
      }

      return {
        ...state,
        availability: Object.values(tempavailabilityArrayObj),
      };

    case UPDATE_247:
      return {
        ...state,
        available_247: action.payload.val,
      };
    case UPDATE_SPOT_IMAGES:
      return {
        ...state,
      };

    case UPDATE_SPOT_IMAGES + "_UPLOAD_RUNNING":
      return {
        ...state,
        upload_progress: action.payload.progress,
        file_error: null,
      };
    case UPDATE_SPOT_IMAGES + "_UPLOAD_ERROR":
      return {
        ...state,
        upload_progress: 100,
        file_error: action.payload.errorMessage,
      };
    case UPDATE_SPOT_IMAGES + "_UPLOAD_SUCCESS":
      return {
        ...state,
        upload_progress: 100,
        file_error: null,
        // parking_imgs: [action.payload.url, ...state.parking_imgs,],
        new_solt_images_added: [
          action.payload.url,
          ...state.new_solt_images_added,
        ],
      };

    case UPDATE_SPOT_IMAGES + "_REMOVE_IMAGE":
      let removingImgUrl = action.payload.image_url;
      let indexOfImg = state.new_solt_images_added.indexOf(removingImgUrl);

      if (indexOfImg !== -1) {
        state.new_solt_images_added.splice(indexOfImg, 1);
      } else {
        let seletedImg = state.parking_imgs[action.payload.index];

        let preparedObj = {};
        for (let key in state.parking_imgs_with_ids) {
          preparedObj[state.parking_imgs_with_ids[key]] = key;
        }

        let id_of_img = preparedObj[seletedImg];

        deleteSpotImage(id_of_img);
        state.parking_imgs.splice(action.payload.index, 1);
      }

      return {
        ...state,
        upload_progress: 0,
        file_error: null,
      };

    case EVENT_SELECTED:
      const { event_id, id } = action.payload;
      return {
        ...state,
        events: {
          ...state.events,
          [event_id]: {
            fare: 0,
            event_id,
            id,
            is_disabled: false,
          },
        },
      };
    case EVENT_REMOVED:
      let { events } = state;

      // not to use delete operator because it doesn't cause a state rerender from thunk
      const newEvents = Object.keys(events).reduce((object, key) => {
        if (key !== action.payload.id) {
          object[key] = events[key];
        }

        if (key === action.payload.id) {
          // this is the event deleted
          object[key] = events[key];
          object[key].is_disabled = true;
        }
        return object;
      }, {});
      return {
        ...state,
        events: newEvents,
      };
    case UPDATE_EVENT_INFORMATION:
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.event_id]: {
            fare: action.payload.price,
            event_id: action.payload.event_id,
            id: action.payload.id,
          },
        },
      };
    case CLEAR_SLOT_DETAILS:
      return newParkingSlotInitState;
    default:
      return state;
  }
};

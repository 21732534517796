import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { signUp } from './../../../reduxModules/auth';
// { firebase } 
import firebaseAuth from "../firebase";

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (f, l, e, phone, legal, token) => dispatch(signUp(f, l, e, phone, legal, token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(props => {


    const actionCode = props.actionCode;
    const { email, firstName, lastName, token, phone } = props;
    const [newCode, setNewCode] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        // firebaseAuth().signOut();
    }, []); 

    return (
        <React.Fragment>
            <div className="h-100 d-flex align-items-center text-center justify-content-center">
                <div className="text-center">
                    <p className="font-l primary-text-color mb-3 semi-bold">Your account has been created</p>
                    <p className="font-l mb-4">To activate your account, please click on the verification link sent to your email.</p>
                </div>
            </div>
            {/* <a href='/login' onClick={() => window.location.href = "/login"}><button className="btn global_btn add_max_width mx-auto btn-block">Login</button></a> */}
        </React.Fragment>
    );
});
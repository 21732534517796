import React from 'react';

export default (props) => {
    return (
        // <div className="d-flex justify-content-center">
        //     <div className={props.color ? `spinner-border text-${props.color}` : 'spinner-border text-primary'} role='status' style={props.style}>
        //         <span className="sr-only">Loading ...</span>
        //     </div>
        // </div>

        <div className="global_loading">
            <div className="center-box p-4 text-center" >
                <p className="font-sm  mb-0">Loading...</p>
            </div>
        </div>

    );
};
import { api2 } from "../../config/api";
import { getFilterURLParams } from "../tools/filters";

const initialState = {
    loading: false,
    error: null,
    data:{
        earnings: [],
        total: 0,
        amount: 0,
    },
    pageNumber: 1,
    pageSize: 10,
}
const GET_EARNINGS_LOADING = "GET_EARNINGS_LOADING";
const GET_EARNINGS_SUCCESS = "GET_EARNINGS_SUCCESS";
const GET_EARNINGS_ERROR = "GET_EARNINGS_ERROR";
export default function EarningsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_EARNINGS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case GET_EARNINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                ...action.payload,
            };
        case GET_EARNINGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export function getEarnings(pageNumber, pageSize) {
    return async (dispatch, getState) => {
        const url = `/earnings`;
        const token = getState().auth.auth_token;
        dispatch({
            type: GET_EARNINGS_LOADING,
        });
        try {
            const params = getFilterURLParams(getState());
            if (pageNumber) {
                params.append('limit', pageSize);
                params.append('offset', (pageNumber - 1) * pageSize);
            }
            const response = await api2.get(url, {
                headers: {
                    'X-AUTH-TOKEN': token,
                },
                params: params,
            });
            dispatch({
                type: GET_EARNINGS_SUCCESS,
                payload: {
                    data:response.data.result,
                    pageNumber: pageNumber,
                    pageSize: pageSize
                }
            });
        } catch (error) {
            dispatch({
                type: GET_EARNINGS_ERROR,
                payload: error.message,
            });
        }
    };
}
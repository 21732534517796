import React, { useState } from "react";
import { connect } from "react-redux";
import { fileManagerAdmin } from "../../actions/fileManager";
import add_image from "../../Assets/imgs/add_image.svg";
import { showUploadModal } from "../../reduxModules/app";
import { clearUploadReceipt } from "../../reduxModules/withdrawals";
import Modal from "../shared/Modal";

const UploadModal = (props) => {

  const [files, setFile] = useState();

  return (
    <Modal
      button_text="Upload"
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      showFlagSetFunction={props.showUploadModal}
      creatingAction={props.uploading ? true : files && files[0] && files[0]?.name ? false : true}
      successAction={props.uploadSuccess}
      errorAction={props.uploadError}
      title="Upload Receipt"
      saveFunc={() => props.fileUpload("RECEIPT", files, "admin@parkpoolr.com", props.upload_id)}
      clearFunction={() => props.clear()}
    >
      <div className="container-fluid p-4">
        <small className="mb-3 text-muted d-block">
          Upload the bank transaction receipt
        </small>
        <div>
          <label htmlFor="upload-receipt">
            <div className="upload-box">
              <img src={add_image} style={{ width: 81, height: 65 }} />
            </div>
          </label>
          <input
            type="file"
            onChange={(e) => setFile(e.target.files)}
            style={{ visibility: "hidden" }}
            id="upload-receipt"
          />
        </div>
        {files ? (

          <p className="font-m">
             <span className="primary-text-color" > File selected: </span> 
            <span className="secondary-text-color"> {files ? files[0].name : null}</span>
          </p>

        ) : null}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    uploadSuccess: state.uploadReceipt.uploadSuccess,
    uploadError: state.uploadReceipt.uploadError,
    uploading: state.uploadReceipt.uploading,
    path: state.uploadReceipt.path,
    upload_id: state.app.show_upload_modal_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => dispatch(clearUploadReceipt()),
    fileUpload: (type, files, email, id) =>
      dispatch(fileManagerAdmin(type, files, email, id)),
    showUploadModal: (bool) => dispatch(showUploadModal(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);

import React from "react"

import LogoImage from "../bigCarImage";
// import LogoText from "../logoText/index";

export default () => {
    return (
        <LogoImage/>
    )
}

import React from 'react'
import Layout from '../../components/shared/layout'
import { Withdrawals } from '../../components/v3/withdrawals'
import { useSelector } from 'react-redux'
export function WithdrawalsPage() {
  const useRole = useSelector(state => state.profile.role)
  return <Layout>
  <Withdrawals tab={useRole === 'admin' ? 'pending' : null} />
  </Layout>
}

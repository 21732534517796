import React from 'react';

function BlockLoader ({waitingText = " Please Wait... "}) {
    return (
        <div className="block_loading">
            <div className="center-box p-4 text-center" >
                <Spinner/>
                <p className="text-muted align-middle font-sm  mb-0"> {waitingText} </p>
            </div>
        </div>
    );
};

export function Spinner() {
    return <div className="spinner">
            <svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
            <circle fill="none" stroke="#82ff85" strokeWidth="4" cx="50" cy="50" r="44" style={{opacity:0.5}}/>
              <circle fill="#fff" stroke="#3CCA23" strokeWidth="3" cx="8" cy="54" r="6" >
                <animateTransform
                    attributeName="transform"
                    dur="2s"
                    type="rotate"
                    from="0 50 48"
                    to="360 50 52"
                    repeatCount="indefinite" />

              </circle>
            </svg>
    </div>
}
export function FullPageLoader() {
    return <div className="fullPageLoader">
        <BlockLoader waitingText=""/>
    </div>
}
export default BlockLoader;

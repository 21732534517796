export default function inputManager(type, value) {
    return {
        type,
        payload: {
            val: value,
            value: value,
        }
    };
}





export const autoFillEventDetails = (value) => {

    return {
        type: "AUTO_FILL_EVENT",
        payload: {
            val: value,
            value
        },
    };
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import firebaseAuth from '../../firebase';
import Spinner from '../../../shared/loading/spinner';
import { signUp } from '../../../../reduxModules/auth';

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (f, l, e, phone, legal, token, dontReload) => dispatch(signUp(f, l, e, phone, legal, token, dontReload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(props => {


    const actionCode = props.actionCode;
    const { email, firstName, lastName, token, phone } = props;
    const [newCode, setNewCode] = useState(false);
    const [success, setSuccess] = useState(false);

    const [newLoading, setNewLoading] = useState(false);


    useEffect(() => {
        setNewLoading(true)
        applyActionCode();
    }, []);

    useEffect(() => {
        if (props.error) {
            console.log("Authentication related Error");
            console.error(props.error);
        }
    }, [props.error]);

    const applyActionCode = () => {
        firebaseAuth().applyActionCode(actionCode)
            .then(resp => {
                props.signUp(firstName, lastName, email, phone, true, token, true); /// last one is for don't refresh
                setSuccess(true);
                setNewLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setNewLoading(false)
                setNewCode(true);
                switch (err.code) {
                    case "auth/expired-action-code":
                        console.log("Code has been Expired!");
                        break;
                    case "auth/invalid-action-code":
                        console.log("Invalid Code!");
                        break;
                    default:
                        break;
                }
            });
    }


    return (
        <React.Fragment>
            {
                newLoading ?

                    <React.Fragment>
                        <div className="container text-center">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <p className="font-l primary-text-color">Please wait, Verifying...</p>
                        </div>
                    </React.Fragment>
                    :
                    null
            }
            {
                !newCode ? success ?
                    <React.Fragment>
                        <div className="container text-center">
                            <p className="font-l primary-text-color semi-bold">Your email has been verified</p>
                        </div>
                        <a href='/login' onClick={() => window.location.href = "/login"}><button className="btn add_max_width mx-auto global_btn btn-block">Login</button></a>
                    </React.Fragment>
                    :
                    props.loading ?
                        <Spinner />
                        :
                        null
                    :
                    <React.Fragment>
                        <div className="container text-center">
                            <p className="font-l primary-text-color">Verification link expired</p>
                        </div>
                    </React.Fragment>
            }
        </React.Fragment>
    );
});
import React from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from '../../shared/loading/spinner';
import Notfound from '../../notfound';
import VerifyEmail from './verifiedState';
import CreateNewPassword from './createPass';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function useAddress(url) {
    return new URLSearchParams(url);
}
  




export default props => {

    const query = useQuery();
    
    const mode = query.get('mode');
    const actionCode = query.get('oobCode');
    // const [continueURL, setContinueURL] = useState(null);
    const continueURL = query.get('continueUrl')
    const params = useAddress(continueURL);
    const email = params.get('email');
    const firstName = params.get('firstName');
    const lastName = params.get('lastName');
    const token = params.get('token');
    const phone = params.get('phone');
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    // const [token, setToken] = useState('');
    // const [email, setEmail] = useState('');
    // const [phone, setPhone] = useState('');

    if (mode === 'verifyEmail') {
        return<VerifyEmail firstName={firstName} phone={phone} lastName={lastName} email={email} token={token} continueURL={continueURL} actionCode={actionCode} />;
    }

    if (mode === 'resetPassword') {
        return <CreateNewPassword actionCode={actionCode} />;
    }

    return <Notfound/>;
};
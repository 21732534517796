import React from 'react'
import moment from 'moment'
import { useGetUsersQuery } from '../../../../reduxModules/v3/users/users'
import { useModalView } from '../../../shared/Modal'
import { useMemo } from 'react'
import defaultUser from '../../../../Assets/imgs/defaultUser.svg'
import { getStyles } from '../../../Filters/byParkingArea'
import { useFieldValue } from '../../../../reduxModules/tools/fieldValue'
import './styles.scss'
import Select from 'react-select'
import { GLOBAL_FIELD_FILTER_BY_HOSTS } from '../../../Filters/byHosts'
import { useGetParkingsQuery } from '../../../../reduxModules/v3/parkings'
import { useExport } from '../../../shared/export'
import { WITHDRAWALS_FILTER_BY_DATE_RANGE } from '..'
const ExportIcon = require('../../../../Assets/imgs/export.svg')
export function FormattedDate({ date }) {
  const [, groupBy] = useFieldValue(...GROUP_BY_FIELD_ID)
  const prefix = datePrefix()
  const suffix = dateSuffix()
  return (
    <span className="d-flex align-items-center justify-content-start align-content-end gap-3">
      {prefix && (
        <span style={{ color: '#999999' }} className="mr-2">
          {prefix}
        </span>
      )}
      {moment(date, getInputDateFormat(groupBy)).format(getOutputDateFormat())}
      {suffix && (
        <span
          className="badge badge-pill badge-success ml-2"
          style={{ backgroundColor: '#91e4a4' }}
        >
          {suffix}
        </span>
      )}
    </span>
  )

  // private functions
  function getOutputDateFormat() {
    switch (groupBy) {
      case 'week':
        return 'WW, YYYY'
      case 'month':
        return 'MMM YYYY'
      case 'year':
        return 'YYYY'
      default:
        return 'MMM DD, YYYY'
    }
  }
  function getInputDateFormat(groupBy) {
    switch (groupBy) {
      case 'week':
        return 'YYYY-WW'
      case 'month':
        return 'YYYY-MM'
      case 'year':
        return 'YYYY'
      default:
        return 'YYYY-MM-DD'
    }
  }
  function datePrefix() {
    switch (groupBy) {
      case 'week':
        return 'Week '
      case 'year':
        return 'Year '
      default:
        return ''
    }
  }
  function dateSuffix() {
    const isCurrent = date === moment().format(getInputDateFormat(groupBy))
    if (!isCurrent) return ''
    switch (groupBy) {
      case 'week':
        return 'Current Week'
      case 'year':
        return 'Current Year'
      case 'month':
        return 'Current Month'
      default:
        return 'Today'
    }
  }
}
export function formatNumber(number) {
  return Number(number).toLocaleString('en-US')
}
export function formatCurrency(number) {
  return Number(number).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}
export const GROUP_BY_FIELD_ID = ['withdrawals-group-by', 'month']
export function GroupBy() {
  const [, groupBy, setGroupBy] = useFieldValue(...GROUP_BY_FIELD_ID)
  const options = useOptions()
  return (
    <div className="d-flex flex-column filter align-self-baseline">
      <p className="label"> Group By </p>
      <Select
        styles={getStyles()}
        value={options.find((x) => x.value === groupBy)}
        style={{ width: 'fit-content' }}
        options={options}
        className="select"
        onChange={(selected) => setGroupBy(selected.value)}
      />
    </div>
  )
  function useOptions() {
    return useMemo(
      () => [
        {
          label: 'By Day',
          value: 'day',
        },
        {
          label: 'By Week',
          value: 'week',
        },
        {
          label: 'By Month',
          value: 'month',
        },
        {
          label: 'By Year',
          value: 'year',
        },
      ],
      [],
    )
  }
}
export function HostsList(props) {
  const { show, hide} = useModalView()
  const hosts = useHosts(props.host_ids)
  if (hosts.length === 0) return null
  const firstHost = hosts[0]
  return (
    <span
      className="d-flex flex-row align-items-center justify-content-start"
    >
      <HostInfo {...firstHost} />
      {hosts.length > 1 && (
        <span 
        onClick={() => show({ children: <HostInfoModal hosts={hosts} /> })}
        style={{ color: '#3CCA23' }} className="btn link cursor-pointer">
          +{hosts.length - 1} more
        </span>
      )}
    </span>
  )

  function HostInfo({
    first_name,
    last_name,
    profile_pic,
    id
  }) {
    const [,,filterHost] = useFieldValue(GLOBAL_FIELD_FILTER_BY_HOSTS)
    return (
      <div
        className="d-flex align-items-center link btn"
        key={id}
        onClick={() => filterHost([id])}
      >
        <img
          className="user-img mr-3"
          src={profile_pic || defaultUser}
          alt="user"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = defaultUser
          }}
        />
        <div className="host-name"> {`${first_name} ${last_name}`} </div>
      </div>
    )
  }
  function useHosts(host_ids) {
    const { data } = useGetUsersQuery({ limit: 1000000, type: 'host' })
    const hosts = useMemo(() => {
      const host_id_set = new Set(host_ids)
      return data?.hosts.filter(({ id }) => host_id_set.has(+id)) || []
    }, [data, host_ids])
    return hosts
  }
  function HostInfoModal({ hosts }) {
    return (
      <div
        className="modal-body"
        style={{ maxHeight: '80vh', overflowY: 'auto' }}
        onClick={(e) => hide()}
      >
        <table className="table table-striped">
            <tbody>
                {hosts.map( h=> <tr key={h.id}>
                    <td className="p-0">
                        <HostInfo {...h} />
                    </td>
                </tr>)}
            </tbody>
        </table>
      </div>
    )
  }
}
export function ParkingsList(props) {
  const { show, hide} = useModalView()
  const parkings = useParkings(props.parking_ids)
  if (parkings.length === 0) return null
  const firstParking = parkings[0]
  return (
    <span
      className="d-flex flex-row align-items-center justify-content-start"
    >
      <ParkingInfo {...firstParking} />
      {parkings.length > 1 && (
        <span 
        onClick={() => show({ children: <ParkingsInfoModal parkings={parkings} /> })}
        style={{ color: '#3CCA23' }} className="btn link cursor-pointer">
          +{parkings.length - 1} more
        </span>
      )}
    </span>
  )

  function ParkingInfo({
    unique_code,
    name,
    id
  }) {
    return (
      <div
        className="d-flex flex-column align-items-start btn pe-none"
        key={id}
      >
        <div className="host-name"> {name} </div>
        <div className="text-muted">
                {unique_code}
        </div>
      </div>
    )
  }
  function useParkings(host_ids) {
    const { data } = useGetParkingsQuery({ limit: 1000000 })
    const parkings = useMemo(() => {
      const parking_id_set = new Set(host_ids)
      return data?.filter(({ id }) => parking_id_set.has(+id)) || []
    }, [data, host_ids])
    return parkings
  }
  function ParkingsInfoModal({ parkings }) {
    return (
      <div
        className="modal-body"
        style={{ maxHeight: '80vh', overflowY: 'auto' }}
        onClick={(e) => hide()}
      >
        <table className="table table-striped">
            <tbody>
                {parkings.map( p=> <tr key={p.id}>
                    <td className="p-0">
                        <ParkingInfo {...p} />
                    </td>
                </tr>)}
            </tbody>
        </table>
      </div>
    )
  }
}

export const TransactionCount = ({ transaction_count, date, amount_to_host, host_ids , type ,status}) => {
  const [, groupBy] = useFieldValue(...GROUP_BY_FIELD_ID);
  const [,dateFilter] = useFieldValue(WITHDRAWALS_FILTER_BY_DATE_RANGE)
  const [,parking_ids] = useFieldValue('field-filter-by-parking-areas',[])
  const {download} = useExport({
        nameTags:[date,groupBy,type, status, transaction_count, formatCurrency(amount_to_host / 100)],
        service:"transactions",
        params:{
          type: type,
          date: [date],
          date_range: dateFilter?.value,
          parking_ids:parking_ids,
          host_ids,
          date_group_by: groupBy,
          status:status
         }})
  return <span>
    <div
      onClick={download} 
      className="d-flex flex-row align-items-center justify-content-center align-content-center gap-3 pointer"
      style={{ width: "fit-content" }}
    >
      <h6 className='mb-0 mr-2'>{formatNumber(transaction_count)}</h6>
      <img src={ExportIcon} alt="export"/>
    </div>
  </span>
}

import React, { useMemo } from "react";
import { useFieldValue } from "../../reduxModules/tools/fieldValue";
import { useGetUsersQuery } from "../../reduxModules/v3/users/users";
import Select, { createFilter, components } from "react-select";
import defaultUser from "../../Assets/imgs/defaultUser.svg";
import { renderToString } from "react-dom/server";
import { getStyles } from "./byParkingArea";
export const GLOBAL_FIELD_FILTER_BY_HOSTS = 'global-field-filter-by-hosts';

export function FilterByHosts({ id = GLOBAL_FIELD_FILTER_BY_HOSTS, placeholder = 'Search Hosts' }) {
    const [,selected,setSelectedHosts] = useFieldValue(id, []);
    const hostOptions = useHostOptions();
    return (
        <div className="d-flex flex-column filter">
            <p className="label"> Filter by Host </p>
            <Select
                styles={getStyles()}
                value={selected.map(x => hostOptions.find(y => y.value === x))}
                isOptionSelected={(option) => selected.includes(option.value)}
                onChange={(selected) => {
                    setSelectedHosts(selected.map(x => x.value))
                }}
                placeholder={placeholder}
                isMulti={true}
                filterOption={createFilter({
                    stringify: (option) => renderToString(option.label),
                    ignoreCase: true,
                    trim: true,
                })}
                components={{ MultiValueLabel: CustomMultiValueLabel }}
                className="select" options={hostOptions} />
        </div>
    );
    function CustomMultiValueLabel(props) {
        return <components.MultiValueLabel {...props}>
            {props.data.name}
        </components.MultiValueLabel>;
    }
    
    function useHostOptions() {
        const {data} = useGetUsersQuery({limit:1000000, type:'host'})
        return useMemo(() => (data?.hosts.map((host) => ({
            value: host.id,
            name: host.first_name + ' ' + host.last_name,
            label: <div className="d-flex align-items-center">
            <img className='user-img mr-3' src={host.profile_pic || defaultUser} alt="user" />
            <span>
                <div className="host-name">{host.first_name} {host.last_name} </div>
                <div className="text-muted">
                    {host.email } <br/> { host.mobile_number }
                </div>
            </span>
        </div>,
        })) || []), [data])
    }
}
import React from "react";
import DateTimePicker from "react-datetime-picker";
import { connect } from "react-redux";
import fileManager, { deleteImgfromArray } from "../../../actions/fileManager";
import inputManager, {
  autoFillEventDetails,
} from "../../../actions/inputManager";
import { showEventModal } from "../../../reduxModules/app";
import {
  clearEventFields,
  createNewEvent,
  EVENT_UPLOAD_FILE,
} from "../../../reduxModules/events";
import FileUpload from "../../parkings/Shared/FileUpload";
//TODO THIS SHOULD BE MOVED TO GLOBAL SHARED
import StdInput from "../../parkings/Shared/StdInput";
import Modal from "../../shared/Modal";
import moment from "moment-timezone";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import {saveEvent} from "../../../reduxModules/events_v2/editor";

class Event extends React.Component {
  state = {
    isTimeZoneInputFocused: false
  }
  setStateTimeZoneFocused() {
    this.setState({
      isTimeZoneInputFocused: true
    })
  }
  setStateTimeZoneNotFocused() {
    this.setState({
      isTimeZoneInputFocused: false
    })
  }
  constructor(props) {
    super(props);

    if (props.edit_event_id && typeof props.edit_event_id === "number") {

      let findOne = Object.values(props.activeEvents).find((ele) => {
        return ele.id == props.edit_event_id;
      });

      if (!findOne) {
        findOne = Object.values(props.upcomingEvents).find((ele) => {
          return ele.id == props.edit_event_id;
        });
      }

      if (!findOne) {
        findOne = Object.values(props.pastEvents).find((ele) => {
          return ele.id == props.edit_event_id;
        });
      }

      if (findOne) {
        console.log("Now auto fill details");
        props.autoFillEventDetails(findOne);
        return;
      }
    }
  }

  render() {
    const props = this.props;
    let formNotFilled = true;
    let _fullEventDetails = props.fullEventDetails;

    const timezones = moment.tz.names().map((x) => ({ name: x, id: x }));
    let selectedTimezone = moment.tz.guess().trim();

    if (
      _fullEventDetails.name &&
      _fullEventDetails.type &&
      _fullEventDetails.location.street &&
      _fullEventDetails.location.city &&
      _fullEventDetails.location.state &&
      _fullEventDetails.location.country &&
      _fullEventDetails.location.pincode
    ) {
      formNotFilled = false;
    }

    return (
      <Modal
        creatingAction={this.props.creatingEvent || formNotFilled}
        successAction={this.props.createNewEventSuccess}
        errorAction={this.props.createNewEventError}
        showFlagSetFunction={this.props.showEvent}
        title={"Event Details"}
        button_text="Add"
        saveFunc={() => this.props.createEvent(this.props.token)}
        clearFunction={this.props.clearEventFields}
      >
        <div className="p-3 py-4">
          <div className="row">
            <StdInput
              value={this.props.fullEventDetails.name}
              onChange={(e) =>
                this.props.inputManager("SET_EVENT_NAME", e.target.value)
              }
              id="event-name"
              label="Event Name"
            />
          </div>
          <div className="row">
            <StdInput
              id="event-type"
              label="Event Type"
              value={this.props.fullEventDetails.type}
              readonly={false}
              type="text"
              onChange={(e) => {
                this.props.inputManager("SET_EVENT_TYPE", e.target.value);
              }}
            />
          </div>
          <div className="row">
            <StdInput
              id="event-location-street"
              value={this.props.fullEventDetails.location.street}
              label="Street"
              readonly={false}
              type="text"
              onChange={(e) => {
                this.props.inputManager(
                  "SET_EVENT_LOCATION_STREET",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="row">
            <StdInput
              id="event-location-city"
              value={this.props.fullEventDetails.location.city}
              label="City"
              readonly={false}
              type="text"
              onChange={(e) => {
                this.props.inputManager(
                  "SET_EVENT_LOCATION_CITY",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="row">
            <StdInput
              id="event-location-state"
              value={this.props.fullEventDetails.location.state}
              label="State"
              readonly={false}
              type="text"
              onChange={(e) => {
                this.props.inputManager(
                  "SET_EVENT_LOCATION_STATE",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="row">
            <StdInput
              id="event-location-country"
              value={this.props.fullEventDetails.location.country}
              label="Country"
              readonly={false}
              type="text"
              onChange={(e) => {
                this.props.inputManager(
                  "SET_EVENT_LOCATION_COUNTRY",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="row">
            <StdInput
              id="event-location-pincode"
              value={this.props.fullEventDetails.location.pincode}
              label="Zip Code"
              readonly={false}
              type="number"
              onChange={(e) => {
                this.props.inputManager(
                  "SET_EVENT_LOCATION_PINCODE",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="row">
            <StdInput
              id="event-venue"
              label="Venue"
              value={this.props.fullEventDetails.location.venue}
              readonly={false}
              type="text"
              onChange={(e) =>
                this.props.inputManager("SET_EVENT_VENUE", e.target.value)
              }
            />
          </div>

          <div className="row">
            <div className="col">
              <div onMouseDown={e => e.nativeEvent.stopImmediatePropagation()} className="form-group" onBlur={this.setStateTimeZoneNotFocused.bind(this)} onFocus={this.setStateTimeZoneFocused.bind(this)}>
                <label htmlFor="event-timezone">Timezone</label>
                <ReactSearchAutocomplete
                  showItemsOnFocus
                  placeholder={moment.tz.guess()}
                  items={timezones || []}
                  fuseOptions={{ keys: ["name"] }}
                  resultStringKeyName="name"
                  id="event-timezone"
                  onSelect={(e) => {
                    moment.tz.setDefault(e.id);
                  }}
                  onClear={() => {
                    moment.tz.setDefault();
                  }}
                  showIcon={false}
                  className="form-control global_input"
                  styling={{
                    height: "34px",
                    border: "0",
                    borderBottom: "1px solid rgb(212, 212, 212)",
                    borderRadius: "0px",
                    backgroundColor: "#f7f7f7",
                    boxShadow: "none",
                    hoverBackgroundColor: "lightgreen",
                    color: "#545454",
                    fontSize: "12px",
                    zIndex: this.state.isTimeZoneInputFocused? 3 : 'unset'
                  }}
                />
                <p>
                  <small>
                    <strong>Note: </strong>
                    This selection isn't saved, this is just to ease the below
                    selections from different timezones , if nothing is selected
                    your current system timezone is taken ({selectedTimezone})
                  </small>
                </p>
              </div>
            </div>
          </div>

          <div className="row mb-4 timers">
            <div className="col">
              <label>Time In</label>
              <div>
                <DateTimePicker
                  clearIcon={null}
                  className="newdatepicker"
                  onChange={(date) => {
                    const inTime = moment(date);
                    this.props.inputManager(
                      "SET_EVENT_TIME_IN",
                      inTime.toDate()
                    );

                    this.props.inputManager(
                      "SET_EVENT_TIME_OUT",
                      moment(inTime).add(1, "hour").toDate()
                    );
                  }}
                  value={moment(this.props.fullEventDetails.time_in).toDate()}
                />
              </div>
            </div>
            <div className="col">
              <label>Time Out</label>
              <div>
                <DateTimePicker
                  required={true}
                  clearIcon={null}
                  className="newdatepicker"
                  onChange={(date) =>
                    this.props.inputManager(
                      "SET_EVENT_TIME_OUT",
                      moment(date).toDate()
                    )
                  }
                  value={moment(this.props.fullEventDetails.time_out).toDate()}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="font-s primary-text-color mb-1">
                Upload event / venue images
              </p>
            </div>
            <FileUpload
              id="event-uploader"
              label="+"
              multiple={true}
              progress={this.props.event_file_progress}
              error={this.props.event_file_error}
              img={this.props.event_new_file}
              uploadFileHandler={(e) =>
                this.props.fileManager(
                  EVENT_UPLOAD_FILE,
                  e.target.files,
                  this.props.email
                )
              }
              deleteFile={(index) =>
                this.props.deleteImgfromArray("EVENT", index)
              }
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const {current:currentEventDetails} = state.events_v2.eventEditor;
  let { events: activeEvents,loading:activeEventsLoading, stale:activeEventsStale } = state.events_v2.active;
  let { events: upcomingEvents,loading:upcomingEventsLoading, stale:upcomingEventStale } = state.events_v2.upcoming;
  let { events: pastEvents,loading:pastEventLoading, stale:pastEventStale } = state.events_v2.past;


  return {
    fullEventDetails: {
      name: currentEventDetails.event_name,
        type: currentEventDetails.event_category,
      location:{
        street: currentEventDetails.street,
        city: currentEventDetails.city,
        state: currentEventDetails.state,
        country: currentEventDetails.country,
        pincode: currentEventDetails.pincode,
      },
        venue: currentEventDetails.venue_name,
        date: currentEventDetails.event_start_time,
        time_in: currentEventDetails.event_start_time,
        time_out: currentEventDetails.event_end_time,
        event_file_progress: state.events_v2.eventEditor.status.fileUpload.progress,
        event_new_file: currentEventDetails.imgs,
        event_file_error: state.events_v2.eventEditor.status.fileUpload.error,
        loading: state.events_v2.eventEditor.status.loading,
        success: state.events_v2.eventEditor.status.success,
        error: state.events_v2.eventEditor.status.errorMessage,
    },
    activeEvents: activeEvents.data,
    upcomingEvents: upcomingEvents.data,
    pastEvents: pastEvents.data,
    edit_event_id: state.app.showEventModal,
    token: state.auth.auth_token,
    creatingEvent: state.events_v2.eventEditor.status.loading,
    createNewEventSuccess: state.events_v2.eventEditor.status.success,
    createNewEventError: state.events_v2.eventEditor.status.error,
    email: state.profile.email,
    event_new_file: currentEventDetails.imgs,
    event_file_progress: state.events_v2.eventEditor.status.fileUpload.progress,
    event_file_error:state.events_v2.eventEditor.status.fileUpload.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    autoFillEventDetails: (value) => dispatch({type: 'EVENT_EDIT_AUTO_FULL', payload: {value}}),
    showEvent: (val) => dispatch(showEventModal(val)),
    inputManager: (type, val) => dispatch(inputManager(type, val)),
    createEvent: (token) => dispatch(saveEvent(token)),
    clearEventFields: () => dispatch(clearEventFields()),
    fileManager: (type, files, email, arr = null) =>
      dispatch(fileManager(type, files, email, arr)),
    deleteImgfromArray: (type, index) =>
      dispatch(deleteImgfromArray(type, index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);

import api from "../../config/api";
import { toast } from "react-toastify/dist/react-toastify";
const intialState = {
    files:{
        null: {
            loading:false,
            error: null
        }
    }
}
const DOWNLOAD_START = 'DOWNLOAD_START';
const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
const DOWNLOAD_FAIL = 'DOWNLOAD_FAIL';
export function downloadReducer(state = intialState, action) {
    switch (action.type) {
        case DOWNLOAD_START:
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.payload]: {
                        loading: true,
                        error: null
                    }
                }
            }
        case DOWNLOAD_SUCCESS:
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.payload]: {
                        loading: false,
                        error: null
                    }
                }
            }
        case DOWNLOAD_FAIL:
            return {
                ...state,
                files: {
                    ...state.files,
                    [action.payload]: {
                        loading: false,
                        error: action.error
                    }
                }
            }
        default:
            return state;
    }
}

export function download({service, params, file_name}) {
    return async (dispatch, getState) => {
        try {
            const url = '/miscellaneous/export'
            const token = getState().auth.auth_token
            const progress = toast.loading('Exporting...')
            dispatch({type: DOWNLOAD_START,payload: file_name})
            const {data} = await api.post(url,{
                service,
                params
            },
            {
                responseType: 'blob',
                headers: {
                "X-AUTH-TOKEN": token,
              }, 
              onDownloadProgress: (progressEvent) => {
                toast.update(progress,{ progress: progressEvent.loaded/progressEvent.total });
              }
            })
            toast.update(progress, { type: toast.TYPE.SUCCESS, autoClose: 5000 , isLoading: false, render: 'Exported successfully' });
            const href = URL.createObjectURL(data);
    
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', file_name)
            document.body.appendChild(link);
            link.click();
    
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            dispatch({type: DOWNLOAD_SUCCESS,payload: file_name})
        }
        catch (err) {
            console.error(err);
            dispatch({type: DOWNLOAD_FAIL,error: err,})
        }
    }
}
import React, { Component, lazy, Suspense } from "react";

import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import firebaseAuth from "./components/auth/firebase";
import {
  setLogin,
  login,
  newUser,
  closeAlert,
  launchError,
} from "./reduxModules/auth";
import { getProfile } from "./reduxModules/profile";
import { setBasicProfile } from "./reduxModules/app";

import "./master.scss";

// Component Imports
import Auth from "./components/auth/index";
import Login from "./components/auth/login";
import Signup from "./components/auth/signup";
import AdminLogin from "./components/auth/adminlogin";

import SocialSignup from "./components/auth/googleSignup";

import SuccessSignUp from "./components/auth/successSignUp";

import Footer from "./components/footer";
import ErrorBoundary from "./components/shared/errorBoundaries";
import Profile from "./components/profile";
import EventModel from "./components/events/eventModal";
import EmailActions from "./components/auth/emailActions";
// import Home from './components/home';
import Loading from "./components/shared/loading/spinner";
import Navbar from "./components/shared/navbar";
import Parkings from "./pages/parkings";
import DeclineModal from "./components/dashboard/Verifications/DeclineModal";
import Earnings from "./pages/earnings";
import UploadModal from "./components/withdrawals/uploadModal";
import {FullPageLoader} from "./components/shared/loading/inProgress";
import Bookings from "./pages/bookings";
import Events from "./pages/events";
import Hosts from "./pages/hosts";
import Users from "./pages/users";
import {WithdrawalsPage}  from "./pages/v3/withdrawals.jsx";
import SpotCodeRequests  from "./pages/spotCodeRequests";
import Settings from "./pages/settings";
import Home from "./pages/home";
import Layout from "./components/shared/layout";
import { IntegrationsPage } from "./pages/v3/integrations";
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ModalView } from "./components/shared/Modal";
const SetInfo = lazy(() => import("./components/auth/createProfile"));
const Pending = lazy(() =>
  import("./components/parkings/ParkingStatus/Pending")
);
const Rejected = lazy(() =>
  import("./components/parkings/ParkingStatus/Rejected")
);
const WelcomeAboard = lazy(() =>
  import("./components/parkings/ParkingStatus/WelcomeAboard")
);
const Forgot = lazy(() => import("./components/auth/forgetPass"));

const PassChanged = lazy(() => import("./components/auth/passChange"));
const FAQ = lazy(() => import("./components/faq"));
const Contact = lazy(() => import("./components/contact"));
const NotFound = lazy(() => import("./components/notfound"));
const VerifyParking = lazy(() => import("./components/parkings/verification"));
// const Withdrawals = lazy(() => import("./components/withdrawals"));
// const Editor = lazy(() => import("./components/parkings/edit"));
// const CustomizeAvailability = lazy(() =>
//   import("./components/parkings/CustomizeAvailability")
// );

export const PrivateRoute = ({ render, loggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (loggedIn ? render(props) : <Redirect to="/login" />)}
    />
  );
};

export const AdminRoute = ({ render, role, loggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn && role === "admin" ? (
          render(props)
        ) : loggedIn ? (
          <Redirect to="/404" />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const adminLogins = [
  // "localhost:3000", // dev
  "admin.parkpoolr.com",
];

const { host } = window.location;

let isAdminSite = false;
if (adminLogins.includes(host)) {
  isAdminSite = true;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToSocialSignUp: false,
      currentUser: null,
      firebaseToken: null,
      loading: false,
    };
    this.facebookSignup = false;
  }

  loading = false;

  componentWillMount() {
    // if (this.state.loading == false) {
    //   this.setState({ loading: true })
    // }
  }

  loginWithGoogle = (e) => {
    this.loading = true;
    let _this = this;
    const googleProvider = new firebaseAuth.GoogleAuthProvider();
    firebaseAuth()
      .signInWithPopup(googleProvider)
      .then((res) => {
        if (res) {
          _this.loading = true;
          localStorage.setItem("userEmail", res.user.email);
          return firebaseAuth().currentUser.getIdToken();
        }
      })
      .then((token) => {
        this.loading = false;
        this.setState({ firebaseToken: token });
        this.props.login(token);
      })
      .catch((e) => {
        this.loading = false;
        switch (e.code) {
          case "auth/account-exists-with-different-credential":
            this.props.launchError(
              "User account exists with different sign up credential."
            );
            break;
          case "auth/user-disabled":
            this.props.launchError("User is disabled. Contact Admin");
            break;
          case "auth/invalid-credential":
            this.props.launchError(
              "Server error. This method is not supported."
            );
            break;
          default:
            console.error("SOME WEIRD ERROR ON REDIRECT", e);
            break;
        }
      });
  };

  doFaceBookLogin = (e) => {
    this.facebookSignup = true;
    this.loading = true;
    let provider = new firebaseAuth.FacebookAuthProvider();
    firebaseAuth()
      .signInWithPopup(provider)
      .then((res) => {
        if (res) {
          return firebaseAuth().currentUser.getIdToken();
        }
      })
      .then((token) => {
        this.loading = false;
        this.setState({ firebaseToken: token });
        this.props.login(token);
      })
      .catch((e) => {
        this.loading = false;
        switch (e.code) {
          case "auth/account-exists-with-different-credential":
            this.props.launchError(
              "User account exists with different sign up credential."
            );
            break;
          case "auth/user-disabled":
            this.props.launchError("User is disabled. Contact Admin");
            break;
          case "auth/invalid-credential":
            this.props.launchError(
              "Server Error. This signup method is not supported."
            );
            break;
          default:
            console.error("SOME WEIRD ERROR ON REDIRECT", e);
            break;
        }
      });
  };

  setUserStates = async (user) => {
    // firebaseAuth().currentUser.reload()

    if (user) {
      if (firebaseAuth().currentUser) {
        let currentUser = firebaseAuth().currentUser.toJSON();
        this.setState({ currentUser });

        let idToken = await firebaseAuth().currentUser.getIdToken();
        this.setState({ firebaseToken: idToken });
      }
    }

    if (!user) {
      localStorage.removeItem("FIREBASE_AUTH_TOKEN");
      sessionStorage.removeItem("FIREBASE_AUTH_TOKEN");
      localStorage.clear();
      firebaseAuth().signOut();
      this.props.setLogin(false);
    }
  };

  componentDidMount() {
    firebaseAuth().onAuthStateChanged((user) => {
      this.setUserStates(user);
    });
  }

  componentDidUpdate() {
    if (this.loading == false) {
      this.loading = true;
    }
    let currentUser = firebaseAuth().currentUser
      ? firebaseAuth().currentUser.toJSON()
      : null;
    let emailVerified =
      currentUser && currentUser.emailVerified
        ? currentUser.emailVerified
        : false;

    if (
      currentUser &&
      currentUser.email &&
      this.props.authError === "User Doesn't Exist!"
    ) {
      this.props.setLogin(false);
      if (!this.state.redirectToSocialSignUp) {
        this.setState({
          redirectToSocialSignUp: true,
          currentUser,
        });
        this.loading = false;
        this.props.closeAlert();
      }
    } else {
      this.loading = false;
      if (this.state.redirectToSocialSignUp) {
        this.setState({ redirectToSocialSignUp: false });
      }
    }
  }

  render() {
    return (
      <div className="App">
        {this.loading ? <Loading></Loading> : null}

        <Navbar isAdminSite={isAdminSite} />

        <div
          id="overlay-back"
          style={{
            display:
              this.props.showProfileModal ||
              this.props.showEventModal ||
              this.props.show_decline_modal ||
              this.props.showUploadModal
                ? "block"
                : "none",
          }}
        />

        <div className="container-fluid p-0">
          <ErrorBoundary>
            <Route
              exact
              path="/login"
              render={() => (
                <Auth adminLogin={isAdminSite}>
                  {!isAdminSite ? (
                    <Login
                      redirectToSocialSignUp={this.state.redirectToSocialSignUp}
                      loginWithGoogle={this.loginWithGoogle}
                      doFaceBookLogin={this.doFaceBookLogin}
                      loading={this.state.loading}
                    />
                  ) : (
                    <AdminLogin
                      redirectToSocialSignUp={this.state.redirectToSocialSignUp}
                      loading={this.state.loading}
                    />
                  )}
                </Auth>
              )}
            />

            <Route
              exact
              path="/adminlogin"
              render={() => (
                <Auth>
                  <AdminLogin
                    redirectToSocialSignUp={this.state.redirectToSocialSignUp}
                  />
                </Auth>
              )}
            />
            <Route
              exact
              path="/signup"
              render={() => (
                <Auth>
                  <Signup
                    loginWithGoogle={this.loginWithGoogle}
                    doFaceBookLogin={this.doFaceBookLogin}
                    redirectToSocialSignUp={this.state.redirectToSocialSignUp}
                    loading={this.state.loading}
                  />
                </Auth>
              )}
            />
            <Route
              exact
              path="/social-signup"
              render={() => (
                <Auth>
                  <SocialSignup
                    facebook={this.facebookSignup}
                    currentUser={this.state.currentUser}
                    firebaseToken={this.state.firebaseToken}
                  />
                </Auth>
              )}
            />
            <Route
              exact
              path="/signup-success"
              render={() => (
                <Auth>
                  <SuccessSignUp />
                </Auth>
              )}
            />
            <Route
              exact
              path="/forgot"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <Auth adminLogin={isAdminSite}>
                    <Forgot />
                  </Auth>
                </Suspense>
              )}
            />
            <Route
              path="/emailActions"
              render={({ match }) => (
                <Auth adminLogin={isAdminSite}>
                  <EmailActions action={match} />
                </Auth>
              )}
            />
            <Route
              path="/passchange"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <Auth>
                    <PassChanged />
                  </Auth>
                </Suspense>
              )}
            />
            <Route
              exact
              path="/info/faq"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <FAQ />
                </Suspense>
              )}
            />
            <Route
              exact
              path="/contact"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <Contact />
                </Suspense>
              )}
            />
            <Route
              exact
              path="/404"
              render={() => (
                <Suspense fallback={<Loading />}>
                  <NotFound />
                </Suspense>
              )}
            />

            <section>
              <PrivateRoute
                exact
                path="/"
                loggedIn={this.props.loggedIn}
                render={() => (
                    <Home />
                )}
              />
              <PrivateRoute
                exact
                path="/bookings"
                loggedIn={this.props.loggedIn}
                render={() => (
                  <Suspense fallback={<FullPageLoader />}>
                    <Bookings />
                  </Suspense>
                )}
              />
              <PrivateRoute
                loggedIn={this.props.loggedIn}
                path="/parkingsstatus/pending/:parkingId"
                render={({ match, location }) => (
                  <Layout>

                    <Pending
                      parkingId={match.params.parkingId}
                      location={location}
                    />
                  </Layout>
                )}
              />
              <PrivateRoute
                loggedIn={this.props.loggedIn}
                path="/parkingsstatus/rejected/:parkingId"
                render={({ match }) => (
                  <Layout>
                    <Rejected parkingId={match.params.parkingId} />
                  </Layout>
                )}
              />
              <PrivateRoute
                loggedIn={this.props.loggedIn}
                path="/parkingsstatus/approved/:parkingId"
                render={({ match }) => (
                  <Suspense fallback={<Loading />}>
                    <WelcomeAboard parkingId={match.params.parkingId} />
                  </Suspense>
                )}
              />
              <PrivateRoute
                path="/parkings"
                loggedIn={this.props.loggedIn}
                render={({ match, location }) => (
                  <Parkings match={match} location={location} />
                )}
              />


              <PrivateRoute
                exact
                path="/earnings"
                loggedIn={this.props.loggedIn}
                render={() => <WithdrawalsPage />}
              />
              <PrivateRoute
                exact
                path="/profile/set"
                loggedIn={this.props.loggedIn}
                render={() => (
                  <Suspense fallback={<Loading />}>
                    <SetInfo />
                  </Suspense>
                )}
              />

              {/* ADMIN ROUTES */}

              <AdminRoute
                exact
                path="/events"
                role={this.props.role}
                loggedIn={this.props.loggedIn}
                render={() => (
                        <Events />
                )}
              />
              <AdminRoute
                path="/hosts"
                role={this.props.role}
                loggedIn={this.props.loggedIn}
                render={({ match }) => (
                  <Suspense fallback={<Loading />}>
                    <Hosts match={match} />
                  </Suspense>
                )}
              />
              <AdminRoute
                path="/users"
                role={this.props.role}
                loggedIn={this.props.loggedIn}
                render={({ match }) => (
                  <Suspense fallback={<Loading />}>
                    <Users match={match} />
                  </Suspense>
                )}
              />
              <AdminRoute
                exact
                path="/settings"
                role={this.props.role}
                loggedIn={this.props.loggedIn}
                render={() => (
                  <Suspense fallback={<Loading />}>
                    <Settings />
                  </Suspense>
                )}
              />
              <AdminRoute
                exact
                path="/ttprequests"
                role={this.props.role}
                loggedIn={this.props.loggedIn}
                render={() => (
                    <SpotCodeRequests />
                )}
              />
              <AdminRoute
                path="/verifications/:id"
                role={this.props.role}
                loggedIn={this.props.loggedIn}
                render={({ match, location }) => (
                  <Suspense fallback={<Loading />}>
                    <VerifyParking
                      parkingId={match.params.id}
                      location={location}
                    />
                  </Suspense>
                )}
              />
              <AdminRoute
                exact
                path="/withdrawals"
                role={this.props.role}
                loggedIn={this.props.loggedIn}
                render={() => (
                    <WithdrawalsPage />
                )}
              />
              <AdminRoute
                exact
                path="/integrations"
                role={this.props.role}
                loggedIn={this.props.loggedIn}
                render={() => (
                    <IntegrationsPage/>
                )}
               />  
            </section>
            <ToastContainer
              position="bottom-left"
              />
            <ModalView id={'modal-global'}/>
          </ErrorBoundary>
        </div>
        {this.props.showProfileModal ? (
          <div className="container">
            <Profile />
          </div>
        ) : null}
        {this.props.showEventModal ? (
          <div className="container">
            <EventModel />
          </div>
        ) : null}
        {this.props.show_decline_modal ? (
          <div className="container">
            <DeclineModal />
          </div>
        ) : null}
        {this.props.showUploadModal ? (
          <div className="container">
            <UploadModal />
          </div>
        ) : null}

        {this.props.role !== "admin" && !this.loading ? (
          <div className="mt-1 p-5 bg-dark footer-app-container">
            <div className="container">
              <Footer isAdminSite={isAdminSite} />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedIn, rememberMe, auth_token, error } = state.auth;
  const {
    redirectToStatusPage,
    redirectToNamePage,
    showProfileModal,
    showEventModal,
    show_upload_modal,
  } = state.app;
  const { role } = state.profile;
  return {
    loggedIn,
    rememberMe,
    redirectToStatusPage,
    redirectToNamePage,
    token: auth_token,
    role,
    showProfileModal,
    showEventModal,
    showUploadModal: show_upload_modal,
    authError: error,
    show_decline_modal: state.app.show_decline_modal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (val) => dispatch(setLogin(val)),
    login: (token) => dispatch(login(token)),
    getProfile: (token) => dispatch(getProfile(token)),
    newUser: (token) => dispatch(newUser(token)),
    setBasicProfile: (val) => dispatch(setBasicProfile(val)),
    closeAlert: () => dispatch(closeAlert(true)),
    launchError: (e) => dispatch(launchError(e)),
  };
};

// eslint-disable no-unused-expressions
function eagerlyLoadLazyLoadedModules() {
  if('requestIdleCallback' in window) {
    requestIdleCallback(() => {
        console.log('Starting to eagerly load lazy loaded modules');
        const promises = [
        import('./components/users'),
        import('./components/users/usersTable'),
        import('./components/users/singleUser'),
        import('./components/hosts'),
        import('./components/hosts/singleHostTable'),
        import('./components/hosts/hostsTable'),
        import('./components/parkings/ParkingStatus'),
        import('./components/dashboard'),
        import('./components/dashboard/Overview'),
        import('./components/dashboard/UpcomingBookings'),
        // import('./pages/settings'),
        // import('./pages/spotCodeRequests'),
        // import('./pages/withDrawls'),
        // import('./pages/events'),
        // import('./pages/settings')
            ]
        Promise.all(promises).then((d)=> {
            console.log('Lazy loaded modules eagerly loaded due to idle time',d)
        })

    },{
        timeout: 2000
        }
        );
  }

    // eslint-enable no-unused-expressions
}
eagerlyLoadLazyLoadedModules();
export default connect(mapStateToProps, mapDispatchToProps)(App);

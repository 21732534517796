import React, {Suspense} from 'react';
import Sidebar from '../sidebar';
import { connect } from 'react-redux';
import './style.scss'
import BlockLoader, {FullPageLoader} from "../loading/inProgress";
import ErrorBoundary from "../errorBoundaries";

const Layout = (props) => {
    return (

        <React.Fragment>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-sm-2 sidebar_wrap px-0">
                        <Sidebar admin={props.admin} />
                    </div>
                    <div className="col-sm-10 p-4 ">
                        <ErrorBoundary>
                            <Suspense fallback={<BlockLoader waitingText=""/>}>
                                {props.children}
                            </Suspense>
                        </ErrorBoundary>
                    </div>
                </div>            
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { role } = state.profile;
    return {
        admin: role === 'admin' ? true : false,
    };
};

export default connect(mapStateToProps)(Layout);

import React from 'react';
import { Link } from 'react-router-dom';
import appStore from '../../Assets/imgs/footer/appstore.svg';
import fb from '../../Assets/imgs/footer/fb.svg';
import googlePlayStore from '../../Assets/imgs/footer/goopl.svg';
import insta from '../../Assets/imgs/footer/insta.svg';
import linkedin from '../../Assets/imgs/footer/li.svg';
import ParkPoolrLogo from '../../Assets/imgs/footer/ParkpoolLogo.svg';
import twitter from '../../Assets/imgs/footer/twit.svg';
import navigationUrls from '../../config/navigationUrls';
import './style.scss';

const _navigationUrls = navigationUrls

const HeadingLink = (props) => {
    if (!props.link) {
        return (
            <h6 className="display-6 footer_nav_link">
                {props.heading}
            </h6>
        )
    }
    return (
        <li>
            <a target={'_blank'} className="footer_nav_link" href={props.link}>
                {props.heading}
            </a>
        </li>
    );
};

const socials = [
    {
        link: _navigationUrls.facebook,
        src: fb,
    },
    {
        link: _navigationUrls.instagram,
        src: insta,
    },
    {
        link: _navigationUrls.twitter,
        src: twitter,
    },
    {
        link: _navigationUrls.linkedin,
        src: linkedin,
    }
];

const SocialLinks = (props) => {
    return (
        <ul className="mt-1 list-inline">
            {
                props.items.map((item, index) => {
                    return (
                        <li className="d-inline-block mr-2" key={index}>

                            <a target='_blank' href={item.link} className="social-icon">
                                <img src={item.src} alt={`Social ${index}`} />
                            </a>
                        </li>
                    )
                })
            }
            {props.children}
        </ul>
    );
};

export default props => {
    return (
        <React.Fragment>
            <div className="row text-light footer_wrap">
                <div className="col-md-4 col-lg-4">
                    <div className="fotter_brand">
                        <Link to='/bookings'>
                            <img src={ParkPoolrLogo} alt='' className="footer-logo" />
                        </Link>
                    </div>
                    <div className="social_links">
                        <small className="">FOLLOW US</small>
                        <SocialLinks items={socials}>
                            <div className="col" />
                            <div className="col" />
                        </SocialLinks>
                    </div>
                </div>
                <div className="col-md-3 col-lg-2">
                    <h5 className="display-6 fotter_nav_title">NAVIGATION</h5>
                    <ul className="footerNavLinksUL">
                        <HeadingLink link={_navigationUrls.howItsWork} heading="How It Works" />
                        <HeadingLink link={_navigationUrls.findParking} heading="Find Parking" />
                        {props.isAdminSite ? <HeadingLink link={_navigationUrls.hostPortal} heading="Host Portal" /> : null}
                        <HeadingLink link={_navigationUrls.faq} heading="FAQ" />
                    </ul>

                </div>
                <div className="col-md-3 col-lg-2">
                    <h5 className="display-6 fotter_nav_title">QUICK LINKS</h5>
                    <ul className="footerNavLinksUL">
                        {/* <HeadingLink link={_navigationUrls.news} heading="News" /> */}
                        <HeadingLink link={_navigationUrls.blog} heading="Blog" />
                        <HeadingLink link={_navigationUrls.privacyPolicy} heading="Privacy Policy" />
                        <HeadingLink link={_navigationUrls.termsConditions} heading="Terms & Conditions" />
                    </ul>
                </div>
            </div>

            <div className="hr"></div>

            <div className="row align-items-center">
                <div className="col-md-8">
                    <div className="text-left font-m">
                        <small className="text-white">&copy;2022-Present ParkPoolr. All rights reserved.</small>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="text-right">
                        <Link to='#' className="store-link-btns mr-4">
                            <img alt="play Store" src={googlePlayStore} />
                        </Link>
                        <a href={_navigationUrls.findParking} target={'_blank'} className="store-link-btns mr-0">
                            <img alt="App Store" src={appStore} />
                        </a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
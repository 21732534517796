import api from "../config/api";

const GETTING_ADMIN_PARKING_DATA = "GETTING_ADMIN_PARKING_DATA";
const GET_ADMIN_PARKING_DATA_SUCCESS = "GET_ADMIN_PARKING_DATA_SUCCESS";
const GET_ADMIN_PARKING_DATA_ERROR = "GET_ADMIN_PARKING_DATA_ERROR";

export const getAdminParkingData = (token, pageNumber = null, itemsPerPage = null, searchTerm = null) => {
  return (dispatch) => {

    const queryParams = {};
    if (pageNumber !== undefined && pageNumber !== null && itemsPerPage) {
      queryParams.offset = (pageNumber - 1) * itemsPerPage;
    }
    if (itemsPerPage) {
      queryParams.limit = itemsPerPage;
    }
    if (searchTerm) {
      queryParams.search_term = searchTerm;
    }


    const uri = "/parkings/admindata";

    dispatch({
      type: GETTING_ADMIN_PARKING_DATA,
    });
    api
      .get(uri, {
        headers: {
          "X-AUTH-TOKEN": token,
        },
        params: queryParams
      })
      .then(({ data }) => {

        const { spotCodes, count } = data.result;

        dispatch({
          type: GET_ADMIN_PARKING_DATA_SUCCESS,
          payload: {
            spotCodes,
            count,
          },
        });
      })
      .catch((err) => {
        console.error("ERROR FETCHING ADMIN PARKING DATA", err);
        dispatch({
          type: GET_ADMIN_PARKING_DATA_ERROR,
          payload: {
            errorMessage: err.message,
          },
        });
      });
  };
};

const initState = {
  loading: false,
  error: null,
  success: false,
  count: 0,
  spotCodes : {}
};
export default function spotCodesReducer(state = initState, action) {
  switch (action.type) {
    case GETTING_ADMIN_PARKING_DATA:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case GET_ADMIN_PARKING_DATA_SUCCESS:
      const spotCodes = action.payload.spotCodes;
      // console.log("SPOT_CODES", spotCodes);
      let extender = {};
      let prepender = "";
      if (window.location.hostname.split(".")[0] === "test") {
        prepender = "test.parkpoolr.io/";
      } else {
        prepender = "parkpoolr.io/";
      }
      spotCodes.forEach(item => {
        extender[item.id] = {
          ...item,
          unique_code: prepender + item.unique_code,
        };
      });
      return {
        ...state,
        spotCodes: extender,
        count: action.payload.count,
        loading: false,
        error: null,
        success: true,
      };
    case GET_ADMIN_PARKING_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.errorMessage,
        success: false,
      };
    default:
      return state;
  }
}

import { baseFetchQueryV3, qs } from "../../../config/api";
import { baseApi } from "../..";

export const usersApi = baseApi.injectEndpoints({
    reducerPath: 'v3/hosts',
    baseQuery: baseFetchQueryV3(),
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: ({limit, offset, search, date_range, type}) => {
                return {
                    url: `/users?${qs({
                        limit,
                        offset,
                        search,
                        date_range, 
                        type
                    })}`,
                }
            },
            transformResponse: response => response.result,
            providesTags: ['Hosts']
        })
    })
})

export const { useGetUsersQuery } = usersApi;
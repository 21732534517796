import API from "../config/api";

export const getReviews = (token, id) => {
  return (dispatch) => {
    dispatch({
      type: "GET_REVIEWS",
      payload: {
        id,
      },
    });

    API.get(`/parkings/reviews?parkingAreas=${id}`, {
      headers: { "X-AUTH-TOKEN": token },
    })
      .then(({ data }) => {
        dispatch({
          type: "GET_REVIEWS_SUCCESS",
          payload: {
            reviews: data.result,
            id,
          },
        });
      })
      .catch((err) => {
        console.error("Reviews getting error", err);
        dispatch({
          type: "GET_REVIEWS_ERROR",
          payload: {
            errorMessage: err.message,
            id,
          },
        });
      });
  };
};

export const clearReviews = () => {
  return {
    type: "CLEAR_REVIEWS",
  };
};

const initState = {
  loading: false,
  error: null,
};

export default function (state = initState, action) {
  switch (action.type) {
    case "GET_REVIEWS":
      return {
        ...initState,
        loading: true,
      };
    case "GET_REVIEWS_SUCCESS":
      const extender = {};
      action.payload.reviews.forEach((item) => extender[item.id] = item);
      return {
        ...state,
        ...extender,
        loading: false,
        error: null,
      };
    case "GET_REVIEWS_ERROR":
      return {
        ...initState,
        loading: false,
        error: action.payload.errorMessage,
      };
    case "CLEAR_REVIEWS":
      return {
        ...initState,
      };
    default:
      return state;
  }
}

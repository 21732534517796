import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as MainRouter } from "react-router-dom";
import { createLogger } from "redux-logger";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./index.css";
import 'jquery/dist/jquery.slim.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Popper from 'popper.js';
import {getStore} from "./reduxModules/index";
import * as serviceWorker from "./serviceWorker";
import "toastify-js/src/toastify.css";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const logger = createLogger();

ReactDOM.render(
  <Provider store={getStore()}>
    <MainRouter>
      {/* Temporary fix until dashboard is fixed */}
      <App />
    </MainRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

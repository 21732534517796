import React from "react"

import firebaseAuth from '../firebase';

import googleLogo from '../../../Assets/imgs/google-icon.svg';
import facebookLogo from '../../../Assets/imgs/facebook-icon.svg';


export default (props) => {

  // const doFaceBookLogin = (e) => {
  //   e.preventDefault();
  //   props.setLoading(true)
  //   let provider = new firebaseAuth.FacebookAuthProvider();
  //   firebaseAuth().signInWithRedirect(provider)
  // }


  return (
    <React.Fragment>
      <div className="row social-buttons-row">
        <div className=" col-sm-12 col-md-6 ">
          <div className="container text-right">
            <button
              type="button"
              className="btn btn-block social-buttons social-fb"
              name="button"
              onClick={(e) => props.doFaceBookLogin()}>
              <span><img className='social-icons' alt="facebook logo" src={facebookLogo} /></span>
              <span className='social-text'> 
              {
                props.isLoginPage ? "Login" : "Join"
              }  with Facebook </span>
            </button>
          </div>
        </div>
        <div className=" col-sm-12 col-md-6 ">
          <div className="container">
            <button type="button"
              id="google"
              name="button"
              className="btn btn-block social-buttons social-google"
              onClick={(e) => props.loginWithGoogle()}>
              <span><img className="social-icons social-google" alt="google logo" src={googleLogo} /></span>
              <span className='social-text'>
              {
                props.isLoginPage ? "Login" : "Join"
              } with Google </span>
            </button>
          </div>
        </div>
      </div>
      <div className="row text-center">
        <span className="divider">or</span>
      </div>
    </React.Fragment>
  )
}

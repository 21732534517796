import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import eye from '../../../Assets/imgs/eye.svg';
import { launchError, login, rememberMe } from '../../../reduxModules/auth';
import Spinner from '../../shared/loading/spinner';
import firebaseAuth from '../firebase';
import SocialButtons from '../socialButtons';


const Login = (props) => {

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [validatedEmail, setValidateEmail] = useState(false);
  const [loading, setLoading] = useState(false);


  const loginWithEmail = (email, password) => {
    window.localStorage.clear();
    window.sessionStorage.clear();

    props.launchError(null);
    firebaseAuth().signInWithEmailAndPassword(email, password)
      .then(() => {
        return firebaseAuth().currentUser.getIdToken();
      })
      .then((token) => {
        props.login(token);
      })
      .catch(err => {

        console.error("Login Error");
        console.error(err);

        let errorMessage;
        switch (err.code) {
          case 'auth/invalid-email':
            errorMessage = 'Email provided is Invalid.';
            break;
          case 'auth/user-disabled':
            errorMessage = 'Your account has been disabled';
            break;
          case 'auth/user-not-found':
            errorMessage = 'No account found for the provided email address.';
            break;
          case 'auth/wrong-password':
            firebaseAuth().fetchSignInMethodsForEmail(email)
              .then(result => {
                let errorMessage = "User Exists with the following methods";
                let flag = true;
                for (let provider of result) {
                  if (provider === "password") {
                    errorMessage = 'Password is invalid.';
                    flag = false;
                    break;
                  }
                }
                if (flag) {
                  errorMessage = `${errorMessage} ${result.join(',')} Please Login with one of these`;
                }
                props.launchError(errorMessage);
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              }).catch(error => {
                let errorMessage;
                if (error.code === "auth/invalid-email") {
                  errorMessage = "Email Provided is Invalid."
                }
                props.launchError(errorMessage);
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              })
            break;
          default:
            errorMessage = err.message;
            break;
        }
        props.launchError(errorMessage);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      });
  };


  const validate = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    let validated = expression.test(String(email).toLowerCase());
    setValidateEmail(validated);
    return validated;
  }

  useEffect(() => {
    props.launchError(null);
    window.localStorage.clear();
    window.sessionStorage.clear();
  }, []);


  useEffect(() => {
    setLoading(props.loading)
  }, [props.loading]);

  if (props.redirectToSocialSignUp && window.location.pathname !== "/social-signup") {
    console.log("redirecting to social signup");
    return <Redirect to='/social-signup' />
  }


  return (
    <React.Fragment>
      <p className="font-l primary-text-color semi-bold">Log In</p>
      <SocialButtons setLoading={setLoading} isLoginPage={true} loginWithGoogle={props.loginWithGoogle} doFaceBookLogin={props.doFaceBookLogin} />


      <div className="text-center">
        {
          loading ?
            <Spinner color="danger" />
            :
            null
        }
      </div>

      <div className="form-group">
        <label htmlFor="email">E-mail</label>
        <input onChange={(e) => { setEmail(e.target.value); validate(e.target.value) }} autoComplete="username" type="text" id="email" className="form-control global_input email-input" value={email} />
        <small className="text-muted">We'll never share your email with anyone.</small>
      </div>
      <div className="form-group mt-4">
        <div className="row">
          <div className="col">
            <label htmlFor="login_pass">Password</label>
          </div>
          <div className="col">
            <Link to='/forgot' className="float-right frgt-pass">Forgot Password</Link>
          </div>
        </div>

        <input onChange={(e) => setPass(e.target.value)} autoComplete="current-password" id="login_pass" type={showPass ? "text" : "password"} className="form-control global_input" value={pass} />
        <span className="all-seeing-eye"><img src={eye} alt="EYE" onClick={() => setShowPass(!showPass)} /></span>
      </div>

      <div className="custom-control custom-checkbox mb-3">
        <input type="checkbox" className="custom-control-input" id="customCheck" onChange={(e) => props.rememberMe(e.target.checked)} name="remember" />
        <label className="custom-control-label remember-me" htmlFor="customCheck"> Remember me </label>
      </div>

      <button disabled={props.loading || !email || !pass || pass.length < 6 ||
        !validatedEmail} className="btn global_btn btn-block" onClick={() => loginWithEmail(email, pass)}><span className='semi-bold login-btn'>Log In</span></button>
      <div>
        <hr />
      </div>
      <div className="text-center footer-text">
        <p>Don't have an account?&nbsp;<Link to='/signup'> <span className='semi-bold'> Sign Up </span> </Link></p>
      </div>

    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { error, rememberMe, loading } = state.auth;
  return {
    error,
    checked: rememberMe,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rememberMe: (val) => dispatch(rememberMe(val)),
    login: (token) => dispatch(login(token)),
    launchError: (err) => dispatch(launchError(err)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

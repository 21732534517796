import React from "react";
import { HostsList, FormattedDate, formatCurrency, TransactionCount } from "../common";
export const withdrawnColDef = [
    {
        label: "Date",
        key: "date",
        component: FormattedDate
    },
    {
        label: "Wallet Amount",
        key: "amount_to_host",
        component: ({ amount_to_host }) => (
            <p>{formatCurrency(amount_to_host / 100)}</p>
        ),
    },
    {
        label: "Hosts",
        key: "host_ids",
        component: props => <HostsList  {...props} />
    },
    {
        label: "Transactions",
        key: "transaction_count",
        component: props => <TransactionCount {...props} type="WITHDRAWN" status="PAID" />
    },
];
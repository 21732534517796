import api from '../config/api';
//Actions
const GETTING_HOSTS = "GETTING_HOSTS";
const GET_HOSTS_SUCCESS = "GET_HOSTS_SUCCESS";
const GET_HOSTS_ERROR = "GET_HOSTS_ERROR";
const DELETING_HOST = "DELETING_HOST";


//Acttion Creators
export const getHosts = (token, hostId = null, pageNumber = null, itemsPerPage = null, searchTerm = null) => {
    return dispatch => {
        dispatch({
            type: GETTING_HOSTS,
        });

        const queryParams = {};

        if (pageNumber !== undefined && pageNumber !== null && itemsPerPage) {
            queryParams.offset = (pageNumber - 1) * itemsPerPage;
        }
        if (itemsPerPage) {
            queryParams.limit = itemsPerPage;
        }
        if (searchTerm) {
            queryParams.search_term = searchTerm;
        }
        if (hostId) {
            queryParams.id = hostId;
        }

        const uri = `/profile/hosts/paged`;

        api.get(uri, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
            params: queryParams
        })
            .then(({ data }) => {

                if (!data || !data.success || !data.result) {
                    dispatch({
                        type: GET_HOSTS_ERROR,
                        payload: {
                            err: "Error",
                            errorMessage: "Error"
                        },
                    });
                    return
                }

                // // dont delete //
                if (hostId) {
                    let hosts = data.result.data;
                    console.log({ hosts });
                    dispatch({
                        type: "ADMIN_PARKING_PROFILE_DETAILS",
                        payload: (hosts && hosts.length) ? hosts[0] : {}
                    });
                }

                dispatch({
                    type: GET_HOSTS_SUCCESS,
                    payload: {
                        hosts: data.result.data,
                        count: data.result.count
                    },
                });
            })
            .catch(err => {
                console.error('ERROR while receiving parking data', err);
                dispatch({
                    type: GET_HOSTS_ERROR,
                    payload: {
                        err: err.response ? err.response.data : err,
                        errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                    },
                });
            });

    };
};

// export const getHostsCached = (token, id) => {
//     return (dispatch, getState) => {
//         const hosts = getState().hosts;
//         if (hosts[id] !== undefined) {

//             dispatch({
//                 type: "HOSTS_CACHE_HIT"
//             });

//             dispatch({
//                 type: "ADMIN_PARKING_PROFILE_DETAILS",
//                 payload: [
//                     hosts[id]
//                 ]
//             })
//         }
//         else {
//             dispatch(getHosts(token, id));
//         }
//     };
// };



//// Made By Vishnu resoju
export const deleteHost = (token, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: DELETING_HOST,
        });

        api.delete(`/profile/hosts?deleteId=${id}`, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
        })
            .then(({ data }) => {

                dispatch({
                    type: 'DELETED_HOST',
                    payload: {
                        id,
                    },
                });
            })
            .catch(err => {
                console.error("ERROR DELETING HOST", err);

            });
    };
};



//Reducers
const hostsInitState = {
    hosts: {},
    loading: false,
    error: null,
    total_hosts: 0,
};
export default function hostReducer(state = hostsInitState, action) {
    switch (action.type) {

        case GETTING_HOSTS:
            return { ...state, loading: true, error: null }

        case GET_HOSTS_SUCCESS:
            let hosts = action.payload.hosts;
            let count = action.payload.count;
            let dataHolder = {};
            hosts.forEach(item => { dataHolder[item.id] = item });
            return {
                ...state,
                hosts: dataHolder,
                loading: false,
                total_hosts: count,
                ...dataHolder
            };

        case GET_HOSTS_ERROR:
            return { ...state, loading: false, error: "Unknown Error Occured!" };

        case "DELETED_HOST":
            const newState = state;
            const { [action.payload.id]: deleteHost, ...restOfState } = newState;
            return {
                ...restOfState,
            };

        default:
            return state;
    }
}

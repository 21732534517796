import React from "react";
import Modal from "../../shared/Modal";
import { connect } from "react-redux";
import {
  rejectionReasonUpdate,
  refetchVerification,
  verifyParking,
  clearVerifyFields,
} from "../../../reduxModules/verifications";
import { showDeclineModal } from "../../../reduxModules/app";

class DeclineModal extends React.Component {
  render() {
    return (
      <Modal
        back_color="red-back p-3"
        button_style="btn btn-danger"
        button_text="Reject"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        title="Declining Parking Request"
        creatingAction={this.props.loading}
        successAction={this.props.verification_success}
        errorAction={this.props.verification_error}
        showFlagSetFunction={this.props.showDeclineModal}
        saveFunc={() => {
          this.props.verifyParking(
            this.props.token,
            this.props.parkingId,
            "rejected",
            this.props.rejection_reason
          );
          this.props.refetchVerification(true);
        }}
        clearFunction={() => this.props.clearVerifyFields()}
      >
        <div className="form-group m-4">
          <label htmlFor="decline-reason-label">Rejection Reason</label>
          <input
            className="form-control"
            type="text"
            id="decline-reason-label"
            onChange={(e) => this.props.rejectionReasonUpdate(e.target.value)}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    verification_success: state.verifyParking.success,
    verification_error: state.verifyParking.error,
    loading: state.verifyParking.loading,
    token: state.auth.auth_token,
    rejection_reason: state.verifyParking.rejection_reason,
    parkingId: state.app.declining_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rejectionReasonUpdate: (val) => dispatch(rejectionReasonUpdate(val)),
    refetchVerification: (bool) => dispatch(refetchVerification(bool)),
    verifyParking: (token, id, status, reject) =>
      dispatch(verifyParking(token, id, status, reject)),
    clearVerifyFields: () => dispatch(clearVerifyFields()),
    showDeclineModal: (bool) => dispatch(showDeclineModal(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclineModal);

import api from "../config/api";

const GETTING_ADMIN_DASHBOARD_COUNTS = "GETTING_ADMIN_DASHBOARD_COUNTS";
const GET_ADMIN_DASHBOARD_COUNTS_SUCCESS = "GET_ADMIN_DASHBOARD_COUNTS_SUCCESS";
const GET_ADMIN_DASHBOARD_COUNTS_ERROR = "GET_ADMIN_DASHBOARD_COUNTS_ERROR";

const GETTING_DASHBOARD_CHARTS_BOOKINGS = "GETTING_DASHBOARD_CHARTS_BOOKINGS";
const GET_DASHBOARD_CHARTS_BOOKINGS_SUCCESS = "GET_DASHBOARD_CHARTS_BOOKINGS_SUCCESS";
const GET_DASHBOARD_CHARTS_BOOKINGS_ERROR = "GET_DASHBOARD_CHARTS_BOOKINGS_ERROR";

const GETTING_DASHBOARD_CHARTS_EARNINGS = "GETTING_DASHBOARD_CHARTS_EARNINGS";
const GET_DASHBOARD_CHARTS_EARNINGS_SUCCESS = "GET_DASHBOARD_CHARTS_EARNINGS_SUCCESS";
const GET_DASHBOARD_CHARTS_EARNINGS_ERROR = "GET_DASHBOARD_CHARTS_EARNINGS_ERROR";

const GETTING_DASHBOARD_CHARTS_USERS = "GETTING_DASHBOARD_CHARTS_USERS";
const GET_DASHBOARD_CHARTS_USERS_SUCCESS = "GET_DASHBOARD_CHARTS_USERS_SUCCESS";
const GET_DASHBOARD_CHARTS_USERS_ERROR = "GET_DASHBOARD_CHARTS_USERS_ERROR";



export const fetchDashboardCounts = (type = "admin") => {
    return async (dispatch, getState) => {
        const selectedParkingFilter = getState().filters.parking_area.join(",");
        try {

            const { auth } = getState();
            const token = auth.auth_token;

            let uri = "/dashboard/admin/counts";
            if (type === "host") {
                uri = '/dashboard/host/counts'
            }
            if (selectedParkingFilter) {
                uri = `${uri}?parkings=${selectedParkingFilter}`;
            }
            dispatch({
                type: GETTING_ADMIN_DASHBOARD_COUNTS
            });

            let response = await api.get(uri, {
                headers: {
                    "X-AUTH-TOKEN": token,
                }
            });

            const data = response.data;
            if (data.success) {
                const counts = data.result;
                dispatch({
                    type: GET_ADMIN_DASHBOARD_COUNTS_SUCCESS,
                    payload: counts
                });
                return;
            }

            dispatch({
                type: GET_ADMIN_DASHBOARD_COUNTS_ERROR,
                payload: {
                    errorMessage: "Error",
                },
            });

        } catch (error) {
            console.error("ERROR FETCHING ADMIN COUNTS", error);
            dispatch({
                type: GET_ADMIN_DASHBOARD_COUNTS_ERROR,
                payload: {
                    errorMessage: error.message ? error.message : "Error",
                },
            });
        }
    };
};



export const fetchDashboardChartBookings = (type = "admin", startDate = null, endDate = null) => {
    return async (dispatch, getState) => {

        try {

            const { auth } = getState();
            const token = auth.auth_token;

            let uri = "/dashboard/charts/bookings";
            if (type === "host") {
                uri = "dashboard/charts/bookings";
            }

            const queryParams = {};
            if (startDate) {
                queryParams.start = startDate;
            }
            if (startDate && endDate) {
                queryParams.end = endDate;
            }

            dispatch({
                type: GETTING_DASHBOARD_CHARTS_BOOKINGS
            });

            let response = await api.get(uri, {
                headers: {
                    "X-AUTH-TOKEN": token,
                },
                params: queryParams
            });

            const data = response.data;
            if (data.success) {
                const graphData = data.result;
                dispatch({
                    type: GET_DASHBOARD_CHARTS_BOOKINGS_SUCCESS,
                    payload: graphData
                });
                return;
            }

            dispatch({
                type: GET_DASHBOARD_CHARTS_BOOKINGS_ERROR,
                payload: {
                    errorMessage: "Error",
                },
            });

        } catch (error) {
            console.error("ERROR FETCHING ADMIN COUNTS", error);
            dispatch({
                type: GET_DASHBOARD_CHARTS_BOOKINGS_ERROR,
                payload: {
                    errorMessage: error.message ? error.message : "Error",
                },
            });
        }
    };
};


export const fetchDashboardChartEarnings = (type = "admin", dateRange, parkings) => {
    return async (dispatch, getState) => {
        const startDate = dateRange && dateRange[0]
        const endDate = dateRange && dateRange[1]
        try {

            const { auth } = getState();
            const token = auth.auth_token;

            let uri = "/dashboard/charts/hostearnings";
            if (type === "host") {
                uri = "dashboard/charts/earnings";
            }
            const queryParams = {};

            if (parkings && parkings.length > 0) {
                queryParams.parkings = parkings.join(',')
            }
            if (startDate) {
                queryParams.start = startDate;
            }
            if (startDate && endDate) {
                queryParams.end = endDate;
            }

            dispatch({
                type: GETTING_DASHBOARD_CHARTS_EARNINGS
            });

            let response = await api.get(uri, {
                headers: {
                    "X-AUTH-TOKEN": token,
                },
                params: queryParams
            });

            const data = response.data;
            if (data.success) {
                const graphData = data.result;
                dispatch({
                    type: GET_DASHBOARD_CHARTS_EARNINGS_SUCCESS,
                    payload: graphData
                });
                return;
            }

            dispatch({
                type: GET_DASHBOARD_CHARTS_EARNINGS_ERROR,
                payload: {
                    errorMessage: "Error",
                },
            });

        } catch (error) {
            console.error("ERROR FETCHING ADMIN COUNTS", error);
            dispatch({
                type: GET_DASHBOARD_CHARTS_EARNINGS_ERROR,
                payload: {
                    errorMessage: error.message ? error.message : "Error",
                },
            });
        }
    };
};


export const fetchDashboardChartUsers = (type = "admin", startDate = null, endDate = null) => {
    return async (dispatch, getState) => {

        try {

            const { auth } = getState();
            const token = auth.auth_token;

            let uri = "/dashboard/charts/users";
            if (type === "host") {
                uri = "dashboard/charts/users";
            }

            const queryParams = {};
            if (startDate) {
                queryParams.start = startDate;
            }
            if (startDate && endDate) {
                queryParams.end = endDate;
            }

            dispatch({
                type: GETTING_DASHBOARD_CHARTS_USERS
            });

            let response = await api.get(uri, {
                headers: {
                    "X-AUTH-TOKEN": token,
                },
                params: queryParams
            });

            const data = response.data;
            if (data.success) {
                const graphData = data.result;
                dispatch({
                    type: GET_DASHBOARD_CHARTS_USERS_SUCCESS,
                    payload: graphData
                });
                return;
            }

            dispatch({
                type: GET_DASHBOARD_CHARTS_USERS_ERROR,
                payload: {
                    errorMessage: "Error",
                },
            });

        } catch (error) {
            console.error("ERROR FETCHING ADMIN COUNTS", error);
            dispatch({
                type: GET_DASHBOARD_CHARTS_USERS_ERROR,
                payload: {
                    errorMessage: error.message ? error.message : "Error",
                },
            });
        }
    };
};

const initialState = {
    loading: false,
    error: null,
    success: false,
    hostsCount: 0,
    usersCount: 0,
    bookingsCount: 0,
    parkingAreasCount: 0,
    commissionAmount: 0,
    earningsAmount: 0
};

export default function dashboardCountsReducer(state = initialState, action) {
    switch (action.type) {

        case GETTING_ADMIN_DASHBOARD_COUNTS:
            return {
                ...state,
                loading: true,
                error: null,
                success: false,
            };

        case GET_ADMIN_DASHBOARD_COUNTS_SUCCESS:

            const commission = action.payload.commission || 0;
            const hosts = action.payload.hosts || 0;
            const parkings = action.payload.parkings || 0;
            const users = action.payload.users || 0;
            const bookings = action.payload.bookings || 0;
            const earnings = action.payload.earnings || 0;


            return {
                ...state,
                loading: false,
                error: null,
                success: true,
                hostsCount: hosts,
                usersCount: users,
                parkingAreasCount: parkings,
                commissionAmount: commission,
                earningsAmount: earnings,
                bookingsCount: bookings,
            };

        case GET_ADMIN_DASHBOARD_COUNTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.errorMessage,
                success: false,
            };
        default:
            return state;
    }
}


const bookingsInitialState = {
    loading: false,
    error: null,
    success: false,
    data: {}
};

export function dashboardChartBookingsReducer(state = bookingsInitialState, action) {
    switch (action.type) {

        case GETTING_DASHBOARD_CHARTS_BOOKINGS:
            return {
                ...state,
                loading: true,
                error: null,
                success: false,
            };

        case GET_DASHBOARD_CHARTS_BOOKINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
                data: action.payload
            };

        case GET_DASHBOARD_CHARTS_BOOKINGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.errorMessage,
                success: false,
            };

        default:
            return state;
    }
}



const earningsInitialState = {
    loading: false,
    error: null,
    success: false,
    data: {}
};

export function dashboardChartEarningsReducer(state = earningsInitialState, action) {
    switch (action.type) {

        case GETTING_DASHBOARD_CHARTS_EARNINGS:
            return {
                ...state,
                loading: true,
                error: null,
                success: false,
            };

        case GET_DASHBOARD_CHARTS_EARNINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
                data: action.payload
            };

        case GET_DASHBOARD_CHARTS_EARNINGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.errorMessage,
                success: false,
            };

        default:
            return state;
    }
}



const usersInitialState = {
    loading: false,
    error: null,
    success: false,
    data: {}
};

export function dashboardChartUsersReducer(state = usersInitialState, action) {
    switch (action.type) {

        case GETTING_DASHBOARD_CHARTS_USERS:
            return {
                ...state,
                loading: true,
                error: null,
                success: false,
            };

        case GET_DASHBOARD_CHARTS_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
                data: action.payload
            };

        case GET_DASHBOARD_CHARTS_USERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.errorMessage,
                success: false,
            };

        default:
            return state;
    }
}





import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            err: null,
        };
    }

    static getDerivedStateFromError (error) {

        return {
            hasError: true,
            err: error.message,
        };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <small className="text-danger">Error could be due to network issues</small>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
import { baseApi } from "../..";
import { baseFetchQueryV3, qs } from "../../../config/api";

export const apiCallsApi = baseApi.injectEndpoints({
    reducerPath: 'v3/integrations/apiCalls',
    baseQuery: baseFetchQueryV3(),
    endpoints: (builder) => ({
        getAll: builder.query({
            query: (ids) => ({
                url: `/integrations/api-calls?${qs({
                    ids: Array.isArray(ids) ? ids : [ids]
                })}`
            }),
            transformResponse: (response,_, id) => Array.isArray(id) ? response.result.api_calls : response.result.api_calls[0],
            providesTags: (result, error, arg) => {
                return [{ type: 'ApiCalls', id: arg }]
            }
        }),
        retryFailed: builder.mutation({
            query: (ids) => ({
                url: `/integrations/api-calls/retry?${qs({
                    ids: Array.isArray(ids) ? ids : [ids]
                })}`,
                method: 'PATCH',
            }),
            invalidatesTags: (result, error, arg) => {
                return [{ type: 'ApiCalls', id: arg }]
            }
        })
    })
})
export const { useGetAllQuery, useRetryFailedMutation } = apiCallsApi;
import { baseApi } from "..";
import { qs } from "../../config/api";

export const parkingsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getParkings: builder.query({
            query: ({limit, offset, host_ids, parking_ids}) => {
                return {
                    url: `/parkings?${qs({
                        limit,
                        offset,
                        host_ids,
                        parking_ids
                    })}`,
                }
            },
            transformResponse: response => response.result,
            providesTags: ['Parkings']
        }),
    })
})

export const { useGetParkingsQuery } = parkingsApi;
import React from "react";
import { HostsList, FormattedDate, GROUP_BY_FIELD_ID, formatCurrency, formatNumber, TransactionCount } from "../common";
import { useModalView } from "../../../../shared/Modal";
import { useFieldValue } from "../../../../../reduxModules/tools/fieldValue";
import { useApproveWithdrawalMutation } from "../../../../../reduxModules/v3/transactions/withdrawals";
import { toast } from "react-toastify/dist/react-toastify";
export const pendingColDef = [
    {
        label: "Date",
        key: "date",
        component: FormattedDate
    },
    {
        label: "Wallet Amount",
        key: "amount_to_host",
        component: ({ amount_to_host }) => (
            <p>{formatCurrency(amount_to_host / 100)}</p>
        ),
    },
    {
        label: "Hosts",
        key: "host_ids",
        component: props => <HostsList  {...props} />
    },
    {
        label: "Transactions",
        key: "transaction_count",
        component: props => <TransactionCount {...props} type="WITHDRAWN" status="PROCESSING" />
    },
    {
      component: ApproveButton
    },
  ];
  // private functions
  function ApproveButton(data) {
      const { show, hide } = useModalView();
      return (
        <button
          onClick={() => show({ children: <ModalContent /> })}
          type="button"
          className="btn btn-success action"
        >
          Approve Request
        </button>
      );
  
      // private functions
      function ModalContent() {
        const [, groupBy] = useFieldValue(...GROUP_BY_FIELD_ID);
        const [approveTrigger, { isLoading }] = useApproveWithdrawalMutation();
        return (
          <>
            <div className="modal-header">
              <h5 className="modal-title">
                Are you sure you want to approve these withdrawal requests?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
  
            <div className="modal-body">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Date</th>
                    <td>
                      <FormattedDate date={data.date} />
                    </td>
                  </tr>
                  <tr>
                    <th>Amount to Withdraw</th>
                    <td>{formatCurrency(data.amount_to_host / 100)}</td>
                  </tr>
                  <tr>
                    <th>Transactions count</th>
                    <td>{formatNumber(data.transaction_count)}</td>
                  </tr>
                  <tr>
                    <th className="align-middle">Hosts ({data.host_ids.length})</th>
                    <td>
                      <HostsList {...data} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                disabled={isLoading}
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
                <button
                disabled={isLoading}
                  type="button"
                  className="btn btn-success action"
                  onClick={() => {
                    withdraw();
                  }}
                >
                  Approve Request
                </button>
            </div>
          </>
        );
        async function withdraw() {
          const params = {
            date: [data.date],
            host_ids: data.host_ids,
            parking_ids: data.parking_ids,
            date_group_by: groupBy,
          };
          await toast.promise(approveTrigger(params).unwrap(), {
            pending: `Approving withdrawal request for ${formatCurrency(data.amount_to_host / 100)} for ${data.host_ids.length} hosts`,
            success: {
              render({ data:{result} }) {
                return (
                  <>
                    <p>
                      Successfully withdrew {formatCurrency(
                        result.approvals.amount_to_host / 100
                      )} for {data.host_ids.length} hosts
                    </p>
                    </>
                )
              }
            },
            error: `Failed to approve withdrawal request for ${formatCurrency(
                data.amount_to_host / 100
                )} for ${data.host_ids.length} hosts`,
          });
          hide();
        }
      }
    }
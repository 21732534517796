import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import eye from '../../../Assets/imgs/eye.svg';
import { adminlogin, launchError, rememberMe } from '../../../reduxModules/auth';
import Spinner from '../../shared/loading/spinner';
import Brand from '../../shared/navbar/brand';
import firebaseAuth from '../firebase';



const AdminLogin = (props) => {

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [showPass, setShowPass] = useState(false);

  const loginWithEmail = (email, password) => {
    props.launchError(null);
    firebaseAuth().signInWithEmailAndPassword(email, password)
      .then(() => {
        return firebaseAuth().currentUser.getIdToken();
      })
      .then((token) => {
        props.adminlogin(token);
      })
      .catch(err => {
        let errorMessage;
        switch (err.code) {
          case 'auth/invalid-email':
            errorMessage = 'Email provided is Invalid.';
            break;
          case 'auth/user-disabled':
            errorMessage = 'Your account has been disabled';
            break;
          case 'auth/user-not-found':
            errorMessage = 'No account found for the provided email address.';
            break;
          case 'auth/wrong-password':
            firebaseAuth().fetchSignInMethodsForEmail(email)
              .then(result => {
                let errorMessage = "User Exists with the following methods";
                let flag = true;
                for (let provider of result) {
                  if (provider === "password") {
                    errorMessage = 'Password is invalid.';
                    flag = false;
                    break;
                  }
                }
                if (flag) {
                  errorMessage = `${errorMessage} ${result.join(',')} Please Login with one of these`;
                }
                props.launchError(errorMessage);
              }).catch(error => {
                let errorMessage;
                if (error.code === "auth/invalid-email") {
                  errorMessage = "Email Provided is Invalid."
                }
                props.launchError(errorMessage);
              })
            break;
          default:
            errorMessage = err.message;
            break;
        }
        props.launchError(errorMessage);
      });
  };

  useEffect(() => {
    props.launchError(null);
  }, []);

  if (props.redirectToSocialSignUp && window.location.pathname !== "/social-signup") {
    console.log("redirecting to social signup");
    return <Redirect to='/social-signup' />
  }


  return (
    <React.Fragment>
      <div className="container">
        <Brand/>
        <p className="font-l text-center primary-text-color semi-bold">Welcome back!</p>
      

        <div className="form-group">
          <label htmlFor="email">E-mail</label>
          <input onChange={(e) => setEmail(e.target.value)} type="text" id="email" autoComplete="username" className="form-control global_input email-input" value={email} />
        </div>
        <div className="form-group mt-4">

          <div className="row">
            <div className="col">
              <label htmlFor="login_pass">Password</label>
            </div>
          </div>

          <input onChange={(e) => setPass(e.target.value)} autoComplete="current-password" id="login_pass" type={showPass ? "text" : "password"} className="form-control global_input" value={pass} />
          <span className="all-seeing-eye"><img src={eye} alt="EYE" onClick={() => setShowPass(!showPass)} /></span>
        </div>

        <div className="mb-3">
          <Link to='/forgot' className="secondary-text-color frgt-pass">Forgot Password</Link>
        </div>

        <button disabled={props.loading || !email || !pass} className="btn global_btn btn-block" onClick={() => loginWithEmail(email, pass)}><span className='semi-bold login-btn'>Log In</span></button>
        
        <div className="text-center">
          {
            props.loading ?
              <Spinner color="danger" />
              :
              null
          }
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { error, rememberMe, loading } = state.auth;
  return {
    error,
    checked: rememberMe,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rememberMe: (val) => dispatch(rememberMe(val)),
    adminlogin: (token) => dispatch(adminlogin(token)),
    launchError: (err) => dispatch(launchError(err)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);

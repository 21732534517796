import React, { useMemo } from 'react'
import ballparcLogo from '../../Assets/imgs/logo_ballparc.ico'
import { useGetAllQuery, useRetryFailedMutation } from '../../reduxModules/v3/integrations/api-calls';
import { EnforcementStatus } from '../parkings/CustomizeAvailability/constants';
import ReactJson from 'react-json-view';
import BlockLoader from '../shared/loading/inProgress';
import { useModalView } from '../shared/Modal';
export const integrationsColDef = [
  {
    label: 'Booking ID',
    key: 'booking_id',
    component: (props) => <div>{props.booking_id}</div>
  },
  {
    key: 'enforcement_client_name',
    label: 'Enforcement Service',
    component: renderEnforcementClient
  },
  {
    key: 'license_plates',
    label: 'License Plates',
    component: (props) => <div>{props.license_plates.join(', ')}</div>
  },
  {
    key: 'booking_valid_from',
    label: 'Booking Date',
    component: (props) => <div>{getFormattedDateRange(props.booking_valid_from, props.booking_valid_to, props.parking_timezone)}</div>
  },
  {
    label: 'Parking',
    key: 'host_id',
    component: renderParkingInfo
  },
  {
    label: 'Status',
    key: 'api_call_status',
    component: (props) => <div>{renderStatusOption(props.api_call_status).label}</div>
  },
  {
    label: '',
    key: 'id',
    component: (props) => <InspectRetryButton {...props} />
  }
]
function InspectRetryButton(props) {
  const {show} = useModalView()
  return <div className="btn-group" role="group" aria-label="Basic example">
      <button type="button" className="btn btn-outline-secondary" 
          onClick={() => show({
            children:getInspectionModal({
              apiCallId: props.api_call_id,
              apiCallStatus: props.api_call_status
            })})}
        >Inspect</button>
      <RetryButton {...props} />
      </div>
}
export function RetryButton({api_call_id,api_call_status}) {
  const [retry,result] = useRetryFailedMutation()
  return canRetry() ?
  <button 
    onClick={() => retry(api_call_id)}
    type="button" 
    className={`btn ${result.isError ? 'btn-danger' : 'btn-outline-danger'}`}
  >
    {
      result.isLoading 
      ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Retrying </>
      : result.isError ?
        'Retry Failed'
        :'Retry Now'
    }
  </button> : null

function canRetry() {
  return  typeof api_call_status !=='undefined' && !(api_call_status === 'success' || api_call_status === 'pending')
}
}
export function renderStatusOption(status) {
  const statusOption = EnforcementStatus.find((option) => option.value === status)
  if(statusOption) {
    return {  
      ...statusOption,
      label:<div className={`${statusOption.class}`}>{statusOption.label}</div>
    }
  }
  return {
    value: status,
    label: <div className="text-muted"><code>{String(status)}</code></div>
  }
}

function getFormattedDateRange(start, end, timezone) {
  const formatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timezone
  })

  return formatter.formatRange(new Date(start), new Date(end))
}
function renderParkingInfo({ host_profile_pic, host_first_name, host_last_name, parking_id, parking_unique_code, parking_name}) {
  const host_name = host_first_name ? `${host_first_name} ${host_last_name}` : null;
  return (
    <div className="d-flex align-items-center">
      <img className='user-img mr-3' src={host_profile_pic} alt="user" />
      <div>
            <div className="text-muted">
                {parking_unique_code}
            </div>
            {host_name ? <div className="host-name"> {host_name} </div> : null}
        </div>
    </div>
  )
}
function renderEnforcementClient({enforcement_client_name, enforcement_operator_name}) {
  return <div className="d-flex align-items-center">
    <img className='mr-3' src={ballparcLogo} alt="user" />
    <span>
      <div className="text-capitalize">
          {enforcement_client_name}
      </div>
      <div className="host-name text-muted text-capitalize"> {enforcement_operator_name?.replace(/_/g, ' ')} </div>
    </span>
</div>
}


function getInspectionModal({ apiCallId, apiCallStatus }) {

  return <><div className="modal-header">
    <h5 className="modal-title">Inspect Enforcement API Call <span className="text-muted">#{apiCallId}</span></h5>
    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
    <div className="modal-body" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
      <ModalBody id={apiCallId} />
    </div>
    <div className="modal-footer">
      <RetryButton api_call_id={apiCallId} api_call_status={apiCallStatus} />
      <button type="button" className="btn btn-success" data-dismiss="modal">Close</button>
    </div></>

  function ModalBody({ id }) {
    const { isFetching, data: apiCallObject } = useGetAllQuery(id, {
      skip: !id
    })
    const inspectObject = useInspectObject(apiCallObject)
    return (!isFetching) && inspectObject
      ? <ReactJson src={inspectObject} name={false} collapsed="2" displayDataTypes={false} quotesOnKeys={false} />
      : <BlockLoader />
    function useInspectObject(data) {
      return useMemo(() => {
        if (data) {
          return {
            client: data.client,
            attempts: data.attempt_count,
            maximumAttempts: 10,
            ...data.last_error && { error: data.last_error },
            ...data.last_response && { response: data.last_response },
            payload: data.payload,
            url: data.url,
          }
        }
        return null;
      }, [data])
    }
  }
}
import React from 'react';

import logo from '../../../../Assets/imgs/BlackLogo.svg';

import "./style.scss"

export default props => {
    return (
        <img className='logo-img' alt="Brand" src={logo} style={{height: props.height? props.height : '100%', width: props.width? props.width : '100%'}} />
    );
};

import React from 'react';
import ClosePng from "./../../../Assets/imgs/Close.png";
import './style.scss';

export default props => {
    return (
        // alert alert-${props.type} alert-dismissible fade show  role="alert"
        <div className={`totalToaster p-2`} >
            <div className="row align-items-center">
                <div className="col-1" onClick={props.onClickHandler ? props.onClickHandler : e => e.preventDefault()}>
                    <img src={props.alertImg} alt="Alert" className="alert-image" />
                </div>
                <div className="col-10">
                    <p className="mb-0 heading semi-bold"><strong>{props.heading}</strong></p>
                    <p className="mb-0 normaText">{props.body}</p>
                </div>
                <div className="col-1 text-center">
                    { props.onClick ? <img src={ClosePng} style={{ height: 16, objectFit: 'contain', cursor:'pointer' }} onClick={props.onClick} /> : null }
                </div>
            </div>
        </div>
    );
};

import { toast } from 'react-toastify/dist/react-toastify';
import { useModalView } from './Modal';

const React = require('react');
const ExportIcon = require('../../Assets/imgs/export.svg');
const { default: api } = require('../../config/api');
const { useSelector } = require('react-redux');
export function ExportButton({service,params={}, className, label, nameTags=[]}) {
    const {download,isDownloaded,isLoading,error} = useExport({service,params,nameTags})
  return (
        <button
          disabled={isLoading || error}
          className={"download-btn btn btn-outline-success "+className} onClick={download}>
            <img src={ExportIcon} className="mr-2" width="20px" height="20px" alt='export' />
            {isLoading ? 'exporting...' : label}
        </button>  
  );
}

export function useExport({service,params={},nameTags=[]}) {
    const {show,hide} = useModalView();
    const token = useSelector(state => state.auth.auth_token);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [finished, setFinished] = React.useState(false);
    return {
      download: () => {
        show({
          children: <ModalContent/>
        })
      },
      isLoading,
      isDownloaded: finished,
      error
    }
    async function download(fileName) {
      try {
              const url = '/miscellaneous/export';
              const progress = toast.loading('Exporting...')
              setIsLoading(true);
              const {data} = await api.post(url,{
                  service,
                  params
              },
              {
                  responseType: 'blob',
                  headers: {
                  "X-AUTH-TOKEN": token,
                }, 
                onDownloadProgress: (progressEvent) => {
                  toast.update(progress,{ progress: progressEvent.loaded/progressEvent.total });
                }
              })
              toast.update(progress, { type: toast.TYPE.SUCCESS, autoClose: 2000 , isLoading: false, render: 'Exported successfully', progress: 1});
              setFinished(true);
              setIsLoading(false);
              const href = URL.createObjectURL(data);
              const link = document.createElement('a');
              link.href = href;
              link.setAttribute('download', fileName)
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
          } catch (error) {
              setIsLoading(false);
              setError(error);
              setFinished(true);
          }
    }
    function ModalContent() {
      const suffix = nameTags.filter(Boolean).join('_') || btoa(JSON.stringify(params).slice(0, 20));
      const [fileName, setFileName] = React.useState(`${service}_${suffix}.csv`);
      const disabled = isLoading || !fileName
      const inputRef = React.useRef();
      React.useLayoutEffect(() => {
        const timer = setTimeout(() => {
          inputRef.current.focus();
          selectSecondaryFileName()
        }, 500);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
      const submit = () => {
        if(disabled) return;
        download(fileName);
        hide();
      }
      const onKeyDown = (e) => {
        if(e.key === 'Enter') {
          submit();
        }
      }
      return <><div className="modal-header">
        <h5 className="modal-title">Export</h5>
        <button type="button" className="close" onClick={hide}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <h6 className='label'>Export file as:</h6>
        <input ref={inputRef} autoFocus={true} type="text" className="form-control lg-width global_input" value={fileName} onChange={e => setFileName(e.target.value)} onKeyUp={onKeyDown}/>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={hide}>Cancel</button>
        <button type="button" className={`btn btn-outline-success ${disabled ? 'pe-none' : ''}`}
          onClick={submit}
          disabled={disabled}
        >Export</button>
      </div></>
    function selectSecondaryFileName() {
      const index = fileName.search('_');
      if(index !== -1) {
        inputRef.current.setSelectionRange(index + 1, fileName.length-4);
      }
    }
    }
}
import axios from "axios";
import config from "../config/config";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import queryString from "qs";

export default axios.create({
  baseURL: config.API_URL,
});

export const api2 = axios.create({
  baseURL: config.API_URL_V2
})


export const api3 = axios.create({
  baseURL: config.API_URL_V3
})

export const baseFetchQueryV3 = () => fetchBaseQuery({
  baseUrl: config.API_URL_V3,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.auth_token
    if (token) {
      headers.set('X-AUTH-TOKEN', token);
    }
    return headers;
  }
})
export const baseFetchQueryV2 = () => fetchBaseQuery({
  baseUrl: config.API_URL_V2,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.auth_token;
    if (token) {
      headers.set('X-AUTH-TOKEN', token);
    }
    return headers;
  }
})
export const qs = (params) => queryString.stringify(params, { arrayFormat: 'brackets', skipNulls: true });
export const SET_PARKING_FILTER = 'SET_PARKING_FILTER';
export const SET_DATE_FILTER = 'SET_DATE_FILTER';
const defaultState = {
    parking_area: [],
    date_filter: {
        value: []
    },
};
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case SET_PARKING_FILTER:
            return {
                ...state,
                parking_area: action.payload,
            };
        case SET_DATE_FILTER:
            return {
                ...state,
                date_filter: action.payload,
            };
        default:
            return state;
    }
}

/**
 * @deprecated use useFieldValue instead
 */
export const setParkingFilter = (payload) => ({
    type: SET_PARKING_FILTER,
    payload,
});


/**
 * @deprecated use useFieldValue instead
 */
export const setDateFilter = (payload) => ({
    type: SET_DATE_FILTER,
    payload,
});

export function getFilterURLParams(state,filters) {
    const { parking_area, date_filter } = filters || state.filters;
    const areaFilter = parking_area.join(',');
    const dateFilter = date_filter.value?.map((date) => Number(date)).join(',');
    const params = new URLSearchParams();
    if(areaFilter) {
        params.append('parkings', areaFilter);
    }
    if(dateFilter) {
        params.append('date_range', dateFilter);
    }
    return params;
}
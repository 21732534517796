import React from "react";
import { renderToString } from 'react-dom/server'
import { connect } from "react-redux";
import Select, { createFilter, components } from 'react-select';
import { setParkingFilter } from "../../reduxModules/tools/filters";
import './style.scss';
import { getAdminParkings, getParkings } from "../../reduxModules/parkings";
import { useFieldValue } from "../../reduxModules/tools/fieldValue";


export const FilterByParkingArea = connect(mapStateToProps, mapDispatchToProps)(Filter);
function Filter(props) {
    const parkings = useParkings(props);
    const [,,setParkingAreas] = useFieldValue('field-filter-by-parking-areas',[])
    return (
        <div className="d-flex flex-column filter">
            <p className="label"> Parking Area </p>
            <Select
                styles={getStyles()}
                value={props.selected}
                isO
                onChange={(selected) => {
                    props.setParkingAreas(selected.map(x => x.value))
                    setParkingAreas(selected.map(x => x.value))
                }}
                placeholder="All Parkings"
                isMulti={true}
                filterOption={createFilter({
                    stringify: (option) => renderToString(option.label),
                    ignoreCase: true,
                    trim: true,
                })}
                components={{ MultiValueLabel: CustomMultiValueLabel }}
                className="select" options={parkings} />
        </div>
    );
    function CustomMultiValueLabel(props) {
        return <components.MultiValueLabel {...props}>
            {props.data.parking?.name}
        </components.MultiValueLabel>;
    }
}



function mapStateToProps({ parkings, filters , parkingsBasic, profile }) {
    const isDigit = (char) => /\d/.test(char);
    const isAdmin = profile?.role === 'admin';
    parkings = isAdmin ? parkingsBasic.parkings : parkings;
    parkings = Object.keys(parkings || {}).map((key) => {
        if (!isDigit(String(key).charAt(0))) {
            return null;
        }
        return {
            value: key,
            label: renderParkingOption(parkings[key]),
            parking: parkings[key]
        }
    }).filter((parking) => parking !== null);
    const selected = parkings.filter((parking) => filters.parking_area.includes(parking.value))
    return {
        parkings,
        filters,
        selected,
        isAdmin
    };
    function renderParkingOption(parking) {
        return <div>
            {parking.host_name ? <div className="host-name"> {parking.host_name} </div> : null}
            {parking.name}
            <div className="text-muted">
                {parking.unique_code}
            </div>
        </div>
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setParkingAreas: (parkingAreas) => dispatch(setParkingFilter(parkingAreas)),
        getHostParkings: () => dispatch(getParkings()),
        getAdminParkings: () => dispatch(getAdminParkings()),
    }
}
export function getStyles() {
    return {
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            backgroundColor: '#d0f7d4',
        }),
        multiValueRemove: (provided, state) => ({
            ...provided,
            backgroundColor: '#d0f7d4',
        }),
        option: (provided, {isSelected}) => ({
            ...provided,
            ...isSelected && {
                backgroundColor: '#3CCA23'
            },
            '&:hover': {
                backgroundColor: '#d0f7d4'
            }
        })
    }
}
function useParkings({isAdmin,getAdminParkings,getHostParkings, parkings}) {
    React.useEffect(() => {
        if (parkings.length > 0) return;
        if (isAdmin) {
            getAdminParkings();
        } else {
            getHostParkings();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return parkings;
}
import React, {lazy, useEffect} from 'react';
import Layout from "../../components/shared/layout";
import moment from "moment";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const BookingsTable = lazy(() => import('../../components/bookings'));
const adminTableColDefs = [
    {
        label: 'User Info',
        key: 'user_first_name',
        component: ({ user_first_name, user_last_name, user_email, user_mobile_number }) => {
            return (
                <>
                    {
                        user_first_name ? <p>{`${user_first_name} ${user_last_name}`}</p> : null
                    }
                    {
                        user_email ? <p style={{ fontSize: 11 }}>{user_email}</p> :
                        <p style={{ fontSize: 11 }}>{user_mobile_number}</p>
                    }
                </>
            );
        }
    },
    {
        label: 'Type',
        key: 'is_app',
        component: ({ is_app }) => <p>{is_app ? 'App' : 'TTP'}</p>
    },
    {
        label: 'License Plate',
        key: 'license_plate_number',
        component: ({ license_plate_number }) => <p className="text-uppercase">{license_plate_number}</p>
    },
    {
        label: 'Parking Area',
        key: 'host_first_name',
        component:   function({ host_profile_pic, host_first_name, host_last_name, parking_id, area_name}) {
            const host_name = host_first_name ? `${host_first_name} ${host_last_name}` : null;
            return (
              <div className="d-flex align-items-center">
                <img className='user-img mr-3' src={host_profile_pic} alt="user" />
                <div>
                      <div className="text-muted">
                          {host_name}
                      </div>
                      {area_name ? <div className="host-name"> {area_name} </div> : null}
                  </div>
              </div>
            )
          }
    },
    {
        label: 'Date & Time',
        key: 'valid_from',
        component: ({ valid_from, valid_to, timezone }) => getFormattedDateRange(valid_from,valid_to, timezone)
    },
    {
        label: 'Duration',
        key: 'valid_from',
        component: formatDuration
    },
    {
        label: 'Charge',
        key: 'amount',
        component: ({ amount }) => <p>{formatCurrency(amount/100)}</p>
    },
    {
        label: 'Trxn Status',
        key: 'transaction_status',
        component: ({ transaction_status,transaction_refunded }) => {
            const colorClasses = {
                PENDING: 'text-warning',
                PROCESSING: 'text-warning',
                CANCELLED: 'text-warning',
                REFUNDED: 'text-danger',
                FAILED: 'text-danger',
                PAID: 'green-text'
            }
            const className =  transaction_refunded ?  colorClasses.REFUNDED : colorClasses[transaction_status]
            const text = transaction_refunded ? 'REFUNDED' : transaction_status
            return <p className={className}>{text}</p>
        }
    }
];



export const hostTableColDefs = [
    {
        label: 'User Email',
        key: 'user_email',
        component: ({ user_first_name, user_last_name, user_email, user_mobile_number }) => {
            return (
                <>
                    {
                        user_first_name ? <p>{`${user_first_name} ${user_last_name}`}</p> : null
                    }
                    {
                        user_email ? <p style={{ fontSize: 11 }}>{user_email}</p> :
                        <p style={{ fontSize: 11 }}>{user_mobile_number}</p>
                    }
                </>
            );
        }
    },
    {
        label: 'License Plate',
        key: 'license_plate_number',
        component: ({ license_plate_number }) => <p className="text-uppercase">{license_plate_number}</p>
    },
    {
        label: 'Parking Area',
        key: 'area_name',
        component: function ({ area_unique_code, host_first_name, host_last_name, area_name}) {
            return (
              <div className="d-flex align-items-center">
                <div>
                      {area_name ? <div className="host-name"> {area_name} </div> : null}
                      <div className="text-muted">
                          {area_unique_code}
                      </div>
                  </div>
              </div>
            )
          }
    },
    {
        label: 'Date & Time',
        key: 'valid_from',
        component: ({ valid_from, valid_to, timezone }) => getFormattedDateRange(valid_from,valid_to, timezone)
    },
    {
        label: 'Duration',
        key: 'valid_from',
        component: formatDuration
    },
    {
        label: 'Charge',
        key: 'amount',
        component: ({ amount }) => <p>{formatCurrency(amount/100)}</p>
    },
    {
        label: 'Trxn Status',
        key: 'transaction_status',
        component: ({ transaction_status,transaction_refunded }) => {
            const colorClasses = {
                PENDING: 'text-warning',
                PROCESSING: 'text-warning',
                CANCELLED: 'text-warning',
                REFUNDED: 'text-danger',
                FAILED: 'text-danger',
                PAID: 'green-text'
            }
            const className =  transaction_refunded ?  colorClasses.REFUNDED : colorClasses[transaction_status]
            const text = transaction_refunded ? 'REFUNDED' : transaction_status
            return <p className={className}>{text}</p>
        }
    }
];



export default function BookingsPage() {
    const role = useSelector(state => state.profile.role);
    const config = role === 'admin' ? adminTableColDefs : hostTableColDefs;
    const {search} = useLocation();
    const tab = new URLSearchParams(search).get('tab');
    return <Layout>
        <BookingsTable colDefs={config} tab={tab} />
    </Layout>
}


function getFormattedDateRange(start, end, timezone) {
    const formatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timezone
    })
  
    return formatter.formatRange(new Date(start), new Date(end))
  }
function formatCurrency(amount) {
    return Number(amount).toLocaleString('en-US',{style:'currency', currency:'USD'})
}
function formatDuration({ valid_from, valid_to, timezone }){
    const start = moment(valid_from).tz(timezone)
    const end = moment(valid_to).tz(timezone).add(1, 'minute')
    const diff = moment.duration(end.diff(start))
    return humanize()
    function humanize() {
        const years = diff.years();
        const months = diff.months();
        const days = diff.days();
        const hours = diff.hours();
        const minutes = diff.minutes();

        const yearsText = years ? `${years} year${years > 1 ? 's' : ''}` : '';
        const monthsText = months ? `${months} month${months > 1 ? 's' : ''}` : '';
        const daysText = days ? `${days} day${days > 1 ? 's' : ''}` : '';
        const hoursText = hours ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
        const minutesText = minutes ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';

        return [yearsText, monthsText, daysText, hoursText, minutesText].filter(Boolean).join(', ');
    }
}
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { launchError } from "../../../../reduxModules/auth";
import firebaseAuth from "../../firebase";

// Custom Hook

const mapDispatchToProps = (dispatch) => {
  return {
    launchError: (msg) => dispatch(launchError(msg)),
  };
};

export default connect(null, mapDispatchToProps)((props) => {
  const [actionCode, setActionCode] = useState(null);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [email, setEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    props.launchError(null);
    setActionCode(props.actionCode);
    return () => {
      props.launchError(null);
    };
  }, []);

  const handleResetPassword = (p1, p2, ac) => {
    if (p1 === p2) {
      firebaseAuth().verifyPasswordResetCode(ac)
        .then((email) => {
          setEmail(email);
          firebaseAuth().confirmPasswordReset(ac, p1)
            .then((resp) => {
              setSuccess(true);
              props.launchError(null);
            })
            .catch(e => {
              switch (e.code) {
                case 'auth/expired-action-code':
                  props.launchError("Password reset email link has expired.");
                  break;
                case 'auth/invalid-action-code':
                  props.launchError("Password reset email has already been used or is tampered with.");
                  break;
                case 'auth/user-disabled':
                  props.launchError("User has been disabled by admin.");
                  break;
                case 'auth/user-not-found':
                  props.launchError("User not found. User may have been removed.");
                  break;
                case 'auth/weak-password':
                  props.launchError("Your password is too weak to continue.");
                  break;
                default:
                  props.launchError(e.message);
                  break;
              }
              setSuccess(false);
            });
        })
        .catch(e => {
          console.error("ERROR RESETTING PASSWORD");
          setError(e.message);
          setSuccess(false);
        });
    }
    else {
      props.launchError("Passwords do not match");
    }
  };

  if (success) {
    return <Redirect to={`/passchange?email=${email}`} />;
  }

  return (
    <React.Fragment>
      <section className="container">

        <div className=" text-left">
          <p className="font-l primary-text-color semi-bold">Create New Password</p>
        </div>

        <small>{props.email}</small>

        <div className=" custom_form_lable_container">
          <div className="form-group mb-3">
            <label htmlFor="new_pass">New Password</label>
            <input onChange={(e) => setPassword1(e.target.value)} value={password1} id="new_pass" type="password" className="global_input form-control" />
            <small className="text-muted">Set Password (min 6 characters)</small>
          </div>
          <div className="form-group">
            <label htmlFor="conf_pass">Confirm New Password</label>
            <input onChange={(e) => setPassword2(e.target.value)} value={password2} id="conf_pass" type="password" className="global_input form-control" />
          </div>
        </div>
        <div className="m-t-2">
          <button className="btn global_btn btn-block " disabled={!password1 || !password2 || password1.length < 6 || password2.length < 6} onClick={() => handleResetPassword(password1, password2, actionCode)}>Reset Password</button>
        </div>
        <div className="m-t-2">
          <hr />
        </div>

        <div className="text-center m-t-1">
          <p>I want to &nbsp;<Link to='/login' className="semi-bold">
            Log In</Link></p>
        </div>

      </section>

    </React.Fragment>
  )
});

export const SELECTION_BUCKETS = {
    EARNING_TRANSACTIONS: 'EARNING_TRANSACTIONS',
    // TODO ...
}
const initState = Object.fromEntries(Object.keys(SELECTION_BUCKETS).map(bucket => [bucket, []]));
const SELECT = "SELECT";
const DESELECT = "DESELECT";
const CLEAR = "CLEAR";
export const SelectionToolReducer = (state = initState, action) => {
    switch (action.type) {
        case SELECT:
            return {
                ...state,
                [action.payload.bucket]: [...new Set([...state[action.payload.bucket], ...action.payload.ids])]
            };
        case DESELECT:
            return {
                ...state,
                [action.payload.bucket]: [...new Set([...state[action.payload.bucket]].filter(id => !action.payload.ids.includes(id)))]
            };
        case CLEAR:
            return {
                ...state,
                [action.payload.bucket]: [...new Set()]
            };
        default:
            return state;
    }
}
export function select(ids, bucket) {
    return (dispatch, getState) => {
        if(bucket in SELECTION_BUCKETS) {
            dispatch({
                type: SELECT,
                payload: {
                    ids: [...new Set(ids)],
                    bucket
                }
            });
        }
    }
}

export function deselect(ids, bucket) {
    return (dispatch, getState) => {
        if(bucket in SELECTION_BUCKETS) {
            dispatch({
                type: DESELECT,
                payload: {
                    ids: [...new Set(ids)],
                    bucket
                }
            });
        }
    }
}
export function clear(bucket) {
    return (dispatch, getState) => {
        if(bucket in SELECTION_BUCKETS) {
            dispatch({
                type: CLEAR,
                payload: {
                    bucket
                }
            });
        }
    }
}
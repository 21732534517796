import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import defaultUser from '../../../Assets/imgs/defaultUser.svg';
import navigationUrls from "../../../config/navigationUrls";
import { showProfileModal } from "../../../reduxModules/app";
import { getProfile } from "../../../reduxModules/profile";
import firebaseAuth from '../../auth/firebase';
import Brand from './brand';

const _navigationUrls = navigationUrls;

class Dropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }


  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.containerRef.current && !this.containerRef.current.contains(event.target)) {
      this.props.open(false);
    }
  }

  render() {
    return (
      <ul className="list-inline mb-0 p-3" ref={this.containerRef}  >
        <li className="mb-2 pointer primary-text-color font-m" onClick={(e) => {
          e.preventDefault();
          this.props.profileClick();
        }}>
          Profile
        </li>
        <li className=" pointer font-m text-danger" onClick={() => this.props.onClick()} > Logout </li>
      </ul>
    )
  }
}


const navBar = withRouter((props) => {

  const [dropdown, setDropdown] = useState(false);

  const signOut = () => {

    firebaseAuth().signOut()
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      })
      .catch(err => {
        console.log("ERROR OCURRED WHILE LOGGING OUT");
      });
  };

  useEffect(() => {
    props.getProfile(props.token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <nav className='navbar border-bottom navbar-light'>

      <Link className="navbar-brand" to='/bookings'>
        <Brand width="212px" />
      </Link>

      <div className="navbar navbar-expand-lg navbar-right " id="navbarLinks">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          <li className="nav-item">
            <a className="nav-link primary-text-color" target="_blank" href={_navigationUrls.howItsWork}>HOW IT WORKS <span className="sr-only"></span></a>
          </li>
          <li className="nav-item">
            <a className="nav-link primary-text-color" target="_blank" href={_navigationUrls.findParking}>FIND PARKING <span className="sr-only"></span></a>
          </li>
          {props.isAdminSite ? <li className="nav-item">
            <a className="nav-link primary-text-color" target="_blank" href={_navigationUrls.hostPortal}>HOST PORTAL<span className="sr-only"></span></a>
          </li> : null
          }
          <li className="nav-item">
            <a className="nav-link primary-text-color" target="_blank" href={_navigationUrls.faq}>FAQ<span className="sr-only"></span></a>
          </li>
          <li className="nav-item">
            <a className="nav-link  white-text text-white contact" target="_blank" href={_navigationUrls.contact}>Contact<span className="sr-only"></span></a>
          </li>
        </ul>

      </div>

      <form className='form-inline ml-auto'>
        {
          props.loggedIn ?
            <div className="dropdown navbar-dropdown-only">
              <button className="btn  p-0 dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span><img src={props.profile_pic || defaultUser} id="profile_pic" alt="def" />  <i className="fa fa-angle-down rotate_angle"></i>  </span>
              </button>
              <div className="dropdown-menu p-0 right-dropdown" aria-labelledby="dropdownMenuButton">
                <Dropdown onClick={() => signOut()} profileClick={() => props.showProfile(true)} open={setDropdown} />
              </div>
            </div>
            :
            null
        }
      </form>
    </nav>

  );
});


const mapStateToProps = (state) => {

  const { loggedIn, auth_token } = state.auth;
  const { profile_pic, role } = state.profile;

  return {
    token: auth_token,
    loggedIn,
    profile_pic,
    admin: role === 'admin' ? true : false,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showProfile: (val) => dispatch(showProfileModal(val)),
    getProfile: (token) => dispatch(getProfile(token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(navBar);


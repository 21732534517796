const BASE_URL = "http://www.parkpoolr.com/";

const NAVIGATION_URLS = {
    /// Footer & Navbar Links ////
    howItsWork: `${BASE_URL}/how-it-works/`,
    findParking: "https://apps.apple.com/us/app/parkpoolr/id1509892715",
    faq: `${BASE_URL}/faq/`,
    contact: `${BASE_URL}/contact/`,

    // Other //
    news: `${BASE_URL}/news/`,
    blog: `${BASE_URL}/blog/`,
    privacyPolicy: `${BASE_URL}/privacy-policy/`,
    termsConditions: `${BASE_URL}/terms-and-conditions/`,


    /// Social Media links ////
    facebook: "https://www.facebook.com/ParkPoolr",
    instagram: "https://www.instagram.com/parkpoolr/?hl=en",
    twitter: "https://www.twitter.com/parkpoolr",
    linkedin: "https://www.linkedin.com/company/parkpoolr-llc",

    // Host Portal Url
    hostPortal: "https://host.parkpoolr.com/"
}
export default NAVIGATION_URLS;
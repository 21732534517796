import {api2} from "../config/api";
import {
    formatTheDataArray,
    GET_ACTIVE_BOOKINGS,
    GET_CANCELLED_BOOKINGS,
    GET_PAST_BOOKINGS,
    GET_UPCOMING_BOOKINGS,
    GOT_ACTIVE_BOOKINGS_ERROR,
    GOT_ACTIVE_BOOKINGS_SUCCESS,
    GOT_CANCELLED_BOOKINGS_ERROR, GOT_CANCELLED_BOOKINGS_SUCCESS,
    GOT_PAST_BOOKINGS_ERROR, GOT_PAST_BOOKINGS_SUCCESS,
    GOT_UPCOMING_BOOKINGS_ERROR, GOT_UPCOMING_BOOKINGS_SUCCESS
} from "./bookings";

const bookingTypes = {
    current: {
        start: GET_ACTIVE_BOOKINGS,
        error: GOT_ACTIVE_BOOKINGS_ERROR,
        finished: GOT_ACTIVE_BOOKINGS_SUCCESS
    },
    past: {
        start: GET_PAST_BOOKINGS,
        error: GOT_PAST_BOOKINGS_ERROR,
        finished: GOT_PAST_BOOKINGS_SUCCESS
    },
    upcoming: {
        start: GET_UPCOMING_BOOKINGS,
        error: GOT_UPCOMING_BOOKINGS_ERROR,
        finished: GOT_UPCOMING_BOOKINGS_SUCCESS
    },
    cancelled: {
        start: GET_CANCELLED_BOOKINGS,
        error: GOT_CANCELLED_BOOKINGS_ERROR,
        finished: GOT_CANCELLED_BOOKINGS_SUCCESS
    }
}
/**
 *
 * @param {'past'|'current'|'upcoming'|'cancelled'} bookingType
 */
export const getBookings = (
    bookingType,
    token,
    hostId = null,
    userId = null,
    pageNumber = null,
    itemsPerPage = null,
    searchTerm = null
) => {
    const {start:startActionType, error:errorActionType, finished:finishedActionType} = bookingTypes[bookingType];
    return (dispatch,getState) => {
        const selectedParkingFilter = getState().filters.parking_area.join(",");
        const dateRangeFilterUnix = getState().filters.date_filter.value?.map((date) => Math.round(Number(date))).join(",");
        dispatch({
            type: startActionType,
        });

        const queryParams = {};
        if (hostId) {
            queryParams.host_id = hostId;
        }
        if (userId) {
            queryParams.user_id = userId;
        }
        if (pageNumber !== undefined && pageNumber !== null && itemsPerPage) {
            queryParams.offset = (pageNumber - 1) * itemsPerPage;
        }
        if (itemsPerPage) {
            queryParams.limit = itemsPerPage;
        }
        if (searchTerm) {
            queryParams.search_term = searchTerm;
        }
        
        let uri = `/bookings/${bookingType}/paged`;
        const params = new URLSearchParams();

        if (selectedParkingFilter) {
            params.append("parkings", selectedParkingFilter);
        }
        if (dateRangeFilterUnix) {
            params.append("date_range", dateRangeFilterUnix);
        } 
        uri = `${uri}?${params}`;

        api2
            .get(uri, {
                headers: {
                    "X-AUTH-TOKEN": token,
                },
                params: queryParams,
            })
            .then(({ data }) => {
                // console.log("all bookings");
                // console.log(data);

                if (!data || !data.result || !data.result.data) {
                    dispatch({
                        type: errorActionType,
                    });
                    return;
                }


                dispatch({
                    type: finishedActionType,
                    payload: {...data.result},
                });
            })
            .catch((err) => {
                console.error("ERROR occurred while fetching bookings", err);
                dispatch({
                    type: errorActionType,
                });
            });
    };
};

function getDefaultBookingsStateByType(bookingType) {
     /**
     *
     * @type {{loading: boolean, error: boolean}|{}|{loading: boolean, error: boolean, bookings:{data: [], total: number}}}
     */
    const defaultBookingState = {
            type: bookingType,
            loading: false,
            error: false,
            stale: true,
            bookings: {
                data: {},
                total: 0
            }
        }
    return defaultBookingState;
}
/**
 * @usage createReducerBookingForBookingType('active') . This will create a reducer for active bookings
 * @description This method will create a reducer for each booking type. So, finally, we will have 4 reducers:
 *  - activeBookings
 *  - pastBookings
 *  - upcomingBookings
 *  - cancelledBookings
 *
 * @param {'past'|'current'|'upcoming'|'cancelled'} bookingType
 */
export const createReducerForBookingType = (bookingType) => {
    const defaultBookingState = getDefaultBookingsStateByType(bookingType);
    function bookingReducer(state = defaultBookingState, action) {
        const {start:startActionType, error:errorActionType, finished:finishedActionType} = bookingTypes[bookingType];
        switch (action.type) {
            case startActionType: // example GET_ACTIVE_BOOKINGS, GET_PAST_BOOKINGS, GET_UPCOMING_BOOKINGS, GET_CANCELLED_BOOKINGS
                return {
                    ...state,
                    loading: true,
                    stale: true,
                    error: false,
                };
            case errorActionType: // example GOT_ACTIVE_BOOKINGS_ERROR, GOT_PAST_BOOKINGS_ERROR, GOT_UPCOMING_BOOKINGS_ERROR, GOT_CANCELLED_BOOKINGS_ERROR
                return {
                    ...state,
                    loading: false,
                    error: true,
                    stale: true,
                    bookings: {
                        data: {},
                        total: 0
                    }
                };
            case finishedActionType: // example GOT_ACTIVE_BOOKINGS_SUCCESS, GOT_PAST_BOOKINGS_SUCCESS, GOT_UPCOMING_BOOKINGS_SUCCESS, GOT_CANCELLED_BOOKINGS_SUCCESS
                return {
                    ...state,
                    loading: false,
                    error: false,
                    stale: false,
                    bookings: {
                        data: formatTheDataArray(action.payload.data),
                        total: action.payload.total
                    }
                };
            default:
                return state;
        }
    }
    return bookingReducer;
}
export default getBookings;
import React from "react";
import { FormattedDate, formatCurrency, ParkingsList, TransactionCount } from "../common";
export const pendingColDef = [
    {
        label: "Date",
        key: "date",
        component: FormattedDate
    },
    {
        label: "Wallet Amount",
        key: "amount_to_host",
        component: ({ amount_to_host }) => (
            <p>{formatCurrency(amount_to_host / 100)}</p>
        ),
    },
    {
      label: "Parkings",
      key: "area_ids",
      component: (props) => <ParkingsList {...props} />,
    },
    {
        label: "Transactions",
        key: "transaction_count",
        component: props => <TransactionCount {...props} type="WITHDRAWN" status="PROCESSING" />
    }
  ];
import React, { createRef, useState } from "react";
import { connect } from "react-redux";

import defaultUser from "../../Assets/imgs/defaultUser.svg";

import firebaseAuth from "./../auth/firebase";

import inputManager from "../../actions/inputManager";
import StdInput from "../parkings/Shared/StdInput";
import { showProfileModal } from "../../reduxModules/app";
import fileManager from "../../actions/fileManager";
import {
  updateProfile,
  clearProfileData,
  getProfile,
} from "../../reduxModules/profile";
import Modal from "../shared/Modal";
import { deleteImgfromArray } from "../../actions/fileManager";
import { UPDATE_PROFILE_IMAGE } from "../../reduxModules/profile";

class Profile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      changePassword: false,
      newPassword: null,
      confirmPassword: null,
      passwordMismatch: false,
      errorOcurred: false,
      recent: false,
      changePasswordBtnEnable: false
    };

    this.setChangePassword = this.setChangePassword.bind(this);
    this.checkBothPasswords = this.checkBothPasswords.bind(this);
  }

  checkBothPasswords(val, type) {
    let obj = {};
    obj[type] = val;

    this.setState(obj, () => {
      let newPassword = this.state.newPassword;
      let confirmPassword = this.state.confirmPassword;

      if (!newPassword || !newPassword.length) {
        return;
      }
      if (!confirmPassword || !confirmPassword.length) {
        return;
      }

      this.setState({ passwordMismatch: false });
      if (newPassword !== confirmPassword) {
        this.setState({ passwordMismatch: true });
      }
    });
  }

  componentDidMount() {
    this.props.getProfile(this.props.token);

    let user = firebaseAuth().currentUser;
    // console.log("user");
    // console.log(user);

    firebaseAuth().fetchSignInMethodsForEmail(user.email).then(res => {
      // console.log(res);
      // console.log("res");

      let providerFind = res.find((e) => {
        return e === 'password';
      });
      // console.log("providerFind");
      // console.log(providerFind);
      if (providerFind) {
        this.setState({
          changePasswordBtnEnable: true
        })
      } else {
        this.setState({
          changePasswordBtnEnable: false
        })
      }

    })
  }

  setChangePassword = (val) => {
    this.setState({ changePassword: val });
  };

  updatePasswordNow = () => {
    if (this.state.passwordMismatch) {
      console.error("Miss match password");
      return;
    }
    let user = firebaseAuth().currentUser;
    let _th = this;
    // console.log("tryoing");

    user
      .updatePassword(this.state.newPassword)
      .then(() => {
        // Update successful.
        alert("Password changed successfully!")
        this.props.showProfile(false);    
      })
      .catch((error) => {
        // An error happened.
        switch (error.code) {
          case "auth/requires-recent-login":
            _th.setState({ recent: true });
            break;
          default:
            console.error("Error ocurred in profile change password", error);
            _th.setState({ errorOcurred: true });
            break;
        }
      });
  };

  render() {
    return (
      <section>
        {!this.state.changePassword ? (
          <Modal
            creatingAction={this.props.uploading}
            errorAction={this.props.uploadError}
            successAction={this.props.uploadSuccess}
            showFlagSetFunction={this.props.showProfile}
            title={"Profile"}
            saveFunc={() => this.props.updateProfile(this.props.token)}
            clearFunction={() => this.props.clearProfileData()}
          >
            <div className="p-3 py-4">
              <div className="text-left mb-4">
                <div className="col">
                  <div className="d-inline-block text-center">
                    <img
                      src={this.props.profile_pic || defaultUser}
                      className="profile_pic"
                      alt="Profile"
                    />
                    <div className="mt-2 position-relative">
                      <input
                        type="file"
                        className="form-control inivisble-input"
                        onChange={(e) =>
                          this.props.fileManager(
                            UPDATE_PROFILE_IMAGE,
                            e.target.files,
                            this.props.email
                          )
                        }
                      />
                      {this.props.loading ? (
                        <p className="font-s primary-text-color mb-0">
                          loading..
                        </p>
                      ) : (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          Change
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="only-profile-texts">
                <div className="mb-1">
                  <StdInput
                    placeholder="First Name"
                    value={this.props.first_name}
                    onChange={(e) =>
                      this.props.inputManager("SET_FIRST_NAME", e.target.value)
                    }
                    id="first-name"
                    label="First Name"
                  />
                </div>
                <div className="mb-1">
                  <StdInput
                    placeholder="Last Name"
                    value={this.props.last_name}
                    onChange={(e) =>
                      this.props.inputManager("SET_LAST_NAME", e.target.value)
                    }
                    id="second-name"
                    label="Last Name"
                  />
                </div>
              </div>

              <div className="only-profile-texts dull">
                <div className="mb-2">
                  <div className="col">
                    <small className="text-muted">Email</small>
                    <p className="primary-text-color font-m">
                      {this.props.email}
                    </p>
                  </div>
                </div>

                <div className="col">
                  <small className="text-muted">Phone</small>
                  <p className="primary-text-color font-m">
                    {this.props.mobile_number}
                  </p>
                </div>
              </div>
              {
                this.state.changePasswordBtnEnable ? <div className="col">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setChangePassword(true);
                    }}
                  >
                    Change password
                  </a>
                </div>
                  : null
              }


            </div>
          </Modal>
        ) : (
          <Modal
            title={"Change Password"}
            clearFunction={() => this.props.clearProfileData()}
            showFlagSetFunction={this.props.showProfile}
            saveFunc={() => this.updatePasswordNow()}
            button_text="Change password"
          >
            <div className="p-3 py-4">
              <div className="mb-1">
                <StdInput
                  placeholder="New Password"
                  type="password"
                  onChange={(e) => {
                    this.checkBothPasswords(e.target.value, "newPassword");
                  }}
                  id="first-name"
                  label="New  Password"
                />
              </div>
              <div className="mb-1">
                <StdInput
                  placeholder="Confirm password"
                  type="password"
                  onChange={(e) => {
                    this.checkBothPasswords(e.target.value, "confirmPassword");
                  }}
                  id="second-name"
                  label="Confirm Password"
                />
                <div className="col">
                  {this.state.passwordMismatch ? (
                    <small className="text-danger">
                      Passwords doesn't match
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="mb-1">
                {this.state.errorOcurred ? (
                  <small className="text-danger">
                    {" "}
                    Error ocurred. Please login again.
                  </small>
                ) : null}
                {this.state.recent ? (
                  <small className="text-danger">
                    Sensitive information updation requires fresh login. Please
                    login again.
                  </small>
                ) : null}
              </div>
            </div>
          </Modal>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.profile.loading,
    profile_pic: state.profile.profile_pic,
    email: state.profile.email,
    mobile_number: state.profile.mobile_number,
    first_name: state.profile.first_name,
    last_name: state.profile.last_name,
    uploading: state.uploadDetails.uploading,
    uploadError: state.uploadDetails.error,
    uploadSuccess: state.uploadDetails.success,
    token: state.auth.auth_token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fileManager: (type, files, email) =>
      dispatch(fileManager(type, files, email)),
    deleteImgfromArray: (type, index) =>
      dispatch(deleteImgfromArray(type, index)),

    getProfile: (token) => dispatch(getProfile(token)),
    showProfile: (val) => dispatch(showProfileModal(val)),
    inputManager: (type, val) => dispatch(inputManager(type, val)),
    updateProfile: (token) => dispatch(updateProfile(token)),
    clearProfileData: () => dispatch(clearProfileData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import React from "react";
import { useFieldValue } from "../../reduxModules/tools/fieldValue";
export function SearchBox({ placeholder, id }) {
  const [,searchText = '', setSearchText] = useFieldValue(id);
  return (
    <div className="search-code position-relative" id={id}>
      <input
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        type="text"
        placeholder={placeholder}
        name="search"
        className="form-control lg-width global_input"
      />
      {searchText ? (
        <span
          className="global_link-text font-s pointer clear-text"
          onClick={(e) => setSearchText("")}
        >
          Clear
        </span>
      ) : null}
    </div>
  );
}
import { baseFetchQueryV3, qs } from "../../../config/api";
import { baseApi } from "../..";

export const withdrawalsAPI = baseApi.injectEndpoints({
    reducerPath: 'v3/withdrawals',
    baseQuery: baseFetchQueryV3(),
    endpoints: (builder) => ({
        getWithdrawals: builder.query({
            query: ({limit, offset,date_group_by,group_by_host, date_range, parking_ids, host_ids, status}) => {
                return {
                    url: `/transactions/withdrawals?${qs({
                        limit,
                        offset,
                        date_group_by,
                        group_by_host,
                        date_range,
                        parking_ids,
                        host_ids,
                        status
                    })}`
                }
            },
            transformResponse: response => ({ count: response.result.count, data: response.result.withdrawals }),
            providesTags: ['Withdrawals']
        }),
        getWithdrawalsAmount: builder.query({
            query: ({date_range, parking_ids, host_ids}) => {
                return {
                    url: `/transactions/withdrawals/amount?${qs({
                        date_range,
                        parking_ids,
                        host_ids,
                    })}`
                }
            },
            transformResponse: response => response.result,
            providesTags: ['Withdrawals']
        }),
        createWithdrawal: builder.mutation({
            query: ({date_range, parking_ids, host_ids, date, date_group_by}) => {
                return {
                    url: `/transactions/withdrawals/create`,
                    method: 'POST',
                    body: {
                        date_range,
                        parking_ids,
                        host_ids,
                        date,
                        date_group_by
                    }
                }
            },
            invalidatesTags: ['Withdrawals', 'Earnings']
        }),
        approveWithdrawal: builder.mutation({
            query: ({date_range, parking_ids, host_ids, date, date_group_by}) => {
                return {
                    url: `/transactions/withdrawals/approve`,
                    method: 'POST',
                    body: {
                        date_range,
                        parking_ids,
                        host_ids,
                        date,
                        date_group_by
                    }
                }
            },
            invalidatesTags: ['Withdrawals', 'Earnings']
        }),
    })
})

export const { useGetWithdrawalsQuery,
        useCreateWithdrawalMutation,
        useApproveWithdrawalMutation,
        useGetWithdrawalsAmountQuery,
     } = withdrawalsAPI;
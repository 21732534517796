import API from '../config/api';
import { GOT_PROFILE_DATA, GETTING_PROFILE, GOT_PROFILE_ERROR } from './profile';
import { GET_PARKINGS_SUCCESS, GETTING_PARKINGS, GET_PARKINGS_ERROR } from './parkings';

import firebaseAuth from "./../components/auth/firebase";


// Action types

// const BASE_URL = "https://api.parkpoolr.o.fountane.xyz/api/v1/auth";
// const BASE_URL = 'http://localhost:7852/api/v1/auth';
const AUTH_ERROR = "AUTH_ERROR";
const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
const SIGNUP_SUCCESSFUL = 'SIGNUP_SUCCESSFUL';
const AUTH_TOGGLE = "AUTH_TOGGLE";
const REMEMBER_ME = "REMEMBER_ME";
const AUTH_LOADING = "AUTH_LOADING";
const NEW_USER = 'NEW_USER';
const LAUNCH_ERROR = 'LAUNCH_ERROR';
const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
const CLOSE_ALERT = 'CLOSE_ALERT';
const USER_DOES_NOT_EXISTS = "USER_DOES_NOT_EXISTS";
const USER_EXISTS = "USER_EXISTS";
const RESET_AUTH_STATE = "RESET_AUTH_STATE";

export const newUser = (token) => {
    return {
        type: NEW_USER,
        payload: {
            token,
        },
    };
};

export const authLoading = () => {
    return {
        type: AUTH_LOADING,
    };
};


export const setLogin = (val) => {
    return {
        type: AUTH_TOGGLE,
        payload: {
            val,
        }
    };
};

export const rememberMe = (val) => {
    return {
        type: REMEMBER_ME,
        payload: {
            val,
        },
    }
};

export const launchError = (err) => {
    return {
        type: LAUNCH_ERROR,
        payload: {
            err: err,
        },
    };
};

export const launchVerificationSuccess = (bool, secondBool = false) => {
    return {
        type: VERIFICATION_SUCCESS,
        payload: {
            success: bool,
            reverification: secondBool
        },
    };
};

export const closeAlert = (bool) => {
    return {
        type: CLOSE_ALERT,
        payload: {
            val: bool
        }
    }
}


const INITIATE_OTP_AUTH = "INITIATE_OTP_AUTH";
const OTP_RECEIVED_SUCCESS = "OTP_RECEIVED_SUCCESS";
const OTP_RECEIVED_FAILED = "OTP_RECEIVED_FAILED";
const INITIATE_OTP_VERIFICATION = "INITIATE_OTP_VERIFICATION";
const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
const OTP_VERIFICATION_FAILED = "OTP_VERIFICATION_FAILED";


/// Adding new flow for the Host Signup process //

const prefixedPhone = '+';

export const firebaseSendOTP = (phone, linking = null) => {
    return async (dispatch) => {
        dispatch({
            type: INITIATE_OTP_AUTH,
        });
        let confirmation = undefined;
        try {

            let appVerifier = window.recaptchaVerifier;
            phone = `${prefixedPhone}${phone}`;

            if (linking) {
                confirmation = await firebaseAuth().currentUser.linkWithPhoneNumber(phone, appVerifier);
                // console.log(confirmation);
                // console.log('linking');
            } else {
                confirmation = await firebaseAuth().signInWithPhoneNumber(phone, appVerifier);
            }


            dispatch({
                type: OTP_RECEIVED_SUCCESS,
                payload: {
                    confirmation: confirmation,
                },
            });

        } catch (err) {
            console.log("error on OTP sent");

            console.log(err);

            dispatch({
                type: OTP_RECEIVED_FAILED,
                payload: {
                    errorMessage: err.message,
                },
            });
        }
    };
};


export const firebaseVerifyOTP = (otp) => {
    return (dispatch, getState) => {
        try {
            dispatch({
                type: INITIATE_OTP_VERIFICATION,
            });
            // console.log('OTP: ', otp);
            const { confirmation } = getState().otp;

            confirmation
                .confirm(otp)
                .then(() => {
                    dispatch({
                        type: OTP_VERIFICATION_SUCCESS,
                    });

                    return firebaseAuth().currentUser.getIdTokenResult();
                })
                .then((idToken) => {
                    // console.log('IdToken');
                    // console.log(idToken);
                })
                .catch((err) => {
                    console.log(err);

                    dispatch({
                        type: OTP_VERIFICATION_FAILED,
                        payload: {
                            errorMessage: err.message ? err.message : 'Invalid Code',
                        },
                    });
                });
        } catch (e) {
            console.log('WEIRD ERROR ON CONFIRMATION', e);
            dispatch({
                type: OTP_VERIFICATION_FAILED,
                payload: {
                    errorMessage: e.message ? e.message : 'Incorrect OTP provided.',
                },
            });
        }
    };
};






export const adminlogin = (firebaseToken) => {



    return async (dispatch) => {

        dispatch(authLoading());
        let token = null;

        try {
            let { data } = await API.post('/auth/login', { firebase_token: firebaseToken });
            dispatch({
                type: LOGIN_SUCCESSFUL,
                payload: {
                    ...data,
                },
            });
            token = data.auth_token;
        } catch (err) {
            dispatch({
                type: AUTH_ERROR,
                payload: {
                    err: err.response ? err.response.data : err,
                    errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                },
            });
        }
        if (!token) return;
        dispatch({
            type: GETTING_PROFILE,
        });
        try {
            let { data } = await API.get('/profile', {
                headers: {
                    'X-AUTH-TOKEN': token,
                },
            });
            dispatch({
                type: GOT_PROFILE_DATA,
                payload: {
                    ...data.result,
                },
            });
        } catch (err) {
            dispatch({
                type: GOT_PROFILE_ERROR,
                payload: {
                    err: err.response ? err.response.data : err,
                    errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                },
            });
        }

    };
}

// Actions

export const login = (firebaseToken) => {

    return async (dispatch, getState) => {

        dispatch(authLoading());
        var token = null;

        try {
            let { data } = await API.post('/auth/login', { firebase_token: firebaseToken });
            dispatch({
                type: LOGIN_SUCCESSFUL,
                payload: {
                    ...data,
                },
            });
            token = data.auth_token;
        } catch (err) {
            dispatch({
                type: AUTH_ERROR,
                payload: {
                    err: err.response ? err.response.data : err,
                    errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                },
            });
        }
        if (!token || typeof token !== "string") return;
        dispatch({
            type: GETTING_PROFILE,
        });
        try {
            let { data } = await API.get('/profile', {
                headers: {
                    'X-AUTH-TOKEN': token,
                },
            });
            let auth_code = getState().auth;
            // console.log("rememberMe");
            // console.log(auth_code);

            dispatch({
                type: GOT_PROFILE_DATA,
                payload: {
                    ...data.result,
                },
                rememberMe: auth_code.rememberMe
            });
        } catch (err) {
            dispatch({
                type: GOT_PROFILE_ERROR,
                payload: {
                    err: err.response ? err.response.data : err,
                    errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                },
            });
        }


    };
}

export const checkUserExistsOrNot = async (email, phone) => {
    try {


        if (!email) {
            console.log("Email  is required!")
            return;
        }


        phone = `${prefixedPhone}${phone}`;

        let payload = { email, phone };
        let { data } = await API.post('/auth/checkUser', payload);

        console.log("Chcking user in action creator");
        console.log(data);

        if (data && data.user_exists) {
            console.log("User already exits!");
            return data;
        }

        return false;
    } catch (error) {
        console.log(error);
        return false
    }
}


export const signUp = (first_name, last_name, email, phone, legal, firebaseToken, dontRefresh = false) => {

    return async (dispatch, getState) => {
        dispatch(authLoading());

        if (!email) {
            console.log("Email is required!")
            return;
        }

        phone = `${prefixedPhone}${phone}`;

        let token = null;

        const options = {
            firebase_token: firebaseToken,
            first_name,
            last_name,
            email,
            phone,
            legal: legal
        };

        try {
            let { data } = await API.post('/auth/signup', options);

            dispatch({
                type: SIGNUP_SUCCESSFUL,
                payload: {
                    ...data,
                },
            });
            token = data.auth_token;


            await dispatch(login(firebaseToken));


            window.localStorage.setItem("AUTH_TOKEN", token);


            if (!dontRefresh) {
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            }


        } catch (err) {
            dispatch({
                type: AUTH_ERROR,
                payload: {
                    err: err.response ? err.response.data : err,
                    errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                },
            });
        }
        if (!token) return;
        dispatch({
            type: GETTING_PARKINGS,
        });
        try {
            let result = await API.get('/parkings', {
                headers: {
                    'X-AUTH-TOKEN': token,
                },
            });

            dispatch({
                type: GET_PARKINGS_SUCCESS,
                payload: {
                    parkings: {
                        ...result.data,
                    },

                },
            });

        } catch (err) {
            dispatch({
                type: GET_PARKINGS_ERROR,
                payload: {
                    err: err.response ? err.response.data : err,
                    errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                },
            });
        }
        dispatch({
            type: GETTING_PROFILE,
        });
        try {
            let { data } = await API.get('/profile', {
                headers: {
                    'X-AUTH-TOKEN': token,
                },
            });
            dispatch({
                type: GOT_PROFILE_DATA,
                payload: {
                    ...data.result,
                },
            });
        } catch (err) {
            dispatch({
                type: GOT_PROFILE_ERROR,
                payload: {
                    err: err.response ? err.response.data : err,
                    errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                },
            });
        }


    };
}



export const checkUserExists = (email, phone) => {

    return async dispatch => {

        dispatch({
            type: "CHECKING_USER_EXISTS",
        });

        try {

            let options = { email, phone };


            let { data } = await API.post('/auth/checkUser', options);

            if (!data.user_exists) {
                dispatch({
                    type: USER_DOES_NOT_EXISTS,
                    payload: {
                        user_exists: false
                    },
                });

            } else {
                dispatch({
                    type: USER_EXISTS,
                    payload: {
                        user_exists: true
                    },
                });
            }




        } catch (error) {
            console.log(error);

        }
    }

}



// Reducers




export const resetAuthState = () => {
    return async (dispatch) => {
        dispatch({
            type: RESET_AUTH_STATE,
        });
    };
};




const signed_jwt = localStorage.getItem('AUTH_TOKEN') || sessionStorage.getItem('AUTH_TOKEN');
const remember = localStorage.getItem('AUTH_TOKEN') ? true : false;
const initState = {
    loggedIn: signed_jwt ? true : false,
    rememberMe: remember,
    error: null,
    auth_token: signed_jwt ? signed_jwt : null,
    loading: false,
    user_exists: false,
    signup_proccess_running: false,
    new_user: false,
    verificationSuccess: false,
    reverification: false,
    alertClosed: false
};
export default (state = initState, action) => {
    switch (action.type) {
        case NEW_USER:
            return {
                ...state,
                new_user: action.payload.token,
                verificationSuccess: false,
            };
        case AUTH_LOADING:
            return {
                ...state,
                loading: true,
                verificationSuccess: false,
            };
        case LOGIN_SUCCESSFUL:
            if (action.payload) {
                let response = action.payload;

                if (state.rememberMe) {
                    window.localStorage.setItem('AUTH_TOKEN', response.auth_token);
                }
                else {
                    window.sessionStorage.setItem('AUTH_TOKEN', response.auth_token);
                }
                return {
                    ...state,
                    loggedIn: true,
                    loading: false,
                    verificationSuccess: false,
                    ...response
                };
            }
            break;
        case SIGNUP_SUCCESSFUL:
            localStorage.setItem('AUTH_TOKEN', action.payload.auth_token);
            sessionStorage.setItem('AUTH_TOKEN', action.payload.auth_token);
            return {
                ...state,
                loggedIn: true,
                loading: false,
                verificationSuccess: false,
                ...action.payload.response,
            };
        case AUTH_ERROR:
            let response = {
                ...state,
                error: action.payload.errorMessage,
            }
            console.log("action: ", action);
            return {
                ...response,
                loading: false,
                loggedIn: false,
                verificationSuccess: false,
                alertClosed: false
            };
        case AUTH_TOGGLE:
            return {
                ...state,
                loggedIn: action.payload.val,
                loading: false
            };
        case REMEMBER_ME:
            return {
                ...state,
                rememberMe: action.payload.val,
            };
        case LAUNCH_ERROR:
            return {
                ...state,
                error: action.payload.err,
                alertClosed: false
            };
        case VERIFICATION_SUCCESS:
            return {
                ...state,
                verificationSuccess: action.payload.success,
                reverification: action.payload.reverification
            };
        case CLOSE_ALERT:
            return {
                ...state,
                alertClosed: action.payload.val
            }

        case "CHECKING_USER_EXISTS":
            return {
                ...state,
                user_exists: false,
                loading: true,
            };

        case USER_DOES_NOT_EXISTS:
            return {
                ...state,
                loggedIn: false,
                loading: false,
                verificationSuccess: false,
                user_exists: action.payload.user_exists,
                signup_proccess_running: true
            }

        case USER_EXISTS:
            return {
                ...state,
                loggedIn: false,
                loading: false,
                verificationSuccess: false,
                user_exists: action.payload.user_exists,
                signup_proccess_running: true
            }

        case RESET_AUTH_STATE:
            return {
                ...state,
                user_exists: false,
                signup_proccess_running: false
            }


        default:
            return state;
    }

}



const otpInitState = {
    fetchingOTP: false,
    errorFetching: null,
    otp_received: false,
    otp_received_error: null,
    otp_verifying: false,
    otp_verified: false,
    otp_verification_error: null,
    confirmation: null,
};
export function otpReducer(state = otpInitState, action) {
    switch (action.type) {
        case INITIATE_OTP_AUTH:
            return {
                ...otpInitState,
                fetchingOTP: true,
                errorFetching: null,
            };
        case OTP_RECEIVED_SUCCESS:
            return {
                ...state,
                fetchingOTP: false,
                otp_received: true,
                otp_verifying: false,
                errorFetching: null,
                confirmation: action.payload.confirmation,
            };
        case OTP_RECEIVED_FAILED:
            return {
                ...state,
                fetchingOTP: false,
                otp_received: false,
                errorFetching: null,
                otp_verifying: false,
                otp_received_error: action.payload.errorMessage,
            };
        case INITIATE_OTP_VERIFICATION:
            return {
                ...state,
                fetchingOTP: false,
                otp_verifying: true,
            };
        case OTP_VERIFICATION_SUCCESS:
            return {
                ...state,
                otp_verified: true,
                otp_verification_error: null,
                otp_verifying: false,
            };
        case OTP_VERIFICATION_FAILED:
            return {
                ...state,
                otp_verifying: false,
                otp_verified: false,
                otp_verification_error: action.payload.errorMessage,
            };
        default:
            return state;
    }
}

import { firebaseStorage } from '../components/auth/firebase';


export default function fileManager(type, files, email, img_arr = null, num = null) {

    return dispatch => {
        dispatch({
            type,
            payload: {
                files,
            },
        });
        let decider;
       
        switch (type) {
            case 'UPDATE_SPOT_IMAGES':
                if (img_arr && img_arr.length) {
                    // decider = 'spot_image/' + String(img_arr.length);
                    if (num) {
                        decider = 'spot_image/' + String(num) + '/' + String(img_arr.length);
                    }
                    else {
                        // new image in first parking itself
                        decider = 'spot_image/' + String(img_arr.length);
                    }
                }
                else {
                    decider = 'spot_image' + String(new Date().getTime());
                }
                break;
            case 'ACTION_PROFILE_IMAGE':
                decider = 'profile_image';
                break;
            case 'EVENT_UPLOAD_FILE':
                decider = 'event_image/' + String(new Date().getTime());
                break;
            case 'RECEIPT':
                decider = 'receipt/' + String(new Date().getTime());
            default:
                decider = 'image' + String(new Date().getTime());
                break;
        }

        if (email == undefined) {
            // email is null or undefined
            email = localStorage.getItem('userEmail');
            if (!email) {
                // if still no email
                dispatch({
                    type: type + '_UPLOAD_ERROR',
                    payload: {
                        errorMessage: 'E-mail error, please login again.'
                    },
                });

                return;
            }
        }

        let email_ = email.indexOf('@');
        email = email.slice(0, email_);
        const fileName = decider;
        // console.log(fileName);

        const uploadTask = firebaseStorage().ref().child('images/' + email + '/' + fileName).put(files[0]);

        uploadTask
            .on(firebaseStorage.TaskEvent.STATE_CHANGED, (snapshot) => {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                switch (snapshot.state) {
                    case firebaseStorage.TaskState.PAUSED:
                        dispatch({
                            type: type + '_UPLOAD_PAUSED',
                            payload: {
                                progress,
                            },
                        });
                        break;
                    case firebaseStorage.TaskState.RUNNING:
                        dispatch({
                            type: type + '_UPLOAD_RUNNING',
                            payload: {
                                progress,
                            },
                        });
                        break;
                    default:
                        break;

                }
            }, (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        dispatch({
                            type: type + '_UPLOAD_ERROR',
                            payload: {
                                errorMessage: 'Unauthorized',
                            },
                        });
                        break;
                    case 'storage/cancelled':
                        dispatch({
                            type: type + '_UPLOAD_ERROR',
                            payload: {
                                errorMessage: 'Cancelled',
                            },
                        });
                        break;
                    default:
                        dispatch({
                            type: type + '_UPLOAD_ERROR',
                            payload: {
                                errorMessage: 'Unknown Error on File Upload',
                            },
                        });
                        break;
                }
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL()
                    .then((url) => {
                        dispatch({
                            type: type + '_UPLOAD_SUCCESS',
                            payload: {
                                url,
                            },
                        });
                    });
            });
    };


}
export function fileManagerAdmin(type, files, email, id) {

    return dispatch => {
        dispatch({
            type,
            payload: { files },
        });
        let decider;

        // console.log("What is the type");
        // console.log(type);


        if (type === "RECEIPT") {
            decider = 'receipt/' + String(new Date().getTime());
        } else {
            decider = 'image' + String(new Date().getTime());;
        }

        if (email === undefined) {
            // email is null or undefined
            email = window.localStorage.getItem('userEmail');
            if (!email) {
                // if still no email
                dispatch({
                    type: type + '_UPLOAD_ERROR',
                    payload: {
                        errorMessage: 'E-mail error, please login again.'
                    },
                });

                return;
            }
        }

        let email_ = email.indexOf('@');
        email = email.slice(0, email_);
        const fileName = decider;

        const uploadTask = firebaseStorage().ref().child('images/' + email + '/' + fileName).put(files[0]);

        uploadTask
            .on(firebaseStorage.TaskEvent.STATE_CHANGED, (snapshot) => {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                switch (snapshot.state) {
                    case firebaseStorage.TaskState.PAUSED:
                        dispatch({
                            type: type + '_UPLOAD_PAUSED',
                            payload: {
                                progress,
                            },
                        });
                        break;
                    case firebaseStorage.TaskState.RUNNING:
                        dispatch({
                            type: type + '_UPLOAD_RUNNING',
                            payload: {
                                progress,
                            },
                        });
                        break;
                    default:
                        break;

                }
            }, (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        dispatch({
                            type: type + '_UPLOAD_ERROR',
                            payload: {
                                errorMessage: 'Unauthorized',
                            },
                        });
                        break;
                    case 'storage/cancelled':
                        dispatch({
                            type: type + '_UPLOAD_ERROR',
                            payload: {
                                errorMessage: 'Cancelled',
                            },
                        });
                        break;
                    default:
                        dispatch({
                            type: type + '_UPLOAD_ERROR',
                            payload: {
                                errorMessage: 'Unknown Error on File Upload',
                            },
                        });
                        break;
                }
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL()
                    .then((url) => {
                        dispatch({
                            type: type + '_UPLOAD_SUCCESS',
                            payload: {
                                id,
                                url,
                            },
                        });
                    });
            });
    };


}


const deleteImgfromArray = (type, index, image_url = null) => {

    return dispatch => {
        dispatch({
            type: type + "_REMOVE_IMAGE",
            payload: {
                index,
                image_url
            },
        })

    }
}

export { deleteImgfromArray }


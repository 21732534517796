import api from "../../config/api";
const GET_OVERVIEW_SUCCESS = 'GET_OVERVIEW_SUCCESS';
const GET_OVERVIEW_LOADING = 'GET_OVERVIEW_LOADING';
const GET_OVERVIEW_ERROR = 'GET_OVERVIEW_ERROR';
const defaultState = {
    loading: false,
    error: null,
    data: {
        bookings: 0,
        totalTransactions: 0,
        grossRevenue: 0,
        netHostRevenue: 0,
        platformFees: 0,
        cancelledTransactionsCount: 0,
        cancelledTransactionsAmount: 0,
        averageTransactionAmount: 0
    }
  }
export default function OverviewReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        data: action.payload
      };
    case GET_OVERVIEW_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_OVERVIEW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
export const getHostOverview = (parkings,dateFilter) => async (dispatch,getState) => {
  const url = `/dashboard/host/counts`;
  
  const token = getState().auth.auth_token;
  try {
      const params = {}
      if (parkings && parkings.length > 0) {
          params.parkings = parkings.join(',');
      }
      if (dateFilter && dateFilter.length > 0) {
          params.date_range = dateFilter.map(date => new Date(date).getTime()).join(',')
      }

      dispatch({ type: GET_OVERVIEW_LOADING });
      let response = await api.get(url, {
          headers: {
              "X-AUTH-TOKEN": token,
          },
          params,
      });
      const data = response.data;
      dispatch({ type: GET_OVERVIEW_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: GET_OVERVIEW_ERROR, payload: error?.response?.data?.error?.info });
  }
}
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import eye from "../../../Assets/imgs/eye.svg";
import config from "../../../config/config";
import NAVIGATION_URLS from "../../../config/navigationUrls";
import {
  checkUserExists,
  firebaseSendOTP,
  firebaseVerifyOTP, launchError,
  launchVerificationSuccess, resetAuthState
} from "../../../reduxModules/auth";
import firebaseAuth, { firebase } from "../firebase";
import SocialButtons from "../socialButtons";


const prefixedPhone = '+91';

const _navigationUrls = NAVIGATION_URLS;


const Signup = (props) => {


  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("1");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [recaptcha, setrecaptcha] = useState(null)
  const [OTPNUMBER, setOTP] = useState("");
  const [validatedEmail, setValidateEmail] = useState(false);

  useEffect(() => {
    props.launchError(null);
  }, []);


  useEffect(() => {
    if (recaptcha) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptcha, {
        'size': 'invisible',
        'callback': function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("recaptcha solved");
          // ...
        },
        'expired-callback': function () {
          console.log("Expired Session!");
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        }
      });
      window.recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
      });
    }
  }, [recaptcha])



  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);


  useEffect(() => {
    if (props.user_exists) {
      props.launchError("Email already exists.");
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      props.resetAuthState();
    }
  }, [props.user_exists, props.signup_proccess_running]);


  const validate = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    let validated = expression.test(String(email).toLowerCase());
    setValidateEmail(validated);
    return validated;
  }

  const beforeOtpCheckUserExistOrNot = async () => {

    // no need here //
    // Dont delete keep this //
    // if (email && mobile) {
    //   let userExits = await checkUserExistsOrNot(email, mobile);
    //   console.log("userExits");
    //   console.log(userExits);
    //   if (userExits) {
    //     props.launchError(userExits.message);
    //     setLoading(false);
    //     window.scrollTo({
    //       top: 0,
    //       left: 0,
    //       behavior: 'smooth'
    //     })
    //     props.resetAuthState();
    //     return;
    //   }
    // }

    props.firebaseSendOTP(mobile);
  }

  const doFirebaseSignUp = async () => {

    setLoading(true);
    props.launchError(null);

    // Vishnu's new code has been started here //

    if (!firebaseAuth().currentUser) {
      console.log("Dont have current user");
      setLoading(false);
      props.launchError(null);
      return;
    }

    if (!email) {
      console.log("Plz fill Email");
    }

    // let userExits = await checkUserExistsOrNot(email, mobile);
    // console.log("userExits");
    // console.log(userExits);
    // if (userExits) {
    //   props.launchError(userExits.message);
    //   setLoading(false);
    //    window.scrollTo({
    //       top: 0,
    //       left: 0,
    //       behavior: 'smooth'
    //     })
    //   props.resetAuthState();
    //   return;
    // }


    try {

      let updateEmail = await firebaseAuth().currentUser.updateEmail(email);
      // console.log("updateEmail");
      // console.log(updateEmail);


      let updatePassword = await firebaseAuth().currentUser.updatePassword(password)
      // console.log("updatePassword");
      // console.log(updatePassword);


      let idToken = await firebaseAuth().currentUser.getIdToken();
      // console.log("idToken");
      // console.log(idToken);

      const actionCodeSettings = {
        url: config.firebase.emailVerifyActionGenerator(firstName, lastName, mobile, email, idToken),
        handleCodeInApp: true,
        continueUrl: config.firebase.continueURL
      };


      let sendVerification = await firebaseAuth().currentUser.sendEmailVerification(actionCodeSettings);
      // console.log("sendVerification");
      // console.log(sendVerification);


      props.resetAuthState();

      setLoading(false);

      setTimeout(() => {
        window.location.href = "/signup-success";
      }, 200);


    } catch (error) {

      console.log("Error");
      console.log(error);
      setLoading(false);


      let errorMessage;
      switch (error.code) {
        case "auth/invalid-email":
          errorMessage = "Email provided is invalid.";
          break;
        case "auth/user-disabled":
          errorMessage = "Your account has been disabled";
          break;
        case "auth/user-not-found":
          errorMessage = "No account found for the provided email address.";
          break;
        case "auth/wrong-password":
          errorMessage = "User with this email already exists, Password is invalid.";
          break;
        case "auth/weak-password":
          errorMessage = "Password should be min 6 characters.";
          break;
        case "auth/requires-recent-login":
          errorMessage = "Updating password is failed, please try again.";
          break;
        case "auth/email-already-in-use":
          errorMessage = "User Already Exists, Please Log In";
          break;
        default:
          errorMessage = error.message;
          break;
      }

      props.launchError(errorMessage);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });


    }

  };

  if (props.redirectToSocialSignUp && window.location.pathname !== "/social-signup") {
    console.log("redirecting");
    return <Redirect to="/social-signup" />;
  }

  return (
    <React.Fragment>
      <div ref={(ref) => setrecaptcha(ref)}></div>

      <p className="font-l primary-text-color semi-bold">Create an Account</p>
      <SocialButtons
        setLoading={setLoading}
        loginWithGoogle={props.loginWithGoogle}
        doFaceBookLogin={props.doFaceBookLogin}
      />

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="fname">First Name</label>
            <input
              id="fname"
              className="form-control global_input"
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </div>
        </div>
        <div className="second_name-block col">
          <div className="form-group">
            <label htmlFor="lname_label">Last Name</label>
            <input
              id="lname_label"
              className="form-control global_input"
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="email-sign">E-mail</label>
        <input
          type="email"
          id="email-sign"
          className="form-control global_input"
          name="emailform"
          value={email}
          onChange={(e) => { validate(e.target.value); setEmail(e.target.value) }}
          pattern="^[+]1[0-9]{10}$"
        />
        <small className="text-muted">
          We'll never share your email with anyone.
        </small>
      </div>
      <div className="form-group position-relative mt-4">

        <label htmlFor="mobile-sign">Mobile Number</label>
        <span className="manuallyAdd">+</span>
        <input
          id="mobile-sign"
          type="number"
          minLength="11"
          maxLength="13"
          className="form-control global_input"
          onChange={(e) => setMobile(e.target.value)}
          value={mobile}
          disabled={props.otp_verified}
        />

        {mobile.length < 13 && mobile.length > 10 && !props.otp_verified ? (
          <span className="global_link-text verifylinkposition pointer cursor-pointer" onClick={() => { beforeOtpCheckUserExistOrNot() }}>Verify</span>) : null}

        {mobile.length > 12 ? (
          <p className="font-s text-danger">Please Enter valid mobile number</p>
        ) : null}

        {
          props.fetchingOTP ?
            <small className="text-muted">
              Please wait..
            </small> : null
        }


        {
          props.otp_received_error ? (
            <p className="font-s text-danger">{props.otp_received_error}</p>
          ) : null
        }



      </div>
      {
        !props.fetchingOTP && props.otp_received && !props.otp_verified ?

          <div className="form-group mt-4 pos_rel ">
            <label htmlFor="otp-sign">Temporary Password</label>
            <input
              id="otp-sign"
              type="number"
              minLength="6"
              maxLength="8"
              className="form-control global_input"
              onChange={(e) => {
                setOTP(e.target.value)
              }}
              value={OTPNUMBER}
            />

            {OTPNUMBER.length < 8 && OTPNUMBER.length > 5 ? (
              <span className="global_link-text verifylinkposition submitLink pointer cursor-pointer" onClick={() => { props.firebaseVerifyOTP(OTPNUMBER) }}>Submit</span>) : null}

            <small className="text-muted">
              Please enter the Pin sent on your mobile no.
            </small>

            {
              props.otp_verification_error ? <p className="font-s text-danger"> {props.otp_verification_error}</p> : null
            }
            {
              props.otp_verifying ?
                <p>
                  <small className="text-muted">
                    Verifying Pin...
                  </small>
                </p> : null
            }
          </div>
          : null
      }

      <div className="form-group mt-4">
        <div className="row">
          <div className="col">
            <label htmlFor="password-sign">Password</label>
          </div>
        </div>
        <input
          id="password-sign"
          type={showPass ? "text" : "password"}
          className=" global_input form-control"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <span className="all-seeing-eye">
          <img src={eye} alt="EYE" onClick={() => setShowPass(!showPass)} />
        </span>
        <small className="text-muted">Set Password (min 6 characters)</small>
      </div>
      <button
        disabled={
          firstName === "" ||
          lastName === "" ||
          !email ||
          !mobile ||
          mobile.length < 10 ||
          mobile.length > 12 ||
          !password ||
          password.length < 6 ||
          loading ||
          !validatedEmail ||
          !props.otp_verified
        }
        className="btn global_btn btn-block"
        type="button"
        name="button"
        onClick={() => {
          doFirebaseSignUp()
        }}
      >
        Sign Up
      </button>
      <div className="text-center m-t-1">
        <small className="text-muted">
          By clicking the 'Sign Up' button, you confirm that you accept our{" "}
          <br />
          <a href={_navigationUrls.termsConditions} target="_blank">
            {" "}
            Terms of Use
          </a>
          &nbsp; and
          <a href={_navigationUrls.privacyPolicy} target="_blank">
            {" "}
            Privacy Policy
          </a>
        </small>
      </div>
      <div>
        <hr />
      </div>
      <div className="text-center footer-text">
        <p>
          Have an account?&nbsp;
          <Link to="/login" className="semi-bold">
            Log In
          </Link>
        </p>
      </div>
      <div className="row mt-2">
        <div className="col">
          {loading ? (
            <div className="container text-center">
              <div className="spinner-border text-danger"></div>
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const { loading, user_exists, signup_proccess_running } = state.auth;
  const { fetchingOTP,
    errorFetching,
    otp_received,
    otp_received_error,
    otp_verifying,
    otp_verification_error,
    otp_verified, } = state.otp;
  return {
    loading,
    user_exists,
    signup_proccess_running,
    fetchingOTP,
    errorFetching,
    otp_received,
    otp_received_error,
    otp_verification_error,
    otp_verifying,
    otp_verified,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkUserExists: (email, phone) => dispatch(checkUserExists(email, phone)),
    launchError: (err) => dispatch(launchError(err)),
    launchVerificationSuccess: (bool) =>
      dispatch(launchVerificationSuccess(bool)),
    firebaseSendOTP: (phone) => dispatch(firebaseSendOTP(phone)),
    firebaseVerifyOTP: (otp) => dispatch(firebaseVerifyOTP(otp)),
    resetAuthState: () => dispatch(resetAuthState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

import getEvents, {EVENT_UPLOAD_FILE} from "./index";
import api from "../../config/api";



const CLEAR_EVENT_FIELDS = 'CLEAR_EVENT_FIELDS';
const EVENT_EDIT_AUTO_FULL = 'EVENT_EDIT_AUTO_FULL';

const EVENT_EDIT_SET_EVENT_NAME = 'SET_EVENT_NAME';
const EVENT_EDIT_SET_EVENT_TYPE = 'SET_EVENT_TYPE';
const EVENT_EDIT_SET_EVENT_ADDRESS_STREET = "SET_EVENT_LOCATION_STREET";
const EVENT_EDIT_SET_EVENT_ADDRESS_CITY = 'SET_EVENT_LOCATION_CITY';
const EVENT_EDIT_SET_EVENT_ADDRESS_STATE = 'SET_EVENT_LOCATION_STATE';
const EVENT_EDIT_SET_EVENT_ADDRESS_COUNTRY = 'SET_EVENT_LOCATION_COUNTRY';
const EVENT_EDIT_SET_EVENT_ADDRESS_PINCODE = 'SET_EVENT_LOCATION_PINCODE';
const EVENT_EDIT_SET_EVENT_VENUE = 'SET_EVENT_VENUE';
const EVENT_EDIT_SET_EVENT_TIME_IN = 'SET_EVENT_TIME_IN';
const EVENT_EDIT_SET_EVENT_TIME_OUT = 'SET_EVENT_TIME_OUT';

const EVENT_EDIT_ADD_EVENT_IMG = 'ADD_EVENT_IMG';
const EVENT_EDIT_REMOVE_EVENT_IMG = 'EVENT_REMOVE_IMAGE';


const getEventTemplate = () => {
    return {
        event_name: null,
        event_category: null,
        street: null,
        city: null,
        state: null,
        country: null,
        pincode: null,
        venue_name: null,
        venueId: null,
        venue_id: null,
        event_start_time: new Date().toISOString(),
        event_end_time: new Date(Date.now() + 1000 * 60 * 60).toISOString(),
        imgs: [],
        media: [],
        lat: null,
        lng: null,
        address_id: null,
        address_name: null,
        login_id: null,
        loginId: null,
        id: null,
    }
}

export const currentlyEditingEventReducer = (state = getEventTemplate(), action) => {
    console.log("REDUCER", action, state);
    switch (action.type) {
        case EVENT_EDIT_AUTO_FULL:

            return {
                ...state,
                ...action.payload.value,
                imgs: action.payload.value.imgs ? action.payload.value.imgs.map(i => i.path) : [],
            }
        case EVENT_EDIT_SET_EVENT_NAME:
            return {
                ...state,
                event_name: action.payload.value
            }
        case EVENT_EDIT_SET_EVENT_TYPE:
            return {
                ...state,
                event_category: action.payload.value
            }
        case EVENT_EDIT_SET_EVENT_ADDRESS_STREET:
            return {
                ...state,
                street: action.payload.value
            }
        case EVENT_EDIT_SET_EVENT_ADDRESS_CITY:
            return {
                ...state,
                city: action.payload.value
            }
        case EVENT_EDIT_SET_EVENT_ADDRESS_STATE:
            return {
                ...state,
                state: action.payload.value
            }
        case EVENT_EDIT_SET_EVENT_ADDRESS_COUNTRY:
            return {
                ...state,
                country: action.payload.value
            }
        case EVENT_EDIT_SET_EVENT_ADDRESS_PINCODE:
            return {
                ...state,
                ...state,
                pincode: action.payload.value
            }
        case EVENT_EDIT_SET_EVENT_VENUE:
            return {
                ...state,
                venue_name: action.payload.value
            }
        case EVENT_EDIT_SET_EVENT_TIME_IN:
            return {
                ...state,
                event_start_time: action.payload.value
            }
        case EVENT_EDIT_SET_EVENT_TIME_OUT:
            return {
                ...state,
                event_end_time: action.payload.value
            }
        case EVENT_EDIT_ADD_EVENT_IMG:
            return {
                ...state,
                imgs: [...state.imgs, action.payload.value]
            }
        case EVENT_EDIT_REMOVE_EVENT_IMG:
            return {
                ...state,
                imgs: state.imgs.filter((img, index) => index !== action.payload.index && img !== action.payload.image_url)
            }
        case EVENT_UPLOAD_FILE + '_UPLOAD_SUCCESS':
            return {
                ...state,
                imgs: [...state.imgs, action.payload.url]
            }
        case CLEAR_EVENT_FIELDS:
            return getEventTemplate();
        default:
            return state;
    }
}


const defaultEventEditorStatus = () => ({
    loading: false,
    error: false,
    errorMessage: '',
    success: false,
    fileUpload: {
        uploading: false,
        progress: 0,
        error: false,
        errorMessage: '',
    }
})
const EVENT_EDTITOR_LOADING = 'EVENT_EDTITOR_LOADING';
const EVENT_EDTITOR_ERROR = 'EVENT_EDTITOR_ERROR';
const EVENT_EDTITOR_SUCCESS = 'EVENT_EDTITOR_SUCCESS';
export const eventEditorStatusEventReducer = (state = defaultEventEditorStatus(), action) => {
    switch (action.type) {
        case EVENT_EDTITOR_LOADING:
            return {
                ...state,
                loading: true,
            }
        case EVENT_EDTITOR_ERROR:

            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload.value
            }
        case EVENT_EDTITOR_SUCCESS:

            return {
                ...defaultEventEditorStatus(),
                success: true,
            };
        case CLEAR_EVENT_FIELDS:
            return defaultEventEditorStatus();
        case EVENT_UPLOAD_FILE + '_UPLOAD_RUNNING':
            return {
                ...state,
                fileUpload: {
                    uploading: true,
                    progress: action.payload.progress,
                }
            }
        case EVENT_UPLOAD_FILE + '_UPLOAD_SUCCESS':
            return {
                ...state,
                fileUpload: {
                    uploading: false,
                    progress: 100,
                }
            }
        case EVENT_UPLOAD_FILE + '_UPLOAD_ERROR':
            return {
                ...state,
                fileUpload: {
                    uploading: false,
                    error: true,
                    errorMessage: action.payload.errorMessage,
                }
            }
        default :
            return state
    }
}


function eventToPayload(event) {
    return {
        data: {
            addressData: {
                street: event.street,
                city: event.city,
                state: event.state,
                country: event.country,
                pincode: event.pincode,
                address_id: event.address_id
            },
            eventData: {
                id: event.id,
                name: event.event_name,
                event_category: event.event_category,
                event_start_time: event.event_start_time,
                event_end_time: event.event_end_time,
                event_imgs: event.imgs,
            },
            venueData: {
                name: event.venue_name,
                venue_id: event.venue_id

            }
        }
    }
}

export function saveEvent(token) {
    return (dispatch, getState) => {

        const event = getState().events_v2.eventEditor.current
        const payload = eventToPayload(event);
        dispatch({type: EVENT_EDTITOR_LOADING});
        let updateRequest;
        if (event.id) {
            updateRequest = api.put('/events', payload, {
                headers: {
                    'X-AUTH-TOKEN': token,
                },
            })
        } else {
            updateRequest = api.post('/events', payload, {
                headers: {
                    'X-AUTH-TOKEN': token,
                },
            })
        }
        return updateRequest
            .then(({data}) => {
                dispatch({type: EVENT_EDTITOR_SUCCESS});
                dispatch(getEvents(token));
            })
            .catch((err) => {
                console.log("ERROR RECEIVING EVENTS", err.response);
                const error = err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error";
                dispatch({type: EVENT_EDTITOR_ERROR, payload: {value: error}});
            });
    }
}

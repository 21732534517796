import { baseFetchQueryV3, qs } from "../../../config/api";
import { baseApi } from "../..";

export const enforcementsApi = baseApi.injectEndpoints({
    reducerPath: 'v3/integrations/enforcements',
    baseQuery: baseFetchQueryV3(),
    endpoints: (builder) => ({
        getAllEnforcements: builder.query({
            query: ({ pageNumber, pageSize, search ,dateRange, parkingIds, status}) => ({
                url: `/integrations/enforcements?${qs({
                    limit: pageSize,
                    offset: (pageNumber - 1) * pageSize,
                    search: search ? search : undefined,
                    parking_ids: parkingIds && parkingIds.length ? parkingIds : undefined,
                    date_range: dateRange && dateRange.length ? dateRange : undefined,
                    status: status && status.length ? status : undefined
                })}`,
            }),
            transformResponse: response => response.result.enforcements,
            providesTags: (result, error, arg) => {
                return [{ type: 'Enforcements', id: 'LIST' }]
            }
        }),
        getCountEnforcements: builder.query({
            query: ({ search ,dateRange, parkingIds, status}) => ({
                url: `/integrations/enforcements/count?${qs({
                    search: search ? search : undefined,
                    parking_ids: parkingIds && parkingIds.length ? parkingIds : undefined,
                    date_range: dateRange && dateRange.length ? dateRange : undefined,
                    status: status && status.length ? status : undefined
                })}`,
            }),
            transformResponse: response => response.result
        }),
    })
})
export const { useGetAllEnforcementsQuery, useGetCountEnforcementsQuery } = enforcementsApi;
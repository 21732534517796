import API from "../config/api";

const USERS_FETCH_STARTED = "USERS_FETCH_STARTED";
const USERS_RECEIVED_SUCCESS = "USERS_RECEIVED_SUCCESS";
const USERS_RECEIVED_FAILED = "USERS_RECEIVED_FAILED";

// async actions
export const getUsers = (token, userId = null, pageNumber = null, itemsPerPage = null, searchTerm = null) => {

    return dispatch => {
        dispatch({
            type: USERS_FETCH_STARTED
        });

        const queryParams = {};

        if (pageNumber !== undefined && pageNumber !== null && itemsPerPage) {
            queryParams.offset = (pageNumber - 1) * itemsPerPage;
        }
        if (itemsPerPage) {
            queryParams.limit = itemsPerPage;
        }

        if (searchTerm) {
            queryParams.search_term = searchTerm;
        }
        if (userId) {
            queryParams.id = userId;
        }


        const uri = `/profile/users/paged`;

        // let searchParams = new URLSearchParams();
        // searchParams.append("search_term", searchTerm);
        // console.log({searchParams});
        // console.log({searchTerm});

        API.get(uri, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
            params: queryParams
        })
            .then(({ data }) => {
                dispatch({
                    type: USERS_RECEIVED_SUCCESS,
                    payload: {
                        users: data.result.userList,
                        count: data.result.count
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: USERS_RECEIVED_FAILED,
                    payload: {
                        errorMessage: err.message,
                    },
                });
            });
    };
};

const userInitState = {
    loading: false,
    error: null,
    total_users: 0,
    users: {}
};
export default function userReducer(state = userInitState, action) {
    switch (action.type) {

        case USERS_FETCH_STARTED:
            return {
                ...state,
                loading: true,
            };

        case USERS_RECEIVED_SUCCESS:
            const users = action.payload.users;
            let dataHolder = {};
            users.forEach(item => { dataHolder[item.id] = item });
            let count = action.payload.count;
            return {
                ...state,
                error: null,
                loading: false,
                users: dataHolder,
                total_users: count,
            };

        case USERS_RECEIVED_FAILED:
            return userInitState;

        default:
            return state;
    }
}

/*
**********************************************************************
**********************************************************************
**********************************************************************
**********************************************************************
**********************************************************************

MONTHLY, YEARLY, DAILY USER DATA FROM BACKEND COMES AFTER THIS COMMENT

**********************************************************************
**********************************************************************
**********************************************************************
**********************************************************************
**********************************************************************
**********************************************************************
*/

import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { signUp, launchError, launchVerificationSuccess ,   
    checkUserExists,
    firebaseSendOTP,
    firebaseVerifyOTP,
    resetAuthState } from '../../../reduxModules/auth';

import firebaseAuth  ,{ firebase } from '../firebase';
import config from "../../../config/config";

const GoogleSignup = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('1');
    const [loading, setLoading] = useState(false);
    const [recaptcha, setrecaptcha] = useState(null)
    const [OTPNUMBER, setOTP] = useState("");
  

    ///// For ASWIN ////
    //// for split the DisplayName as Firstname and lastName use this function/////
    ///////
    const splitFirstNameLastName = (fullName) => {
        let splitted = fullName.split(' ');

        let obj = {
            lastName: splitted[splitted.length - 1],
            firstName: ""
        }

        for (let index in splitted) {
            if (index != (splitted.length - 1)) {
                obj.firstName += splitted[index] +" "
            }
        }

        return obj;
    }

    useEffect(() => {
        if (props.currentUser && props.currentUser.email) {
            // console.log("Current User");
            // console.log(props.currentUser);
            if (props.currentUser.displayName) {
                let obj = splitFirstNameLastName(props.currentUser.displayName);
                setFirstName(obj.firstName)
                setLastName(obj.lastName);
            }
            if (props.currentUser.phoneNumber) {
                setMobile(props.currentUser.phoneNumber)
            }
            setEmail(props.currentUser.email);
        }
    }, [props.currentUser])

    useEffect(() => {
        props.launchError(null);
    }, []);


    useEffect(() => {
        if (recaptcha) {
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptcha, {
            'size': 'invisible',
            'callback': function (response) {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              console.log("recaptcha solved");
              // ...
            },
            'expired-callback': function () {
              console.log("Expired Session!");
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            }
          });
          window.recaptchaVerifier.render().then(function (widgetId) {
            window.recaptchaWidgetId = widgetId;
          });
        }
      }, [recaptcha]);


    
    const firstAndLastCheckAndSignUp = async () => {
        let launched = false;
        let canSignUp = true;
        if (firstName === '' || !firstName) {
            props.launchError("First name can't be empty.");
            canSignUp = false
            launched = true;
            return null;
        }
        if (lastName === '' || !lastName) {
            if (launched) {
                props.launchError(null);
            }
            props.launchError("Last name can't be empty.");
            canSignUp = false;
            launched = true;
            return null;
        }
        if (mobile === '' || !mobile) {
            if (launched) {
                props.launchError(null);
            }
            props.launchError("Mobile Number can't be empty.");
            canSignUp = false;
            launched = true;
            return null;
        }

        if (!(props.currentUser && props.currentUser.email && props.firebaseToken)) {
            if (launched) {
                props.launchError(null);
            }
            props.launchError("User is Not Logged In");
            canSignUp = false;
            launched = true;
            return null;
        }

        if (!firebaseAuth().currentUser) {
            console.log("Dont have current user");
            return;
        }
        let idToken = props.firebaseToken;
        if(!idToken){
             idToken = await firebaseAuth().currentUser.getIdToken();
        }
        if (canSignUp && props.currentUser && props.facebook && !props.currentUser.emailVerified) {
            canSignUp = false;
            const actionCodeSettings = {
                url:config.firebase.emailVerifyActionGenerator(firstName,lastName,mobile,email,props.firebaseToken),
                handleCodeInApp: true,
            };
              // this needs be uncomment
           
                let sendVerification = await  firebaseAuth().currentUser.sendEmailVerification(actionCodeSettings)
            
             props.launchVerificationSuccess(true, true);
             props.signUp(firstName, lastName, props.currentUser.email, mobile, true, idToken);
               
        }
        // this needs be uncomment
        if (canSignUp) {
            props.signUp(firstName, lastName, props.currentUser.email, mobile, true,idToken);
        }
    };

    return (
        <React.Fragment>
 
                <div ref={(ref) => setrecaptcha(ref)}></div>

            <p className="font-l primary-text-color semi-bold m-b-3">Create an Account</p>

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="fname">First Name</label>
                        <input id="fname" className="form-control global_input" type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                    </div>
                </div>
                <div className="second_name-block col">
                    <div className="form-group">
                        <label htmlFor="lname_label">Last Name</label>
                        <input id="lname_label" className="form-control global_input" type="text" onChange={e => setLastName(e.target.value)} value={lastName} />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="email-sign">E-mail</label>
                <input type="email" id='email-sign' readOnly className="form-control global_input" name="" value={email} onChange={e => setEmail(e.target.value)} />
                <small className="text-muted">We'll never share your email with anyone.</small>
            </div>
            <div className="form-group position-relative mt-4">

                <label htmlFor="mobile-sign">Mobile Number</label>
                <span className="manuallyAdd">+</span>
                <input id='mobile-sign' type="number" className="form-control global_input"
                 onChange={(e) => setMobile(e.target.value)} 
                 disabled={props.otp_verified}
                 value={mobile} />
                {mobile.length < 13 && mobile.length > 10 && !props.otp_verified ? (
                    <span className="global_link-text verifylinkposition pointer cursor-pointer" onClick={() => { props.firebaseSendOTP(mobile , true) }}>Verify</span>) : null}
                {mobile.length > 12 ? (
                <p className="font-s text-danger">Please Enter valid mobile number</p>
                ) : null}

                {
                props.fetchingOTP ?
                    <small className="text-muted">
                    Please wait..
                </small> : null
                }
                {  props.otp_received_error ? 
                    <p className="font-s text-danger">{props.otp_received_error}</p>
                : null
                }
            </div>


            {
        !props.fetchingOTP && props.otp_received && !props.otp_verified ?

          <div className="form-group mt-4 position-relative pos_rel ">
            <label htmlFor="otp-sign">Temporary Password</label>
            <input
              id="otp-sign"
              type="number"
              minLength="6"
              maxLength="8"
              className="form-control global_input"
              onChange={(e) => {
                setOTP(e.target.value)
              }}
              value={OTPNUMBER}
            />

            {OTPNUMBER.length < 8 && OTPNUMBER.length > 5 ? (
              <span className="global_link-text verifylinkposition submitLink pointer cursor-pointer" onClick={() => { props.firebaseVerifyOTP(OTPNUMBER) }}>Submit</span>) : null}

            <small className="text-muted"> Please enter the Pin sent on your mobile no. </small>
          {
           props.otp_verification_error ? <p className="font-s text-danger"> {props.otp_verification_error}</p> : null
          }

         {
           props.otp_verifying ? 
           <p>
               <small className="text-muted">
                  Verifying Pin...
                </small>  
           </p> : null
          }


          </div>

         
          : null
      }


            <button disabled={(firstName === '' || lastName === '' || !email || !mobile || mobile.length < 10 || mobile.length > 12 ) || loading 
        ||  !props.otp_verified } className="btn global_btn btn-block" type="button" name="button" onClick={e => firstAndLastCheckAndSignUp()}>Sign Up</button>
           
           
            <div className="text-center m-t-1">
                <small className="text-muted">
                    By clicking the 'Sign Up' button, you confirm that you accept our <br /> <Link to='/terms'>Terms of Use</Link>&nbsp;
              and <Link to='/policy'>Privacy Policy</Link>
                </small>
            </div>
            <div >
                <hr />
            </div>
            <div className="text-center footer-text">
                <p>Have an account?&nbsp;<Link to='/login' className="semi-bold">
                    Log In</Link></p>
            </div>
            <div className="row mt-2">
                <div className="col">
                    {
                        loading ?
                            <div className="container text-center">
                                <div className="spinner-border text-danger">
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    const { loading, user_exists, signup_proccess_running } = state.auth;
    const { fetchingOTP,
      errorFetching,
      otp_received,
      otp_received_error,
      otp_verifying,
      otp_verified, otp_verification_error } = state.otp;
    return {
      loading,
      user_exists,
      signup_proccess_running,
      fetchingOTP,
      errorFetching,
      otp_received,
      otp_received_error,
      otp_verifying,
      otp_verified,
      otp_verification_error
    };
  };


const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (f, l, e, p, le, token) => dispatch(signUp(f, l, e, p, le, token)),
        launchError: (err) => dispatch(launchError(err)),
        launchVerificationSuccess: (bool) => dispatch(launchVerificationSuccess(bool)),
        checkUserExists: (email, phone) => dispatch(checkUserExists(email, phone)),
        firebaseSendOTP: (phone , linking) => dispatch(firebaseSendOTP(phone , linking)),
        firebaseVerifyOTP: (otp) => dispatch(firebaseVerifyOTP(otp)),
        resetAuthState: () => dispatch(resetAuthState())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleSignup);

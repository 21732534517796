import API from "../config/api";
const UPDATING_SETTINGS = "UPDATING_SETTINGS";
const UPDATED_SETTINGS_SUCCESS = "UPDATED_SETTINGS_SUCCESS";
const UPDATED_SETTINGS_ERROR = "UPDATED_SETTINGS_ERROR";
const GETTING_SETTINGS = "GETTING_SETTINGS";
const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";
const CLEAR_SETTINGS = "CLEAR_SETTINGS";
const DELETING_CUSTOM_PRICES = "DELETING_CUSTOM_PRICES";
const DELETING_CUSTOM_PRICES_ERROR = "DELETING_CUSTOM_PRICES_ERROR";

export const DELETED_CUSTOM_PRICES_SUCCESS = "DELETED_CUSTOM_PRICES_SUCCESS";
export const USER_BOOKINGS_UPDATE = "USER_BOOKINGS_UPDATE";
export const HOST_BOOKINGS_UPDATE = "HOST_BOOKINGS_UPDATE";
export const SET_CUSTOM_SETTINGS_AS_GLOBAL_ROW =
  "SET_CUSTOM_SETTINGS_AS_GLOBAL_ROW";
export const SET_CUSTOM_SETTINGS_AS_CUSTOM_ROW =
  "SET_CUSTOM_SETTINGS_AS_CUSTOM_ROW";
export const SET_CUSTOM_SETTINGS_NUETRAL = "SET_CUSTOM_SETTINGS_NUETRAL";

export const customBookingSettingsStatusEnum = {
  IS_A_CUSTOM_ROW: "IS_A_CUSTOM_ROW",
  IS_A_GLOBAL_ROW: "IS_A_GLOBAL_ROW",
  EDITABLE_CUSTOM_ROW: "EDITABLE_CUSTOM_ROW",
};

// Actions
export const updateBookingFeeValues = (val, type) => {
  return {
    type: type,
    payload: {
      val,
    },
  };
};

export const clearSettings = () => {
  return {
    type: CLEAR_SETTINGS,
  };
};

export const changeCustomPriceEditMode = (type) => {
  return {
    type,
  };
};

export const toggleIsCustomPricing = (type) => {
  return {
    type,
  };
};

// Async Actions

export const deleteSettingsPrice = (token, val, id, host_id) => {
  return (dispatch) => {
    dispatch({
      type: DELETING_CUSTOM_PRICES,
    });

    const queryParams = {};

    queryParams.area_id = id;
    queryParams.host_id = host_id;

    API.delete(`/settings`, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
      params: queryParams,
    })
      .then(({ data }) => {
        console.log("data recieved after deleting custom prices => ", data);
        dispatch({
          type: DELETED_CUSTOM_PRICES_SUCCESS,
          payload: {
            ...data,
          },
        });
      })
      .catch((err) => {
        console.error("ERROR WHILE DELETING CUSTOM PRICES: ", err);
        dispatch({
          type: DELETING_CUSTOM_PRICES_ERROR,
          payload: {
            errorMessage: err.message,
          },
        });
      });
  };
};

export const updateSettings = (
  token,
  userBookingFee,
  hostBookingFee,
  host_id,
  id
) => {
  return (dispatch) => {
    dispatch({
      type: UPDATING_SETTINGS,
    });

    const queryParams = {};

    queryParams.userBookingPrice = userBookingFee;
    queryParams.hostBookingPrice = hostBookingFee;
    queryParams.host_id = host_id;
    queryParams.area_id = id;

    API.put(`/settings`, null, {
      params: queryParams,
      headers: {
        "X-AUTH-TOKEN": token,
      },
    })
      .then(({ data }) => {
        console.log("data recieved after updating settings API => ", data);
        dispatch({
          type: UPDATED_SETTINGS_SUCCESS,
          payload: {
            ...data,
          },
        });
      })
      .catch((err) => {
        console.error("ERROR WHILE UPDATING SETTINGS: ", err);
        dispatch({
          type: UPDATED_SETTINGS_ERROR,
          payload: {
            errorMessage: err.message,
          },
        });
      });
  };
};

export const getSettings = (token, host_id, id) => {
  return (dispatch) => {
    dispatch({
      type: GETTING_SETTINGS,
    });

    const queryParams = {};
    queryParams.area_id = id ? id : null;
    queryParams.host_id = host_id ? host_id : null;

    API.get(`/settings`, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
      params: queryParams,
    })
      .then(({ data }) => {
        console.log("data recieved from settings API => ", data);
        dispatch({
          type: GET_SETTINGS_SUCCESS,
          payload: {
            ...data.result,
          },
        });
      })
      .catch((err) => {
        console.error("ERROR OCURRED WHILE GETTING SETTINGS.", err);
        dispatch({
          type: GET_SETTINGS_ERROR,
          payload: {
            errorMessage: err.message,
          },
        });
      });
  };
};

// Reducers

const settingsInitState = {
  userBookingFee: 0,
  hostBookingFee: 0,
  updating: false,
  loading: false,
  error: null,
  isCustomSettings: null,
};
export default function settingsReducer(state = settingsInitState, action) {
  switch (action.type) {
    case GETTING_SETTINGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SETTINGS_SUCCESS:
      let userPrice =
        action.payload.data.user_booking_price === undefined
          ? 0.0
          : action.payload.data.user_booking_price;
      let hostPrice =
        action.payload.data.host_booking_price === undefined
          ? 0.0
          : action.payload.data.host_booking_price;
      let isCustomSettings = action.payload.isCustomSettings
        ? customBookingSettingsStatusEnum.IS_A_CUSTOM_ROW
        : customBookingSettingsStatusEnum.IS_A_GLOBAL_ROW;

      return {
        ...state,
        userBookingFee: userPrice,
        hostBookingFee: hostPrice,
        isCustomSettings,
        loading: false,
        error: null,
      };
    case GET_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.errorMessage,
      };
    case USER_BOOKINGS_UPDATE:
      return {
        ...state,
        userBookingFee: action.payload.val,
      };
    case HOST_BOOKINGS_UPDATE:
      return {
        ...state,
        hostBookingFee: action.payload.val,
      };
    case UPDATING_SETTINGS:
      return {
        ...state,
        loading: true,
        updating: true,
        updated: false,
        error: null,
      };
    case UPDATED_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        updated: true,
        updating: false
      };
    case UPDATED_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        updating: false,
        updated: false,
        error: action.payload.errorMessage,
      };
    case CLEAR_SETTINGS:
      return settingsInitState;
    case DELETED_CUSTOM_PRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DELETING_CUSTOM_PRICES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETING_CUSTOM_PRICES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.errorMessage,
      };

    case SET_CUSTOM_SETTINGS_AS_GLOBAL_ROW:
      return {
        ...state,
        isCustomSettings: customBookingSettingsStatusEnum.IS_A_GLOBAL_ROW,
      };
    case SET_CUSTOM_SETTINGS_AS_CUSTOM_ROW:
      return {
        ...state,
        isCustomSettings: customBookingSettingsStatusEnum.IS_A_CUSTOM_ROW,
      };
    case SET_CUSTOM_SETTINGS_NUETRAL:
      return {
        ...state,
        isCustomSettings: customBookingSettingsStatusEnum.EDITABLE_CUSTOM_ROW,
      };
    default:
      return state;
  }
}

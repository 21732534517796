import React, {Suspense} from "react";
import Layout from "../../components/shared/layout";
import BlockLoader from "../../components/shared/loading/inProgress";
const Parkings = React.lazy(() => import("../../components/parkings"));
export default function(props) {
    const isAvailabilityPage = props.location.pathname.includes("/availibility/");
    if (isAvailabilityPage) {
        return <Suspense fallback={<BlockLoader />}>
                <Parkings {...props}/>
            </Suspense>
    }
    return <Layout>
        <Parkings {...props}/>
    </Layout>
}

import api from "../config/api";
import Moment from 'moment';


const DELETING_EVENT = 'DELETING_EVENT';

const AUTO_FILL_EVENT = "AUTO_FILL_EVENT";
const GETTING_EVENTS = 'GETTING_EVENTS';
const GOT_EVENTS_SUCCESS = 'GOT_EVENTS_SUCCESS';
const GOT_EVENTS_ERROR = 'GOT_EVENTS_ERROR';

const GOT_ACTIVE_EVENTS = 'GOT_ACTIVE_EVENTS';
const GOT_UPCOMING_EVENTS = 'GOT_UPCOMING_EVENTS';
const GOT_PAST_EVENTS = 'GOT_PAST_EVENTS';

const SET_EVENT_NAME = 'SET_EVENT_NAME';
const SET_EVENT_TYPE = 'SET_EVENT_TYPE';
const SET_EVENT_LOCATION_STREET = "SET_EVENT_LOCATION_STREET";
const SET_EVENT_LOCATION_CITY = 'SET_EVENT_LOCATION_CITY';
const SET_EVENT_LOCATION_STATE = 'SET_EVENT_LOCATION_STATE';
const SET_EVENT_LOCATION_COUNTRY = 'SET_EVENT_LOCATION_COUNTRY';
const SET_EVENT_LOCATION_PINCODE = 'SET_EVENT_LOCATION_PINCODE';
const SET_EVENT_VENUE = 'SET_EVENT_VENUE';
const SET_EVENT_DATE = 'SET_EVENT_DATE';
const SET_EVENT_TIME_IN = 'SET_EVENT_TIME_IN';
const SET_EVENT_TIME_OUT = 'SET_EVENT_TIME_OUT';

const MAKING_NEW_EVENT = "MAKING_NEW_EVENT";
const MADE_NEW_EVENT = "MADE_NEW_EVENT";
const COULDNT_MAKE_NEW_EVENT = "COULDNT_MAKE_NEW_EVENT";
const INITIALIZE_SUCCESS = 'INITIALIZE_SUCCESS';
const CLEAR_EVENT_FIELDS = 'CLEAR_EVENT_FIELDS';

const EVENT_REMOVE_IMAGE = 'EVENT_REMOVE_IMAGE';
const DELETE_EVENT_FAILED = "DELETE_EVENT_FAILED";
const GOT_PARKING_EVENTS = "GOT_PARKING_EVENTS";

export const EVENT_UPLOAD_FILE = 'EVENT_UPLOAD_FILE';


// Simple Actions
export const initializeNewEventsSuccess = (val) => {
    return {
        type: INITIALIZE_SUCCESS,
        payload: {
            val,
        },
    };
};

export const clearEventFields = () => {
    return {
        type: CLEAR_EVENT_FIELDS,
    };
};



export const fetchAllEvents = (token, pageNumber = null, itemsPerPage = null, filterTerm = null) => {

    return dispatch => {

        const queryParams = {};
        if (itemsPerPage) {
            queryParams.limit = itemsPerPage;
        }
        
        if (pageNumber !== undefined && pageNumber !== null && itemsPerPage) {
            queryParams.offset = (pageNumber - 1) * itemsPerPage;
        }
      
        if (filterTerm) {
            queryParams.filter_key = filterTerm;
        }

        const uri = "/events/all";
        api.get(uri, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
            params: queryParams
        })
            .then(({ data }) => {
                // console.log({ data });
                if (data.success) {
                    const eventsList = data.result.events;
                    dispatch({
                        type: GOT_PARKING_EVENTS,
                        payload: {
                            events: eventsList.events,
                            count: eventsList.totalEventCount
                        }
                    });
                }
            })
            .catch(err => {
                console.error("ERROR RECEIVING EVENTS", err);
                // dispatch({
                //     type: GOT_EVENTS_ERROR,
                //     payload: {
                //         err: err.response ? err.response.data : err,
                //         errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                //     },
                // });
            });
    };
};


// Async Actions

const _getEvents = (token, pageNumber = null, itemsPerPage = null, searchTerm = null) => {

    return dispatch => {
        dispatch({
            type: GETTING_EVENTS,
        });

        const queryParams = {};
        if (pageNumber !== undefined && pageNumber !== null && itemsPerPage) {
            queryParams.offset = (pageNumber - 1) * itemsPerPage;
        }
        if (itemsPerPage) {
            queryParams.limit = itemsPerPage;
        }
        if (searchTerm) {
            queryParams.search_term = searchTerm;
        }

        const uri = "/events/paged";
        api.get(uri, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
            params: queryParams
        })
            .then(({ data }) => {

                const {
                    upcomingEvents,
                    activeEvents,
                    pastEvents,
                    activeEventsCount,
                    pastEventsCount,
                    upcomingEventsCount
                } = data.result.events;

                dispatch({
                    type: GOT_EVENTS_SUCCESS,
                    payload: {
                        events: [
                            ...upcomingEvents,
                            ...activeEvents,
                            ...pastEvents,
                        ],
                        activeEventsCount,
                        pastEventsCount,
                        upcomingEventsCount
                    },
                });
                dispatch({
                    type: GOT_ACTIVE_EVENTS,
                    payload: { activeEvents, activeEventsCount },
                });
                dispatch({
                    type: GOT_UPCOMING_EVENTS,
                    payload: { upcomingEvents, upcomingEventsCount },
                });
                dispatch({
                    type: GOT_PAST_EVENTS,
                    payload: { pastEvents, pastEventsCount },
                });
            })
            .catch(err => {
                console.error("ERROR RECEIVING EVENTS", err);
                dispatch({
                    type: GOT_EVENTS_ERROR,
                    payload: {
                        err: err.response ? err.response.data : err,
                        errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                    },
                });
            });
    };
};

export const getEvents = _getEvents;

//// Made By Vishnu resoju
export const deleteEvent = (token, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: DELETING_EVENT,
            payload: {
                id,
            },
        });
        dispatch({
            type: 'SHOW_EVENT_MODAL',
            payload: {
                val: true,
            },
        });

        // console.log("Trying to delete");
        // console.log(id);
        // console.log(token);


        api.delete(`/events?deleteId=${id}`, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
        })
            .then(({ data }) => {
                // console.log(data);

                dispatch({
                    type: MADE_NEW_EVENT,
                    payload: {
                        ...data.result,
                    },
                });
                dispatch({
                    type: 'SHOW_EVENT_MODAL',
                    payload: {
                        val: null,
                    },
                });


                dispatch({ type: "EVENT DELETED" });

                // console.log("token being used for calling getEvents: ", token);
                dispatch(getEvents(token));

            })
            .catch((error) => {

                console.log("ERROR RECEIVING EVENTS", error.response);

                error = error.response && error.response.data ? error.response.data : error;

                dispatch({
                    type: 'SHOW_EVENT_MODAL',
                    payload: {
                        val: null,
                    },
                });

                dispatch({
                    type: DELETE_EVENT_FAILED,
                    payload: {
                        error: true,
                        errorMessage: error.info ? error.info : error.error && error.error.info ? error.error.info : error.error && error.error.message ? error.error.message : 'Delete event failed!'
                    }
                })
            });
    };
};

export const clearEventDeleteState = () => {
    return (dispatch) => {
        dispatch({ type: "CLEAR EVENT DELETE STATE" });
    }
}



export const createNewEvent = (token) => {
    return (dispatch, getState) => {
        dispatch({
            type: MAKING_NEW_EVENT,
        });

        const newEventDetails = getState().newEvent;
        let parcel = {
            data: {
                addressData: {
                    street: newEventDetails.location.street,
                    city: newEventDetails.location.city,
                    state: newEventDetails.location.state,
                    country: newEventDetails.location.country,
                    pincode: newEventDetails.location.pincode
                },
                venueData: {
                    name: newEventDetails.venue,
                },
                eventData: {
                    name: newEventDetails.name,
                    event_start_time: new Date(newEventDetails.time_in).toISOString(),
                    event_end_time: new Date(newEventDetails.time_out).toISOString(),
                    event_category: newEventDetails.type,
                    event_imgs: newEventDetails.event_new_file,
                }
            }
        };

        if (newEventDetails.id) {
            parcel.data.eventData.id = newEventDetails.id;
            if (newEventDetails.venue_id) {
                parcel.data.venueData.venue_id = newEventDetails.venue_id
            }
            if (newEventDetails.address_id) {
                parcel.data.addressData.address_id = newEventDetails.address_id;
            }
        }

        let newReq;
        if (newEventDetails.id) {
            newReq = api.put('/events', parcel, {
                headers: {
                    'X-AUTH-TOKEN': token,
                },
            })
        } else {
            newReq = api.post('/events', parcel, {
                headers: {
                    'X-AUTH-TOKEN': token,
                },
            })

        }

        newReq.then(({ data }) => {
            dispatch({
                type: MADE_NEW_EVENT,
                payload: {
                    ...data.result,
                },
            });
            dispatch(getEvents(token));
        })
            .catch(err => {
                dispatch({
                    type: COULDNT_MAKE_NEW_EVENT,
                    payload: {
                        err: err.response ? err.response.data : err,
                        errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                    },
                });
            });
    };
}




// Reducers

let dt = new Date();
dt.setHours(dt.getHours() + 1);
const newEventInitState = {
    name: null,
    type: null,
    location: {
        street: null,
        city: null,
        state: null,
        country: null,
        pincode: null
    },
    venue: null,
    date: null,
    time_in: new Date().toISOString(),
    time_out: dt.toISOString(),
    event_file_progress: 0,
    event_new_file: [],
    event_file_error: null,
    loading: false,
    success: false,
    error: null,
}

export const newEvent = (state = newEventInitState, action) => {
    switch (action.type) {
        case CLEAR_EVENT_FIELDS:
            return {
                ...newEventInitState,
            };

        case AUTO_FILL_EVENT:

            let event_obj = action.payload.val;
            // console.log("event iob");
            // console.log(event_obj);

            return {
                ...event_obj,
                ...state,
                name: event_obj.event_name,
                type: event_obj.event_category,
                location: {
                    street: event_obj.street,
                    city: event_obj.city,
                    state: event_obj.state,
                    country: event_obj.country,
                    pincode: event_obj.pincode,
                    venue: event_obj.venue_name,
                },
                venue: event_obj.venue_name,
                date: event_obj.event_start_time,
                time_in: event_obj.event_start_time,
                time_out: event_obj.event_end_time,
                event_new_file: Array.isArray(event_obj.imgs) ? event_obj.imgs.map(obj=>obj.path) : Array.isArray(event_obj.img) ? event_obj.img : [event_obj.img],
            }

        case SET_EVENT_NAME:
            return {
                ...state,
                name: action.payload.val,
                success: false,
                error: null,
                loading: false,
            }
        case SET_EVENT_TYPE:
            return {
                ...state,
                type: action.payload.val,
                success: false,
                error: null,
                loading: false,
            }
        case SET_EVENT_LOCATION_STREET:
            return {
                ...state,
                location: {
                    ...state.location,
                    street: action.payload.val
                },
                success: false,
                error: null,
                loading: false,
            }
        case SET_EVENT_LOCATION_CITY:
            return {
                ...state,
                location: {
                    ...state.location,
                    city: action.payload.val
                },
                success: false,
                error: null,
                loading: false,
            }
        case SET_EVENT_LOCATION_STATE:
            return {
                ...state,
                location: {
                    ...state.location,
                    state: action.payload.val
                },
                success: false,
                error: null,
                loading: false,
            }
        case SET_EVENT_LOCATION_COUNTRY:
            return {
                ...state,
                location: {
                    ...state.location,
                    country: action.payload.val
                },
                success: false,
                error: null,
                loading: false,
            }
        case SET_EVENT_LOCATION_PINCODE:
            return {
                ...state,
                location: {
                    ...state.location,
                    pincode: action.payload.val
                },
                success: false,
                error: null,
                loading: false,
            }
        case SET_EVENT_VENUE:
            return {
                ...state,
                venue: action.payload.val,
                success: false,
                error: null,
                loading: false,
            }
        case SET_EVENT_DATE:
            return {
                ...state,
                date: action.payload.val,
                success: false,
                error: null,
                loading: false,
            }
        case SET_EVENT_TIME_IN:
            return {
                ...state,
                time_in: action.payload.val,
                success: false,
                error: null,
                loading: false,
            }
        case SET_EVENT_TIME_OUT:
            return {
                ...state,
                time_out: action.payload.val,
                success: false,
                error: null,
                loading: false,
            }
        case INITIALIZE_SUCCESS:
            return {
                ...state,
                success: false,
                loading: false,
                error: null,
            };
        case MAKING_NEW_EVENT:
            return {
                ...state,
                loading: true,
                success: false,
                error: null,
            }
        case MADE_NEW_EVENT:
            return {
                ...state,
                success: true,
                loading: false,
                error: null
            }
        case COULDNT_MAKE_NEW_EVENT:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload.errorMessage
            }
        case EVENT_UPLOAD_FILE + '_UPLOAD_SUCCESS':
            return {
                ...state,
                event_new_file: [...state.event_new_file, action.payload.url],
                event_file_error: null,
                event_file_progress: 100,
            };
        case EVENT_UPLOAD_FILE + '_UPLOAD_ERROR':
            return {
                ...state,
                event_file_progress: 0,
                event_file_error: action.payload.errorMessage,
            };
        case EVENT_UPLOAD_FILE + '_UPLOAD_RUNNING':
            return {
                ...state,
                event_file_progress: action.payload.progress,
                event_file_error: null,
            };
        case EVENT_REMOVE_IMAGE:
            const arr = state.event_new_file;
            arr.splice(action.payload.index, 1);
            return {
                ...state,
                event_new_file: arr,
            };
        default:
            return {
                ...state,
            }
    }
}

export const makeNewEvent = (state = {}, action) => {
    switch (action.type) {
        case MAKING_NEW_EVENT:
            return {
                ...state,
                loading: true,
                error: null
            }
        case MADE_NEW_EVENT:
            return {
                response: action.payload,
                loading: false,
                error: null
            }
        case COULDNT_MAKE_NEW_EVENT:
            return {
                loading: false,
                error: action.payload.errorMessage
            }
        default:
            return {
                ...state,
                loading: false,
                error: null
            }
    }
}


const eventsInitState = {
    loading: false,
    error: null,
    total_events: 0,
    event_deleted: false,
    events: {}
};
export default function eventsReducer(state = eventsInitState, action) {
    switch (action.type) {
        case GETTING_EVENTS:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case GOT_EVENTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.errorMessage,
            };

        case GOT_EVENTS_SUCCESS:
            let eventHolder = {};
            let count = 0;
            for (let event of action.payload.events) {
                eventHolder[event.id] = { ...event };
                count++;
            }
            return {
                loading: false,
                error: null,
                events: eventHolder,
                total_events: count,
            };
        case "EVENT DELETED":
            return {
                ...state,
                event_deleted: true
            }
        case "CLEAR EVENT DELETE STATE":
            return {
                ...state,
                event_deleted: false
            }
        default:
            return state;
    }
};

let deleteEventInit = {
    event_deleted: false,
    errorMessage: null,
    error: null
}

export const deleteEventsReducer = (state = deleteEventInit, action) => {
    switch (action.type) {

        case "EVENT DELETED":
            return {
                ...state,
                error: null,
                errorMessage: null,
                event_deleted: true
            }
        case "CLEAR EVENT DELETE STATE":
            return {
                ...state,
                error: null,
                errorMessage: null,
                event_deleted: false
            }

        case DELETE_EVENT_FAILED:
            return {
                ...state,
                error: true,
                errorMessage: action.payload.errorMessage
            }
        default:
            return state;

    }
}

const activeEventsInitState = {
    total_active_events: 0,
    events: {},
    loading: true
};
export const activeEventsReducer = (state = activeEventsInitState, action) => {
    switch (action.type) {

        case GOT_ACTIVE_EVENTS:

            let eventHolder = {};
            let allEvents = action.payload.activeEvents;
            eventHolder = formatTheEventData(allEvents)

            return {
                events: eventHolder,
                total_active_events: action.payload.activeEventsCount || 0,
                loading: false
            };

        default:
            return state;
    }
};

const upcomingEventsInitState = {
    total_upcoming_events: 0,
    events: {},
    loading: true,
};

export const upcomingEventsReducer = (state = upcomingEventsInitState, action) => {
    switch (action.type) {
        case GOT_UPCOMING_EVENTS:
            let eventHolder = {};
            let allEvents = action.payload.upcomingEvents;
            eventHolder = formatTheEventData(allEvents)
            return {
                events: eventHolder,
                total_upcoming_events: action.payload.upcomingEventsCount || 0,
                loading: false,
            };
        default:
            return state;
    }
};

const pastEventsInitState = {
    total_past_events: 0,
    events: {},
    loading: true,
};
export const pastEventsReducer = (state = pastEventsInitState, action) => {
    switch (action.type) {
        case GOT_PAST_EVENTS:
            let eventHolder = {};
            let allEvents = action.payload.pastEvents;
            eventHolder = formatTheEventData(allEvents)
            return {
                total_past_events: action.payload.pastEventsCount || 0,
                events: eventHolder,
                loading: false,
            };
        default:
            return state;
    }
};

const parkingEventsInitState = {
    total_events: 0,
    events: {}
};
export const parkingEventsReducer = (state = parkingEventsInitState, action) => {
    switch (action.type) {
        case GOT_PARKING_EVENTS:
            let eventHolder = {};
            let allEvents = action.payload.events;
            eventHolder = formatTheEventData(allEvents)
            return {
                total_events: action.payload.count || 0,
                events: eventHolder,
            };
        default:
            return state;
    }
};



/////////////
/////// These are helper functions to convert date n times
/////////////


// const _MS_PER_DAY = 1000 * 60 * 60 * 24;
// const dateDiffInDays = (a, b) => {
//     // Discard the time and time-zone information.
//     const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
//     const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
//     return Math.floor((utc2 - utc1) / _MS_PER_DAY);
// }

const hoursDiff = (dt1, dt2) => {
  dt1 = Moment(dt1);
  dt2 = Moment(dt2);
  return dt2.diff(dt1, "hours");
}

export const formatTheEventData = (events) => {
    let eventHolder = {};
    for (let event of events) {
        if (event.event_start_time && event.event_end_time) {
            event.from_to_date = Moment(event.event_start_time).format('MM/DD/YYYY') + " - " + Moment(event.event_end_time).format('MM/DD/YYYY');
            event.start_time = Moment(event.event_start_time).format("h:mm A");
            event.end_time = Moment(event.event_end_time).format("h:mm A");
            const difference_in_hrs = hoursDiff(event.event_start_time, event.event_end_time)
            event.difference_in_hrs = difference_in_hrs + (difference_in_hrs === 1 ? 'Hr':  "Hrs");
        }
        eventHolder[event.id] = { ...event };
    }
    return eventHolder;
}

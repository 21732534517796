export const CalendarEventColors = {
  BLOCK: "#FCD7E0",
  PRICE_CHANGE: "#D2F4D3",
};

export const RecursionOptions = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];

export const CalendarViewModelOptions = [
  {
    label: "Monthly",
    value: "month",
  },
  {
    label: "Weekly",
    value: "week",
    default: true,
  },
  {
    label: "Daily",
    value: "day",
  },
];
export const  EnforcementStatus = [
  {
    label: "Success",
    value: "success",
    class: "text-success"
  },
  {
    label: "Retry Pending",
    value: "retry-pending",
    class: "text-warning"
  },
  {
    label: "Processing",
    value: "pending",
    class: "text-info"
  },
  {
    label: "Failure",
    value: "failure",
    class: "text-danger"
  },
]
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import config from "../../config/config";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

firebase.initializeApp(config.firebase.config);
const firebaseAuth = firebase.auth;
const firebaseStorage = firebase.storage;

export default firebaseAuth;
export { firebaseStorage, firebase };

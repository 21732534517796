import {connect} from "react-redux";
import Layout from "../../components/shared/layout";
import React, {lazy} from "react";
const AdminEvents = lazy(() => import("../../components/events"))
const mapStateToProps = (state) => {
    return {
        role: state.profile.role,
    };
};

export default connect(mapStateToProps)(props => {
    return (
        <Layout>
            <AdminEvents/>
        </Layout>
    );
});

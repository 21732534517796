import React from "react";
import { Link, withRouter } from "react-router-dom";
import IntegrationsLogo from "../../../Assets/imgs/integrations.svg";
/** 
import earnings from "../../../Assets/imgs/coin.svg";
import parkings from "../../../Assets/imgs/hostside.svg";
import dashboard from "../../../Assets/imgs/dashboard.svg";
import events from "../../../Assets/imgs/events.svg";
import bookings from "../../../Assets/imgs/bookings.svg";
import users from "../../../Assets/imgs/userside.svg";
import qrcode from "../../../Assets/imgs/qr-code.svg";

*/

import "./style.scss";

const Settings = (props) => {
  return <React.Fragment>
    <svg width="20px" height="20px" className="mr-3" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <title>F082550C-AEAF-4106-95C0-8EBA337C8ACB@3x</title>
      <desc>Created with sketchtool.</desc>
      <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity={props.opacity}>
        <g id="NewIcon/Green/settings">
          <image id="streamline-icon-cog@40x40" x="0" y="0" width="20" height="20" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABGdBTUEAALGN5fIAKQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAKKADAAQAAAABAAAAKAAAAABZLkSWAAAD8klEQVRYCb2WjXHaQBCFSSYFqAOrA9NB6CDqwHRgUoHVgUkFKBVYHXCpwEoFUiqADpz3kVvmOJ9+CErezPPt7b59uxL24MXiOjxIvhffxFfxWRzDkwRo6aH3Ufwn2MmVITEZniUmkrPF4h68ZsVGbjakU7wVnWi5eMl4ObSl2InWs1Y8G5ycMO7EEJUuNpAll+JnMXxzle4GFu9Eepw4Gw5ywpQ3F6NWwpaMzyoW644HOjxH8XFU8Ufwwet4AzEKJb7FSZ/bJPKWMk+733Q6dfPUnH1geZZdibnYB6fCmFdfb2++9aZ1r2J6ofJeeI5iykfMW8m9k/PnLUfjm3Odax9POh6k2ov88vJ0xJxvnkedmXgr8MDLfJmxF5kLiR/FC+x0exsghsuLjtsueIVLpmaz04K/pI34zEX4JdZiJuYiJk6sROIU0N6LK3EpgkZ04k9xqG+t+krEgx5QiHenaLH4yulEngABwmuAGR9L6g2Qo4bmWnRqoN+JZ/OSy0TwIC9ivFijHIzzaOmZiq2E5nE2xGQqagnNoFNcJBrJUTPdNf5739foPH2Jm8mOxAgYbHqnOBvQU0NjenrHwA6m35q4CZIIME6B/EHEoBP7dCqdgaYT6aF3qIe3bMs1is+giYQVt+fKZbAKNMVlafCG1rxXPUpmmqZRnMU6EhRMlMcC3UvR6onyYMr68IjBbKuzA/ckcmVNWCQUta9jci3owRuPGIUSNjcPi1O+i0O9xZj9d2Sa+Crak3CPUSph9bg2drc+PGLkSlidHd7Njpfbxg7+vtJpRkWPJpVGa32rlEA5Zprm3ZLhm6t7DEhn4lHEqPV3HYOg5yDSQy/3FMjXYrjkSbcNktUpM/yjCPR7xRj3gRoaG0rvGCoJTF8itrfnuExELZ2ZtIq/JPpYxt4cWnqmwklID7udB5VcJiKTLlzSlsUQ2t1OtPRMRSmh9Z6/K1slrzFhWCEeRTOLT2porkEmMbvg5eJ/WDslaxEsxaPoxO8+1vEOGKJd+VPH6RvJ+ROPFOh7FOkl7kS0hZiL4PQPK0Elxk8f3g+qYzQX8MIznBHHVTxso4QTj57EnWiNGGbircAjXK7T3Ynh3LXuk1FIaUuWk7v6hZvAD+9Z0MmFJesZ3PDAq5vi9WmKyJvd6cwG9Llq977+Q+fRx/FhHl1cuOXeqpmnrhImDHwWqYckl0KlJLo2VfzbHG8D023C4MXXwuUsphYDD+p4zgYnJ0xbMRMNOwXkYSPmnsSWR2OgtxWpOXE2rOVkA18VP4nhm2MhhhuIycU9bZBbK54VldxsYHjGy9nQeMmwB69/go1cncgwFivFMZQSoKXHiWtxMn4Dcr+7KbeBCp0AAAAASUVORK5CYII="></image>
        </g>
      </g>
    </svg>
  </React.Fragment>;
};
const Users = (props) => {
  return (
    <React.Fragment>
      <svg
        width="20px"
        height="20px"
        className="mr-3"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <title>DF01B380-BC2B-4755-8BEC-C1D47E014853@3x</title>
        <desc>Created with sketchtool.</desc>
        <g
          id="Asset-Artboard-Page"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity={props.opacity}
        >
          <g id="NewIcon/Green/Users">
            <image
              id="streamline-icon-multiple-users-1@40x40"
              x="0"
              y="0"
              width="20"
              height="20"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABGdBTUEAALGN5fIAKQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAKKADAAQAAAABAAAAKAAAAABZLkSWAAAD8UlEQVRYCc1W0XXTQBBUePxHHXAdRB1wVICoIKaCmAoiKsAdxDRAQgVRKoiowNeB3QHMKDfKRrmTHUFC5r3xzu3O3q5lHFMU/wZLXHMNbiOpz8D/jgob3IK/M2TNgbNxNLuzKEr02gVucG7jfTXiSdQd4gdwF88vFlaYpCfnE1OZU53eFwefCBeYGr6Oni3iLLxJdJXIfQP58XEBRp6ZFxzEcTy0MaaCaux1xnDIDGO/l7xkA+qjsZF51gkPqkadg0dh7HPI8S7lbWSe9SxaVNRwBd2Aa1C5a2hBuaUSidggJ5/Krcntm6GePnrTuOgz9y9cQoN8THcxt4nnVJCHkfCg7llAW6Rm2HphDeWDyt2/P11MH+FB5a6hP4Lqq6GZU72CJtirnLx9AS88q0Zfj7cSo7gbnVPHFskvIL9APhLhEejpHmVn/F30uETvYDG6kO9INT+q1TjzDamuyJwHLTwOqi9sAXpqxmBtoXTBJfQ5eGFyLXQOHPAj8ixnQr4F587ov+LBXKCLGJl3oEWNwy1ofVazRo+FwyGA1ifNvAMnUaK6AjuQjYw8l6BAzSesi/dFesf9vLOLdzDyXIJ7UcNxAerJLEYdFc5bUEvtoBvQgxxAUjcga/Kxx4EWSxyY5yzOrMEsePElqAsV16OOzniuoNmXA2v06C4uYmFr8nCHR3cysQVlCtBsbkAHCg2EPNSHooEx1eeQX4KcFUB5uAt3GkCDiivoB8XocsbTxdxTAns0wyUaOXNlPHySPWq8qpGGHJYoyFflTBN59qifd+WwQkG+mqZ1TATEEsxhjQIbAzgXAY28Yw3mwB0C2PveQFQg0YG7XqVfrC/t2J/lDOLkLiRfucPg44IyK5nseoIv18+8ZlRTJuvjgq8ar35BPj19YxSHr/fo0apu4waecuSzR9bosT3S1id9lfA++Dmaat4lmun3YA4eBd1pY8g0WA914H9YPViDDjwFiQrsenX/4iHpE86jcEokoq19NfXWaEnOFL5DBLAFB3govQPqfZC3mTCyJt+ErS9546XuYb8k9onZdyPvc0c7c9jFLrgzG5RG5+RNLPicAXnV5J2wPviyDbvYBdn8K95QTd30TDXN1A79mPGC2rw8YIkQPe8mvC7WwoRHJc3UDn1+vGAX3fr5U3Mqhph0qWLMafkw4VGpikI79Mfxgtq+VNcLxuM4SzskF7Tb68+D4ma0bGvO3mhJm2uVROSbvwV1r6Isdodi/ASDXInokCP/Fg4XVBOXBFvjL4kFt/8MOpuMOiCSQpBAPAXfmzOlM+dgNGd8AlNL0sf6gKNBzRP8eA7B7Dnjj/iQYdbz0x4y+hBPprUo/gBH9YNeJ/n3iAAAAABJRU5ErkJggg=="
            ></image>
          </g>
        </g>
      </svg>
    </React.Fragment>
  );
};
const Events = (props) => {
  return (
    <React.Fragment>
      <svg
        width="20px"
        height="20px"
        className="mr-3"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <title>01E12835-6169-4B73-AF27-91C0ECA26841@3x</title>
        <desc>Created with sketchtool.</desc>
        <g
          id="Asset-Artboard-Page"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity={props.opacity}
        >
          <g id="NewIcon/Green/events">
            <image
              id="streamline-icon-party-decoration-1@40x40-(1)"
              x="0"
              y="0"
              width="20"
              height="20"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABGdBTUEAALGN5fIAKQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAKKADAAQAAAABAAAAKAAAAABZLkSWAAADWElEQVRYCd2XvWsUURTFNbGxm9Iu8xfolhaK7x8QtlOrbGfp9hYZG7VzLQOCW6USXbGyysSPwsrYRRB2QES7rKAgKMbfCe8mL+PMzsxm1gQPnNz77j33Y99+ZRdPHC06jL8Ov8IvR7tK8fQR4R0oW4iFwui/C0Z+lNlGk6+i/gl/w9VGldPFWmYb6ubyVLz2sh+CBr/w20JMo/xi4Vn5WthAZYXfalXUF3WQJjCDmiGbQMVrQ1f9BL6FZwuq1KxRw4IeKTEtKNsqYrrZ7cqfFV0KUyjbKhzdbEH5xwZ6pOtQT7stKF+x1m+Bno0xosIWy1vlWsdiw45b6E/Dk/CMr32HfQ7vwrl8XSU01rvVwbpwCO0G5c8VNkj2IYxrTIvQTDzlzxU9umuYLfodfw3OCy9orHm3mwyIEQ+hLSn7ES7DNnGHZuGMpabN+7kGajaG92AMD4sHNAgXvNC0oQsa6Ps3bCZfn3m3YNFXH+FK6PX6Hv6AzyrVBYIuMVvqIn4PptBioX1JfAXehLMuTGkzJMhtibAy5qA3juXK7DoavRyW4aWAuLWhW74CVR+dqlnm0F3zWj31r6E+qM/5mBmHI1ZBT/NnL3JTxFk+lxDY8bRcL4hN8DuWwIYviUecU2j1bdis6gYHDLwBBf3ycnATGkL/FUHphQ6MoM52y6v454PzG3y9WVJo6OKY/jK+eh6ABPbItwN/gt85oNw/KKea4X5o1+vz13olPhcHsYGPmYmC3NCCeStRBq2x7CbswDKMSEg3DgQxvj3ADF99DSmO9MqH8b6PK+dgKWIyCUyhisImHP9CQkRNRdOmQczhh+hyMH0vSGQ+LtsqHN1soHw9KDsP8IuQEZRm7JPOnxVTfauI6DaBNrDsqQ2HagnpRQdH3lefCLaOhI420KybMkVLaBnTmh1OqTlUSgP7MIVD6GAVugjCJTc5R1VFlo9xHsP7sHYR2qaIKejDbtPCTxTYta81La7QO/JxhaYybcvJ2ndmZVENQQ+Neo5raHclCyXC8H+1lRLNLOHYF5mdpcdezdKed3gnocU61M3teOosxvDIMWEDWyxv9SYpRdMf7qWNKhJb5PWDX4y8dgP7FA6g/qs5FkjYwm6w1kJlb5JaxTOIUl+j2/s/8AfxAissI1PjeQAAAABJRU5ErkJggg=="
            ></image>
          </g>
        </g>
      </svg>
    </React.Fragment>
  );
};
const Dashboard = (props) => {
  return (
    <React.Fragment>
      <svg
        width="20px"
        height="20px"
        className="mr-3"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <title>54C85134-FAA4-4F95-BC26-BC7DEE438A2F@3x</title>
        <desc>Created with sketchtool.</desc>
        <g
          id="Asset-Artboard-Page"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity={props.opacity}
        >
          <g id="NewIcon/Green/dashboard">
            <image
              id="streamline-icon-layout-dashboard@40x40-(1)"
              x="0"
              y="0"
              width="20"
              height="20"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABGdBTUEAALGN5fIAKQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAKKADAAQAAAABAAAAKAAAAABZLkSWAAAB30lEQVRYCe2Y/02DQBiG0fi/bFCcQJ2gjMAGZQPZoGxgO4FsIBuIG3QDcQLpBPV545E0JP1xHxDbhDd5vCt379e3d3A1DYIgCOEddka+8L1CVysuaMxS9wefaipbUIKlSNeTqZiT+t1xy+vVjSukup9QqeOplPkzkD8GqYI5fEMBvooxyN/I2H6yXC8MqvCohtpWFZ3utXbsnDZ3/t3tObP/c84UsO/qTys4rWDfFejrv+tb4IC/5roO2kf4AF9FzrAdK+CKN1hACDFYVYwVcEOiZ8ggAotKTKMFVCCFTNXpo4s/B8faYi1aAu19aFlEbfF6rIApxd8sqfY8Mf2HsQJq9aQt6F70VYRhBouxAoYukcLFru/T5ExeQngVD4m24R50Q8/BV/q2kJq/Zti/2uISFC5y0JhUmFwnTAqYge6V5MTcQ8MNA6Xj0BzzdQXUG6wc5kJjGa/iIQn59LoHE+Mq1PjWsDH6j9q0xTm8HJ11ejBliv57GTykAqq4pF8BanU89cR8HVMZpDCoFFDFpQJy8FWFQednBIPr4h+SKWDfPZ9WcFrBvivQ169zsJXOsmX7wqOdubnNnqfta8xSc75Xa7Af0ZO9ourvBqBUzRDUsRas8WbQla7VYK2rTOEvxgTEkGpAq0oAAAAASUVORK5CYII="
            ></image>
          </g>
        </g>
      </svg>
    </React.Fragment>
  );
};
const Parkings = (props) => {
  return (
    <React.Fragment>
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        className="mr-3"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <title>4784FA03-D043-40CD-A6B0-EAEAFB59DD8D@3x</title>
        <desc>Created with sketchtool.</desc>
        <g
          id="Asset-Artboard-Page"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity={props.opacity}
        >
          <g id="NewIcon/Green/hosts">
            <image
              id="streamline-icon-car-garage@40x40"
              x="0"
              y="0"
              width="20"
              height="20"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAABGdBTUEAALGN5fIAKQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAKKADAAQAAAABAAAAKAAAAABZLkSWAAACpklEQVRYCdWXQY7TQBBFDeIAvgG9QuwmCxbspo/Ajm24wRzBN+AI+AaYE0yzZTNZskCK5wREgj28b3VJHieRO3Yn8XzpT7W7u379qjiKpijywSO1jdR6MShx8hn+G1B7OrsqPNU1NTO3Yy3as848vDgOTS3gwkVqbSYVLzpNT0FNxgxoYndwCO3pzO4px8OzoUR5+K4F9hw8BsdBgGZS8SzT9AhrAlZIk9GEUqG7yrF8aXk4GyUKp07tWFHHQYBmUnHWND0C6tQENQFNYi6kIS3TVQ0Pk1FyM9fUjhV1HARoJhWTpum5qI4sUZ2q43NB2qph9VTbwz2U7Jx7antF44YjBmgmFZ9M07Mh53ZBHamzS0M1Vdt8yJOHxW9om4G1g9eCo3CA5kfeigbKuTpYCuRFnuqlGHq+Pl5MtF6SdwtXE/MDed8n5o6myVT/i2Uv9KnxAR01mhUSzGHOmrnP6g6xCpr4mvVU1CSajp8qMszrTy8MD098ltYOymS2KVZRUKIezkWFQLYp5pyeNZZ1ilm7NYfEk3U/kPQVbqGN/1LxT6wtD3vQuGXsUmbG6nzBizwV9ksic+b8kXWALTS8jYuftpE5vkfvL3wHX0ftmvhJaxmzjiptXBkV9c1PN7QmbmyubKxfvo2empcsbuJJiHEJwbzcyKCLjnYxLiG00YR7lejGce8WKupV+AbnwJGcrGcvZHWkovbtjsUtew5OQUWS6Vgc6vXvPPnRdiT3seLBRIax7l9MXKfolWg9QNVrYfdP07D4oec1d5X8Cx46P3UvRa+hVvdRtcSxAjIn/IBjd1POx/Ra6nRfEi1WkZ7Yh35BPsaNe2KAb+LzI7GO69SQqrdBsEkRVZctPDSRuxSBwZ3cep2846+6aaGMbuAaToUjMVnvP1i+nDDo5wseAAAAAElFTkSuQmCC"
            ></image>
          </g>
        </g>
      </svg>
    </React.Fragment>
  );
};
const Earnings = (props) => {
  return (
    <React.Fragment>
      <svg
        width="20px"
        height="20px"
        className="mr-3"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <title>45D44AAA-0017-47EA-B0CC-5EA4B029F63D@3x</title>
        <desc>Created with sketchtool.</desc>
        <g
          id="Asset-Artboard-Page"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity={props.opacity}
        >
          <g id="NewIcon/Green/earnings" fill="#000" fillRule="nonzero">
            <g id="price">
              <path
                d="M17.0710937,2.92890625 C15.1823438,1.04015625 12.6710938,0 10,0 C7.32890625,0 4.81765625,1.04015625 2.92890625,2.92890625 C1.04019531,4.81765625 0,7.32890625 0,10 C0,12.6710938 1.04019531,15.1823438 2.92890625,17.0710937 C4.81765625,18.9598438 7.32890625,20 10,20 C12.6710938,20 15.1823438,18.9598438 17.0710937,17.0710937 C18.9598047,15.1823438 20,12.6710938 20,10 C20,7.32890625 18.9598047,4.81765625 17.0710937,2.92890625 Z M10,18.828125 C5.13214844,18.828125 1.171875,14.8678516 1.171875,10 C1.171875,5.13214844 5.13214844,1.171875 10,1.171875 C14.8678516,1.171875 18.828125,5.13214844 18.828125,10 C18.828125,14.8678516 14.8678516,18.828125 10,18.828125 Z"
                id="Shape"
              ></path>
              <path
                d="M10.6276172,9.4140625 L9.37238281,9.4140625 C8.65726563,9.4140625 8.07550781,8.83230469 8.07550781,8.11722656 C8.07550781,7.40210937 8.65730469,6.82035156 9.37238281,6.82035156 L11.8828125,6.82035156 C12.2064063,6.82035156 12.46875,6.55800781 12.46875,6.23441406 C12.46875,5.91082031 12.2064063,5.64847656 11.8828125,5.64847656 L10.5859375,5.64847656 L10.5859375,4.35160156 C10.5859375,4.02800781 10.3235938,3.76566406 10,3.76566406 C9.67640625,3.76566406 9.4140625,4.02800781 9.4140625,4.35160156 L9.4140625,5.64847656 L9.37242188,5.64847656 C8.01113281,5.64847656 6.90367187,6.75597656 6.90367187,8.11722656 C6.90367187,9.47847656 8.01117187,10.5859375 9.37242187,10.5859375 L10.6276562,10.5859375 C11.3427344,10.5859375 11.9244922,11.1677344 11.9244922,11.8828125 C11.9244922,12.5978906 11.3427344,13.1796875 10.6276562,13.1796875 L8.11722656,13.1796875 C7.79363281,13.1796875 7.53128906,13.4420312 7.53128906,13.765625 C7.53128906,14.0892187 7.79363281,14.3515625 8.11722656,14.3515625 L9.4140625,14.3515625 L9.4140625,15.6484766 C9.4140625,15.9720703 9.67640625,16.2344141 10,16.2344141 C10.3235938,16.2344141 10.5859375,15.9720703 10.5859375,15.6484766 L10.5859375,14.3515625 L10.6276172,14.3515625 C11.9888672,14.3515625 13.0963281,13.2440625 13.0963281,11.8828125 C13.0963281,10.5215625 11.9888672,9.4140625 10.6276172,9.4140625 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </React.Fragment>
  );
};

const Bookings = (props) => {
  return (
    <svg
      width="20px"
      height="20px"
      className="mr-3"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>BF7F8635-7051-47B4-8695-028A957A4290@3x</title>
      <desc>Created with sketchtool.</desc>
      <g
        id="Asset-Artboard-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity={props.opacity}
      >
        <g id="NewIcon/Green/bookings">
          <image
            id="streamline-icon-ticket-1@undefinedxundefined"
            x="0"
            y="0"
            width="20"
            height="20"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABGdBTUEAALGN5fIAKQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAMKADAAQAAAABAAAAMAAAAAD4/042AAADh0lEQVRoBcWWgXnaQAyFO4JH8AhsUI+QDXIbNBvABs0GvhG6Ad6gbAAbhA3o/wiiimLAwB2873s9SSdL7zmQ9MePevjN6N2BPWcLT+GVizVUv84ZfCp6tktMpOo/YQMl8hWu4S7wg/xpJvogJoqbmj/FRBSfMdPBAZ4TvuV+AfXWFVvvQ02MiUfLER1RhgOUwA0c4BtsoOEpJi6JN3FTz4eaKC3eTD7ERC3xDzExRXyHkjlU7yu8BVV+ElPEJ9TuApfkDbwWRU3cKt7M/EX900zcIn6F4AU0AzpLmVgzazJuFW9vO7GphIkuzCG9jHvF24ZEcK+JhZuxIb6IUuJtUSK41YR+mvrvhT2vWWdRWrwtSwQmQufU78Sbe07frbMoJb5ly2xkU6J2rYnBPfNCfBKlxEu4/cjTyDbVrjGxdv1jL2W/ooZ4icz76d//SZSmmvB9zfdRn3/yfVMeaUrUfM+KPA7T27E3r94tbOEpJC78zFPficH1dcRf0JP5IfnL7WeSOHzPVPEydAmJBj97OfJAdj2Kj+iJ/MP5ePM/SIS+p6R42xJ3KPfoSLyGVpfvoZhVDEjk/sEa4m3lwu3KVnTnQGxasuqW6NwXVHToiH3PreIb5sxhC09BPfr8277FSGPn7tV3bFbSqRDwTm4Dx8RrafzCzsIM5dazDneWao4Xr51xjnp7aHp07t+6FZYqBGRyu1+EO6UttPstcVyq3MSrbwMjGgpRfIpN5D20XTrf1dNCX+zIPRKJv1cekSj8gS2M2FCw57fEs9DQkN8iPvs5SmzJl4tD0+Du1ZfgFEicROsZnTPoofu7xWtgB83AQByhRStoPdeYSDw3wGrimf3tY6RaxD0mxmYVefM2WG/H3u7GiiNnCROaUVS8dCZoBgbic7jHRBXxEruGZuDtnPrD3S0mqolPTvyWWIum4BoT1cRLqH/7iynKXc8UE1XFS4t9dHS2KlyJcyaqi5fWDTQT+u2gpddizMScIcV/24wJm1HcwhombKbOBCN6Cr4nx4apeUkTA0u9qOrizWQpE5qXYIYLqLkRxd58HFzSRJxteTXxtqCmieria5p4mPipJhoaf8ElXMPdIZbQDnqopntj9pc147GPU8vCOfyAJmjsXHLfwT70ZfKHIpoYEzu1lh+q3C07ZWJFzwvUfQM7mOGYIdWfConcQInTmeAptFxkaEbeiavgH7sg17oryuwfAAAAAElFTkSuQmCC"
          ></image>
        </g>
      </g>
    </svg>
  );
};
const host = [
  {
    name: "Dashboard",
    link: "",
    to: '/',
    image: (opacity) => <Dashboard opacity={opacity} />,
  },
  {
    name: "Bookings",
    link: "bookings",
    to: '/bookings',
    image: (opacity) => <Bookings opacity={opacity} />,
  },
  {
    name: "Parkings",
    link: "parkings",
    to: '/parkings',
    image: (opacity) => <Parkings opacity={opacity} />,
  },
  {
    name: "Earnings",
    link: "earnings",
    to: '/earnings',
    image: (opacity) => <Earnings opacity={opacity} />,
  },
];

const admin = [
  {
    name: "Dashboard",
    link: "",
    to: '/',
    image: (opacity) => <Dashboard opacity={opacity} />,
  },
  {
    name: "Users",
    link: "users",
    to: '/users',
    image: (opacity) => <Users opacity={opacity} />,
  },
  {
    name: "Hosts",
    link: "hosts",
    to: '/hosts',
    image: (opacity) => <Parkings opacity={opacity} />,
  },
  {
    name: "Bookings",
    link: "bookings",
    to: '/bookings',
    image: (opacity) => <Bookings opacity={opacity} />,
  },
  {
    name: "Events",
    link: "events",
    to: '/events',
    image: (opacity) => <Events opacity={opacity} />,
  },
  {
    name: "Spot Codes",
    link: "ttprequests",
    to: '/ttprequests',
    image: (opacity) => <Parkings opacity={opacity} />,
  },
  {
    name: 'Withdrawals',
    link: 'withdrawals',
    to: '/withdrawals',
    image: (opacity) => <Earnings opacity={opacity} />,
  },
  {
    name: "Settings",
    link: "settings",
    to: "/settings",
    image: (opacity) => <Settings opacity={opacity} />,
  },
  {
      name: "Integrations",
      link: "integrations",
      to: "/integrations",
      image: (opacity) => <SideBarLogo src={IntegrationsLogo} />,
  }
];

const List = (props) => {
  return props.arr.map((item, index) => {
    return (
      <div
        key={index}
        className={`normal-bar ${props.pathname === item.link ? "sidebar-active" : ""}`}>
        <Link
          to={item.to}
          className={`sidebar-links ${props.pathname === item.link ? "link-active" : ""
            }`} >
          <span className="font-m d-block mb-0 p-3">
            {item.image(props.pathname === item.link ? 1 : 0.5)}
            {item.name}{" "}
          </span>
        </Link>
      </div>
    );
  });
};

const Sidebar = withRouter((props) => {
  return (
    <div className="bg-white">
      <List
        pathname={props.location.pathname.split('/')[1]}
        arr={props.admin ? admin : host}
      />
    </div>
  );
});

export default Sidebar;

function SideBarLogo({ src, opacity }) {
  return <img className="mr-3" height={20} width={20} src={src} style={{ opacity }} alt="logo" />;
}
import React, { useMemo } from "react";
import { DataTable } from "../shared/table/customcomponent";
import {integrationsColDef, renderStatusOption} from "./config";
import { useGetAllEnforcementsQuery, useGetCountEnforcementsQuery } from "../../reduxModules/v3/integrations/enforcements";
import { Paginator, usePaginator } from "../shared/pagination";
import { FilterByParkingArea } from "../Filters/byParkingArea";
import { FilterByDateRange, GLOBAL_FIELD_FILTER_BY_DATE_RANGE, useDateRangeOptions } from "../Filters/byDateRange";
import { SearchBox } from "../Filters/SearchBox";
import { useFieldValue } from "../../reduxModules/tools/fieldValue";
import { ExportButton } from "../shared/export";
import { EnforcementStatus } from "../parkings/CustomizeAvailability/constants";
import Select from "react-select";
export default function Integrations() {
  const [searchText] = useFieldValue('field-search-enforcements');
  const [,statusIndices] = useFieldValue('field-filter-by-enforcement-status', [3]);
  const [,dateFilter] = useFieldValue(GLOBAL_FIELD_FILTER_BY_DATE_RANGE, useDateRangeOptions()[0]);
  const [,parkingsFilter] = useFieldValue('field-filter-by-parking-areas',[])
  const {data:counts} = useGetCountEnforcementsQuery({
    search: searchText,
    parkingIds: parkingsFilter.length ? parkingsFilter : null,
    dateRange: dateFilter.value,
    status: statusIndices.map(i => EnforcementStatus[i].value)
  })
  const pagination = usePaginator({total: counts?.count, id: 'field-pagination-enforcements'}, [parkingsFilter, dateFilter, statusIndices, searchText])
  const {isFetching, data: enforcements = []} = useGetAllEnforcementsQuery({
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
    search: searchText,
    parkingIds: parkingsFilter.length ? parkingsFilter : null,
    dateRange: dateFilter.value,
    status: statusIndices.map(i => EnforcementStatus[i].value)
  })
  return (
    <section>
      <header className="d-flex justify-content-between align-items-end mb-5 position-relative">
                 <FilterByParkingArea />
                 <FilterByDateRange />
                </header>
      <div className="global_container_card section_wrap">
      <div className="d-flex justify-content-between align-items-center">
              <p className="font-m p-4 semi-bold primary-text-color">Integrations</p>
              <ExportButton
                              className={'mx-4'}
                              service={'enforcements'}
                              params={{
                                search: searchText,
                                status: statusIndices.map(i => EnforcementStatus[i].value),
                                date_range: dateFilter.value
                              }}
                          />
      </div>
                    <div className="px-4">
                    <header className="d-flex justify-content-between align-items-center position-relative">
                        <ul className="nav nav-tabs mt-4 mb-5">
                            <li className="nav-item">
                                <button className={`btn nav_btn nav_btn_left btn-outline-success`}>
                                    Enforcements {countString()}
                                </button>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center">
                          <FilterByEnforcementStatus />
                          <SearchBox id="field-search-enforcements" placeholder="Search all parameters" />
                        </div>
                        </header>
                    </div>
        <DataTable
            colDefs={integrationsColDef}
            rows={enforcements}
            loading={isFetching}
          />
            <Paginator {...pagination} />
        </div>
    </section>
  );
  function countString() {
      return counts?.count ? `(${counts.count})` : ''
}
}
function FilterByEnforcementStatus() {
  const statusOptions = useMemo(() => EnforcementStatus.map(s=> renderStatusOption(s.value)).map((option, index) => ({...option, index})),[])
  const [,statusIndex, setStatusIndex] = useFieldValue('field-filter-by-enforcement-status', [3]);
  return<Select
          placeholder="No Status Filter"
          styles={getStyles()}
          isMulti={true}
          value={statusOptions[statusIndex]}
          isOptionSelected={(option) => option.value === statusOptions[statusIndex].value}
          style={{width:'fit-content'}}
          options={statusOptions}
          className="select" 
          onChange={(selected) => { setStatusIndex(selected.map(x=>x.index))}}
      ></Select>
      function getStyles() {
        return {
            control: (provided, state) => ({
                ...provided,
                border: 'none',
                boxShadow: 'none',
                backgroundColor: 'transparent',
            })
        }
    }
}
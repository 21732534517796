const devEnvironment = {
  // API_URL: "http://localhost:7853/api/v1",
  API_URL: "https://api.parkpoolr.o.fountane.xyz/api/v1",
  // API_URL_V2: "http://localhost:7853/api/v2",
  API_URL_V2: "https://api.parkpoolr.o.fountane.xyz/api/v2",
  // API_URL_V3: "http://localhost:7853/api/v3",
  API_URL_V3: "https://api.parkpoolr.o.fountane.xyz/api/v3",
  // API_URL : "https://b7a9-115-96-55-7.ngrok.io/api/v1",
  firebase: {
    continueURL: "https://parkpoolr-host.firebaseapp.com",
    emailVerifyActionGenerator(firstName, lastName, mobile, email, idToken) {
      return `${this.continueURL}/verify?verifying=true&firstName=${firstName}&lastName=${lastName}&phone=${mobile}&email=${email}&token=${idToken}`;
    },
    config: {
      apiKey: "AIzaSyB3c6AbZzaq6v40ExEEDY22ixRtyDlnyjQ",
      authDomain: "parkpoolr-host.firebaseapp.com",
      databaseURL:
        "https://parkpoolr-host-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "parkpoolr-host",
      storageBucket: "parkpoolr-host.appspot.com",
      messagingSenderId: "709725026812",
      appId: "1:709725026812:web:0e9607b5c62afe47cb9a79",
    },
  },
};
const prodEnvironment = {
  API_URL: "https://api.parkpoolr.com/api/v1",
  API_URL_V2: "https://api.parkpoolr.com/api/v2",
  API_URL_V3: "https://api.parkpoolr.com/api/v3",
  firebase: {
    continueURL: "https://host.parkpoolr.com",
    emailVerifyActionGenerator(firstName, lastName, mobile, email, idToken) {
      return `https://host.parkpoolr.com/verify?verifying=true&firstName=${firstName}&lastName=${lastName}&phone=${mobile}&email=${email}&token=${idToken}`;
    },
    config: {
      apiKey: "AIzaSyBlDXnexUQcY-ki36WzGVUsnFnOOnk2hVA",
      authDomain: "host.parkpoolr.com",
      databaseURL: "https://parkpoolr.firebaseio.com",
      projectId: "parkpoolr",
      storageBucket: "parkpoolr.appspot.com",
      messagingSenderId: "979781371795",
      appId: "1:979781371795:web:c754c65a9feff7b6b6ca66",
    },
  },
};

const isProd =
  process.env.REACT_APP_ENVIRONEMNT === "production" ? true : false;

const config = isProd ? prodEnvironment : devEnvironment;

export default config;

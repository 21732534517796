import api from "../config/api";
import Moment from "moment";
export const GET_ACTIVE_BOOKINGS = "GET_ACTIVE_BOOKINGS";
export const GOT_ACTIVE_BOOKINGS_ERROR = "GOT_ACTIVE_BOOKINGS_ERROR";
export const GOT_ACTIVE_BOOKINGS_SUCCESS = "GOT_ACTIVE_BOOKINGS_SUCCESS";
export const GET_PAST_BOOKINGS = "GET_PAST_BOOKINGS";

export const GOT_PAST_BOOKINGS_ERROR = "GOT_PAST_BOOKINGS_ERROR";
export const GOT_PAST_BOOKINGS_SUCCESS = "GOT_PAST_BOOKINGS_SUCCESS";

export const GET_UPCOMING_BOOKINGS = "GET_UPCOMING_BOOKINGS";
export const GOT_UPCOMING_BOOKINGS_ERROR = "GOT_UPCOMING_BOOKINGS_ERROR";
export const GOT_UPCOMING_BOOKINGS_SUCCESS = "GOT_UPCOMING_BOOKINGS_SUCCESS";

export const GET_CANCELLED_BOOKINGS = "GET_CANCELLED_BOOKINGS";
export const GOT_CANCELLED_BOOKINGS_ERROR = "GOT_CANCELLED_BOOKINGS_ERROR";
export const GOT_CANCELLED_BOOKINGS_SUCCESS = "GOT_CANCELLED_BOOKINGS_SUCCESS";
export const RESET_BOOKINGS = "RESET_BOOKINGS";
/*
BOOKINGS ARE I GUESS USER SPECIFIC, 
SO FOR EACH login_id we will map each booking like this
*/

export const clearBookings = () => {
  return {
    type: RESET_BOOKINGS,
  };
};

export const getUserBookings = (token, userId) => {
  return (dispatch) => {
    dispatch(getBookings(token, null, userId));
  };
};

export const getBookings = (
  token,
  hostId = null,
  userId = null,
  pageNumber = null,
  itemsPerPage = null,
  searchTerm = null
) => {
  return (dispatch) => {
    dispatch({
      type: GET_ACTIVE_BOOKINGS,
    });
    dispatch({
      type: GET_PAST_BOOKINGS,
    });
    dispatch({
      type: GET_UPCOMING_BOOKINGS,
    });
    dispatch({
      type: GET_CANCELLED_BOOKINGS,
    });

    const queryParams = {};
    if (hostId) {
      queryParams.host_id = hostId;
    }
    if (userId) {
      queryParams.user_id = userId;
    }
    if (pageNumber !== undefined && pageNumber !== null && itemsPerPage) {
      queryParams.offset = (pageNumber - 1) * itemsPerPage;
    }
    if (itemsPerPage) {
      queryParams.limit = itemsPerPage;
    }
    if (searchTerm) {
      queryParams.search_term = searchTerm;
    }

    const uri = `/bookings/paged`;

    api
      .get(uri, {
        headers: {
          "X-AUTH-TOKEN": token,
        },
        params: queryParams,
      })
      .then(({ data }) => {
        // console.log("all bookings");
        // console.log(data);

        if (!data || !data.result || !data.result.bookings) {
          dispatch({
            type: GOT_ACTIVE_BOOKINGS_ERROR,
          });
          dispatch({
            type: GOT_CANCELLED_BOOKINGS_ERROR,
          });
          dispatch({
            type: GOT_PAST_BOOKINGS_ERROR,
          });
          dispatch({
            type: GOT_UPCOMING_BOOKINGS_ERROR,
          });
          return;
        }
        const {
          currentBookings,
          pastBookings,
          upcomingBookings,
          pastBookingsCount,
          canceledBookingsCount,
          currentBookingCount,
          upcomingBookingsCount,
        } = data.result.bookings;

        let canceledBookings = [];
        if (
          data.result.bookings.canceledBookings ||
          data.result.bookings.cancledBooking
        ) {
          canceledBookings =
            data.result.bookings.canceledBookings ||
            data.result.bookings.cancledBooking;
        }

        dispatch({
          type: GOT_ACTIVE_BOOKINGS_SUCCESS,
          payload: {
            bookings: currentBookings,
            currentBookingCount,
          },
        });
        dispatch({
          type: GOT_CANCELLED_BOOKINGS_SUCCESS,
          payload: {
            bookings: canceledBookings,
            canceledBookingsCount,
          },
        });

        dispatch({
          type: GOT_PAST_BOOKINGS_SUCCESS,
          payload: {
            bookings: pastBookings,
            pastBookingsCount,
          },
        });
        dispatch({
          type: GOT_UPCOMING_BOOKINGS_SUCCESS,
          payload: {
            bookings: upcomingBookings,
            upcomingBookingsCount,
          },
        });
      })
      .catch((err) => {
        console.error("ERROR ocurred while fetching bookings", err);
        dispatch({
          type: GOT_ACTIVE_BOOKINGS_ERROR,
        });
        dispatch({
          type: GOT_CANCELLED_BOOKINGS_ERROR,
        });
        dispatch({
          type: GOT_PAST_BOOKINGS_ERROR,
        });
        dispatch({
          type: GOT_UPCOMING_BOOKINGS_ERROR,
        });
      });
  };
};

export const formatTheDataArray = (data) => {
  let dataHolder = {};
  for (let d of data) {
    if (d.valid_from && d.valid_to) {
      d.from_to_date =
        Moment(d.valid_from).format("MM/DD/YYYY") +
        " - " +
        Moment(d.valid_to).format("MM/DD/YYYY");
      d.start_time = Moment(d.valid_from).format("h:mm A");
      d.end_time = Moment(d.valid_to).format("h:mm A");
      d.duration = getTimeDifference(d.valid_from, d.valid_to);
      d.paidText = `$ ${(Number(d.amount) / 100).toFixed(2)}`;
      d.paid = (Number(d.amount) / 100).toFixed(2);
      d.transaction_amount_to_host_text = `$ ${(
        Number(d.transaction_amount_to_host) / 100
      ).toFixed(2)}`;
    }
    dataHolder[d.id] = { ...d };
  }
  return dataHolder;
};

export const getTimeDifference = (valid_from, valid_to) => {
  //both are made by vishnu only //

  // this is new code //

  let a = Moment(Number(new Date(valid_to)) + 60000);
  let b = Moment(new Date(valid_from));
  let h = a.diff(b, "hours"); // 44700
  let m = a.diff(b, "minutes");
  let d = a.diff(b, "days");

  let result = { day: 0, hr: 0, min: 0 };

  result.hr = h;
  result.min = m - h * 60;

  let combining = "";
  if (result.day) {
    combining += `${result.day}Days`;
    if (result.day > 1) {
      combining += "s";
    }
  }
  if (result.hr) {
    combining += ` ${result.hr}Hr`;
    if (result.hr > 1) {
      combining += "s";
    }
  }
  combining += ` ${result.min}Min`;
  if (result.min > 1) {
    combining += "s";
  }
  return combining;
};

const activeBookingsInitState = {
  total_active_bookings: 0,
  loading: false,
  error: null,
  bookings: {},
};
export const activeBookingReducer = (
  state = activeBookingsInitState,
  action
) => {
  switch (action.type) {
    case GET_ACTIVE_BOOKINGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GOT_ACTIVE_BOOKINGS_SUCCESS:
      let bookings = formatTheDataArray(action.payload.bookings);
      return {
        ...state,
        bookings,
        total_active_bookings:
          action.payload.currentBookingCount || Object.keys(bookings).length,
        loading: false,
        error: null,
      };
    case GOT_ACTIVE_BOOKINGS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case RESET_BOOKINGS:
      return activeBookingsInitState;
    default:
      return state;
  }
};

const upcomingBookingsInitState = {
  total_upcoming_bookings: 0,
  loading: false,
  error: null,
  bookings: {},
};
export const upcomingBookingReducer = (
  state = upcomingBookingsInitState,
  action
) => {
  switch (action.type) {
    case GET_UPCOMING_BOOKINGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GOT_UPCOMING_BOOKINGS_SUCCESS:
      let bookings = formatTheDataArray(action.payload.bookings);
      return {
        ...state,
        bookings,
        total_upcoming_bookings:
          action.payload.upcomingBookingsCount || Object.keys(bookings).length,
        loading: false,
        error: null,
      };
    case GOT_UPCOMING_BOOKINGS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case RESET_BOOKINGS:
      return upcomingBookingsInitState;
    default:
      return state;
  }
};

const cancelledBookingsInitState = {
  total_cancelled_bookings: 0,
  loading: false,
  error: null,
  bookings: {},
};
export const cancelledBookingReducer = (
  state = cancelledBookingsInitState,
  action
) => {
  switch (action.type) {
    case GET_CANCELLED_BOOKINGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GOT_CANCELLED_BOOKINGS_SUCCESS:
      let bookings = formatTheDataArray(action.payload.bookings);
      return {
        ...state,
        bookings,
        total_cancelled_bookings:
          action.payload.canceledBookingsCount || Object.keys(bookings).length,
        loading: false,
        error: null,
      };
    case GOT_CANCELLED_BOOKINGS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case RESET_BOOKINGS:
      return cancelledBookingsInitState;
    default:
      return state;
  }
};

const pastBookingsInitState = {
  total_past_bookings: 0,
  loading: false,
  error: null,
  bookings: {},
};
export const pastBookingReducer = (state = pastBookingsInitState, action) => {
  switch (action.type) {
    case GET_PAST_BOOKINGS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GOT_PAST_BOOKINGS_SUCCESS:
      let bookings = formatTheDataArray(action.payload.bookings);
      return {
        ...state,
        bookings,
        total_past_bookings:
          action.payload.pastBookingsCount || Object.keys(bookings).length,
        loading: false,
        error: null,
      };
    case GOT_PAST_BOOKINGS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case RESET_BOOKINGS:
      return pastBookingsInitState;
    default:
      return state;
  }
};

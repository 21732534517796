import React from "react";
import {
  FormattedDate,
  GROUP_BY_FIELD_ID,
  formatCurrency,
  formatNumber,
  ParkingsList,
  TransactionCount,
} from "../common";
import { useModalView } from "../../../../shared/Modal";
import { useFieldValue } from "../../../../../reduxModules/tools/fieldValue";
import { useCreateWithdrawalMutation } from "../../../../../reduxModules/v3/transactions/withdrawals";
import { toast } from "react-toastify/dist/react-toastify";
export const creditedColDef = [
  {
    label: "Date",
    key: "date",
    component: FormattedDate,
  },
  {
    label: "Wallet Amount",
    key: "amount_to_host",
    component: ({ amount_to_host }) => (
      <p>{formatCurrency(amount_to_host / 100)}</p>
    ),
  },
  {
    label: "Parkings",
    key: "area_ids",
    component: (props) => <ParkingsList {...props} />,
  },
  {
    label: "Transactions",
    key: "transaction_count",
    component: props => <TransactionCount {...props} type="EARNING" status="PAID" />
  },
  {
    component: WithdrawButton
  },
];
// private functions
function WithdrawButton(data) {
    const { show, hide } = useModalView();
    return (
      <button
        onClick={() => show({ children: <ModalContent /> })}
        type="button"
        className="btn btn-success action"
      >
        Request Withdrawal
      </button>
    );

    // private functions
    function ModalContent() {
      const [, groupBy] = useFieldValue(...GROUP_BY_FIELD_ID);
      const [withdrawTrigger, { isLoading }] = useCreateWithdrawalMutation();
      return (
        <>
          <div className="modal-header">
            <h5 className="modal-title">
              Confirm withdrawal request for {data.parking_ids.length} parkings
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th>Date</th>
                  <td>
                    <FormattedDate date={data.date} />
                  </td>
                </tr>
                <tr>
                  <th>Amount to Withdraw</th>
                  <td>{formatCurrency(data.amount_to_host / 100)}</td>
                </tr>
                <tr>
                  <th>Transactions count</th>
                  <td>{formatNumber(data.transaction_count)}</td>
                </tr>
                <tr>
                <th className="align-middle">Parkings ({data.parking_ids.length})</th>
                  <td>
                    <ParkingsList {...data} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              disabled={isLoading}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
              <button
              disabled={isLoading}
                type="button"
                className="btn btn-success action"
                onClick={() => {
                  withdraw();
                }}
              >
                Request Withdrawal
              </button>
          </div>
        </>
      );
      async function withdraw() {
        const params = {
          date: [data.date],
          host_ids: data.host_ids,
          parking_ids: data.parking_ids,
          date_group_by: groupBy,
        };
        await toast.promise(withdrawTrigger(params).unwrap(), {
          pending: `Withdrawing ${formatCurrency(
            data.amount_to_host / 100
          )} for ${data.transaction_count} transactions`,
          success: {
            render({ data:{result} }) {
              return (
                <>
                  <p>
                    Successfully requested withdrawal of {formatCurrency(
                      result.withdrawals.amount_to_host / 100
                    )} for {data.transaction_count} transactions
                  </p>
                  </>
              )
            }
          },
          error: `Failed to withdraw ${formatCurrency(
            data.amount_to_host / 100
          )} for ${data.transaction_count} transactions`,
        });
        hide();
      }
    }
  }
import React from "react";
import { FilterByDateRange } from "../../Filters/byDateRange";
import { ExportButton } from "../../shared/export";
import ErrorBoundary from "../../shared/errorBoundaries";
import { FilterByHosts, GLOBAL_FIELD_FILTER_BY_HOSTS } from "../../Filters/byHosts";
import { DataTable } from "../../shared/table/customcomponent";
// import { WithdrawConfirmModal } from "./config/admin/credited";
import { useGetWithdrawalsAmountQuery, useGetWithdrawalsQuery } from "../../../reduxModules/v3/transactions/withdrawals";
import { useGetEarningsInWalletAmountQuery, useGetEarningsInWalletQuery } from "../../../reduxModules/v3/transactions/earnings";
import { GROUP_BY_FIELD_ID, GroupBy, formatCurrency } from "./config/common";
import { useFieldValue } from "../../../reduxModules/tools/fieldValue";
import { Paginator, usePaginator } from "../../shared/pagination";
import { useSelector } from "react-redux";
import { FilterByParkingArea } from "../../Filters/byParkingArea";
import { tableLabels, useColDef } from "./config";
export const WITHDRAWALS_FILTER_BY_DATE_RANGE = 'field-filter-by-date-range-withdrawals'
export  function Withdrawals(props) {
    const {currentTab, setTab, colDef} = useWithdrawalTabs()
    const useRole = useSelector(state => state.profile.role)
    const {data, amount} = useData();
    const pagination = usePaginator({
        id: `field-pagination-withdrawals-${currentTab}`,
        total: data[currentTab].data?.count, 
      })
    return <section>
    <header className="d-flex justify-content-between align-items-center mb-5 position-relative">
      {
        useRole === 'admin' 
          ?<FilterByHosts placeholder="All Hosts" />
          : <FilterByParkingArea />
      }
      <FilterByDateRange id={WITHDRAWALS_FILTER_BY_DATE_RANGE} />
    </header>

      <div className="section_wrap">
        <div className="mt-4 p-4 bg-white global_container_card">
          <p className="font-l semi-bold">{ useRole === 'admin' ? 'Host Withdrawals' : 'Earnings' } </p>
          <span className="d-flex align-items-baseline justify-content-between flex-row">
              <ul className="nav nav-tabs mt-3 mb-5 earnings_tabs">
                <li className="nav-item">
                  <button
                    className={`btn nav_btn nav_btn_left btn-outline-${ currentTab === 'credited'? "success" : "secondary"
                      }`}
                    onClick={() => {
                      setTab('credited')
                    }}
                  >
                    {tableLabels.credited} {renderCount('credited')}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`btn nav_btn btn-outline-${ currentTab === 'pending'? "success" : "secondary"
                      }`}
                    onClick={() => setTab('pending')}
                  >
                    {tableLabels.pending} {renderCount('pending')}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`btn nav_btn nav_btn_right btn-outline-${ currentTab === 'withdrawn'? "success" : "secondary "
                      }`}
                    onClick={() => {
                      setTab('withdrawn')
                    }}
                  >
                    {tableLabels.withdrawn} {renderCount('withdrawn')}
                  </button>
                </li>
              </ul>
              <span className="row align-items-center">
              <GroupBy />
              <WithdrawalExportButton/>
              </span>

            </span>
          <ErrorBoundary>
           <DataTable 
                rows = {data[currentTab].data?.data}
                colDefs={colDef}
                loading={data[currentTab].isFetching}
           />
           <Paginator
           {...pagination}
            />
           {/* <HostInfoModal /> */}
          </ErrorBoundary>
        </div>
      </div>
    </section>
    function useWithdrawalTabs() {
        const colDefsMap =  useColDef()
        const [currentTab, setTab] = React.useState(props.tab ? props.tab : Object.keys(colDefsMap)[0])
        return {
            currentTab,
            setTab,
            colDef: colDefsMap[currentTab],
        }
    }
    function useData() {
        const [,groupBy] = useFieldValue(...GROUP_BY_FIELD_ID)
        const [,parkingsFilter] = useFieldValue('field-filter-by-parking-areas',[])
        const [,hostFilter] = useFieldValue(GLOBAL_FIELD_FILTER_BY_HOSTS,[])
        const [,dateFilter] = useFieldValue(WITHDRAWALS_FILTER_BY_DATE_RANGE)
        const {pageNumber,pageSize} = usePaginator({id: `field-pagination-withdrawals-${currentTab}`},[groupBy, dateFilter, hostFilter])
        const countsParams = {
          host_ids: hostFilter,
          parking_ids: parkingsFilter,
          date_range: dateFilter?.value
        }
        const dataParams = {
          ...countsParams,
          date_group_by: groupBy,
          parking_ids: parkingsFilter,
          status: getStatus(),
          limit: pageSize,
          offset: (pageNumber - 1) * pageSize,
        }
        const earnings = useGetEarningsInWalletQuery(dataParams, { skip: currentTab !== 'credited' });
        const pending = useGetWithdrawalsQuery(dataParams, { skip: currentTab !== 'pending' });
        const withdrawals = useGetWithdrawalsQuery(dataParams, { skip: currentTab !== 'withdrawn' });
        const withdrawalsAmount = useGetWithdrawalsAmountQuery(countsParams);
        const earningsAmount = useGetEarningsInWalletAmountQuery(countsParams);
      return {
        amount:{
          ...withdrawalsAmount.data,
          credited: earningsAmount.data?.earnings,
        },
        data: {
          credited: earnings,
          pending: pending,
          withdrawn: withdrawals
      }
    }
      function getStatus() {
        switch (currentTab) {
          case 'pending': return 'PROCESSING';
          case 'withdrawn': return 'PAID';
          default: return null;
        }
      }
    }
    function renderCount(tab) {
      if(typeof amount?.[tab] === 'undefined') return null;
      return `(${formatCurrency(amount[tab] / 100)})`
    }
    function WithdrawalExportButton() {
      const [,host_ids] = useFieldValue(GLOBAL_FIELD_FILTER_BY_HOSTS)
      const [,dateFilter] = useFieldValue(WITHDRAWALS_FILTER_BY_DATE_RANGE)
      const [,parkingsFilter] = useFieldValue('field-filter-by-parking-areas',[])
      return <ExportButton
      className='ml-4 mr-4'
      service={"transactions"}
      nameTags={['withdrawals',tableLabels[currentTab], formatCurrency(amount?.[currentTab]/100)]}
      params={{ 
        host_ids,
        parking_ids: parkingsFilter,
        date_range: dateFilter?.value,
        type:currentTab === 'credited' ? 'EARNING' : 'WITHDRAWN',
        status:currentTab  === 'pending' ? 'PROCESSING' : 'PAID',
      }}
      label={`Export ${tableLabels[currentTab]}`}
    />
    }
}
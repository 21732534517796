import api from "../../config/api";
import {formatTheEventData} from "../events";


const DELETING_EVENT = 'DELETING_EVENT';

const AUTO_FILL_EVENT = "AUTO_FILL_EVENT";
const GETTING_EVENTS = 'GETTING_EVENTS';
const GOT_EVENTS_SUCCESS = 'GOT_EVENTS_SUCCESS';
const GOT_EVENTS_ERROR = 'GOT_EVENTS_ERROR';

const GOT_ACTIVE_EVENTS = 'GOT_ACTIVE_EVENTS';
const GOT_UPCOMING_EVENTS = 'GOT_UPCOMING_EVENTS';
const GOT_PAST_EVENTS = 'GOT_PAST_EVENTS';

const SET_EVENT_NAME = 'SET_EVENT_NAME';
const SET_EVENT_TYPE = 'SET_EVENT_TYPE';
const SET_EVENT_LOCATION_STREET = "SET_EVENT_LOCATION_STREET";
const SET_EVENT_LOCATION_CITY = 'SET_EVENT_LOCATION_CITY';
const SET_EVENT_LOCATION_STATE = 'SET_EVENT_LOCATION_STATE';
const SET_EVENT_LOCATION_COUNTRY = 'SET_EVENT_LOCATION_COUNTRY';
const SET_EVENT_LOCATION_PINCODE = 'SET_EVENT_LOCATION_PINCODE';
const SET_EVENT_VENUE = 'SET_EVENT_VENUE';
const SET_EVENT_DATE = 'SET_EVENT_DATE';
const SET_EVENT_TIME_IN = 'SET_EVENT_TIME_IN';
const SET_EVENT_TIME_OUT = 'SET_EVENT_TIME_OUT';

const MAKING_NEW_EVENT = "MAKING_NEW_EVENT";
const MADE_NEW_EVENT = "MADE_NEW_EVENT";
const COULDNT_MAKE_NEW_EVENT = "COULDNT_MAKE_NEW_EVENT";
const INITIALIZE_SUCCESS = 'INITIALIZE_SUCCESS';
const CLEAR_EVENT_FIELDS = 'CLEAR_EVENT_FIELDS';

const EVENT_REMOVE_IMAGE = 'EVENT_REMOVE_IMAGE';
const DELETE_EVENT_FAILED = "DELETE_EVENT_FAILED";
const GOT_PARKING_EVENTS = "GOT_PARKING_EVENTS";

const GET_ACTIVE_EVENTS = 'GET_ACTIVE_EVENTS';
const GET_UPCOMING_EVENTS = 'GET_UPCOMING_EVENTS';
const GET_PAST_EVENTS = 'GET_PAST_EVENTS';

const GOT_ACTIVE_EVENTS_ERROR = 'GOT_ACTIVE_EVENTS_ERROR';
const GOT_UPCOMING_EVENTS_ERROR = 'GOT_UPCOMING_EVENTS_ERROR';
const GOT_PAST_EVENTS_ERROR = 'GOT_PAST_EVENTS_ERROR';

const GOT_ACTIVE_EVENTS_SUCCESS = 'GOT_ACTIVE_EVENTS_SUCCESS';
const GOT_UPCOMING_EVENTS_SUCCESS = 'GOT_UPCOMING_EVENTS_SUCCESS';
const GOT_PAST_EVENTS_SUCCESS = 'GOT_PAST_EVENTS_SUCCESS';

const DELETED_ACTIVE_EVENT = 'DELETED_ACTIVE_EVENT';
const DELETED_UPCOMING_EVENT = 'DELETED_UPCOMING_EVENT';
const DELETED_PAST_EVENT = 'DELETED_PAST_EVENT';

export const EVENT_UPLOAD_FILE = 'EVENT_UPLOAD_FILE';


const eventTypes = {
    active: {
        start: GET_ACTIVE_EVENTS,
        finished: GOT_ACTIVE_EVENTS_SUCCESS,
        error: GOT_ACTIVE_EVENTS_ERROR,
        delete: DELETED_ACTIVE_EVENT,
    },
    upcoming: {
        start: GET_UPCOMING_EVENTS,
        finished: GOT_UPCOMING_EVENTS_SUCCESS,
        error: GOT_UPCOMING_EVENTS_ERROR,
        delete: DELETED_UPCOMING_EVENT,
    },
    past: {
        start: GET_PAST_EVENTS,
        finished: GOT_PAST_EVENTS_SUCCESS,
        error: GOT_PAST_EVENTS_ERROR,
        delete: DELETED_PAST_EVENT,
    }
}
/**
 *
 * @param {'past'|'current'|'upcoming'} eventType
 */

export const getEvents = (token, pageNumber = null, itemsPerPage = null, searchTerm = null) => {

    return dispatch => {
        dispatch({type: GET_ACTIVE_EVENTS});
        dispatch({type: GET_UPCOMING_EVENTS});
        dispatch({type: GET_PAST_EVENTS});
        const queryParams = {};
        if (pageNumber !== undefined && pageNumber !== null && itemsPerPage) {
            queryParams.offset = (pageNumber - 1) * itemsPerPage;
        }
        if (itemsPerPage) {
            queryParams.limit = itemsPerPage;
        }
        if (searchTerm) {
            queryParams.search_term = searchTerm;
        }

        const uri = "/events/paged";
        api.get(uri, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
            params: queryParams
        })
            .then(({data}) => {

                const {
                    upcomingEvents,
                    activeEvents,
                    pastEvents,
                    activeEventsCount,
                    pastEventsCount,
                    upcomingEventsCount
                } = data.result.events;
                dispatch({
                    type: GOT_ACTIVE_EVENTS_SUCCESS,
                    payload: {
                        data: activeEvents,
                        total: activeEventsCount
                    }
                });
                dispatch({
                    type: GOT_UPCOMING_EVENTS_SUCCESS,
                    payload: {
                        data: upcomingEvents,
                        total: upcomingEventsCount
                    }
                });
                dispatch({
                    type: GOT_PAST_EVENTS_SUCCESS,
                    payload: {
                        data: pastEvents,
                        total: pastEventsCount
                    }
                });
            })
            .catch(err => {
                console.error("ERROR RECEIVING EVENTS", err);
                dispatch({type: GOT_ACTIVE_EVENTS_ERROR});
                dispatch({type: GOT_UPCOMING_EVENTS_ERROR});
                dispatch({type: GOT_PAST_EVENTS_ERROR});
            });
    };
};

export const deleteEvent = (token, id) => {
    return dispatch => {
        dispatch({type: 'NOTI_DELETE_EVENT_START'});
        // TODO: delete status
        api.delete(`/events?deleteId=${id}`, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
        })
            .then(({data}) => {

                dispatch({type: "NOTI_DELETE_EVENT_SUCCESS"});
                // dispatch(getEvents(token));

            })
            .catch((error) => {
                console.log("ERROR RECEIVING EVENTS", error.response);
                error = error.response && error.response.data ? error.response.data : error;
                // TODO: use toast in future
                dispatch({
                    type: 'NOTI_DELETE_EVENT_ERROR',
                    payload: {
                        errorMessage: error.info ? error.info : error.error && error.error.info ? error.error.info : error.error && error.error.message ? error.error.message : 'Delete event failed!'
                    }
                })
            });
    };
};


/**
 *
 * @param {'past'|'current'|'upcoming'} eventType
 */
export const createEventsReducer = (eventsType) => {
    /**
     *
     * @type {{loading: boolean, error: boolean}|{}|{loading: boolean, error: boolean, bookings:{data: [], total: number}}}
     */
    const defaultEventsState = {
        type: eventsType,
        loading: false,
        error: false,
        stale: true,
        events: {
            data: {},
            total: 0
        }
    }

    function eventsReducer(state = defaultEventsState, action) {
        const {start: startActionType, error: errorActionType, finished: finishedActionType, delete: deleteActionType } = eventTypes[eventsType];
        switch (action.type) {
            case startActionType:
                return {
                    ...state,
                    loading: true,
                    stale: true,
                    error: false,
                };
            case errorActionType:
                return {
                    ...state,
                    loading: false,
                    error: true,
                    stale: true,
                    events: {
                        data: {},
                        total: 0
                    }
                };
            case finishedActionType:
                return {
                    ...state,
                    loading: false,
                    error: false,
                    stale: false,
                    events: {
                        data: formatTheEventData(action.payload.data),
                        total: action.payload.total
                    }
                };
            case deleteActionType:
                const {[action.payload.id]:deleted, ...rest} = state.events.data;
                return {
                    ...state,
                    events: {
                        data: rest,
                        total: state.events.total - 1
                    }
                }
            default:
                return state;
        }
    }

    return eventsReducer;
}
export default getEvents;

import React from "react";
import "./style.scss";
const Uploader = (props) => {
  let renderUploadedImage = <></>;

  if (Array.isArray(props.img)) {
    renderUploadedImage = props.img
      .filter((x) => x)
      .map((im, index) => {
        return (
          <div className="pos_rel mr-3 mb-3" key={index + "ss"}>
            <i
              className="fa fa-close close-icon pointer"
              onClick={() => props.deleteFile(index, im)}
            ></i>
            <img
              alt="Uploaded in"
              src={im}
              className="uploaded_img"
              key={index}
            />
          </div>
        );
      });
  } else if (props.img && props.img.length) {
    renderUploadedImage = (
      <div className="pos_rel mb-3 mr-3">
        {" "}
        <i
          className="fa fa-close close-icon pointer "
          onClick={() => props.deleteFile(1, props.img)}
        ></i>{" "}
        <img alt="Uploaded im" src={props.img} className="uploaded_img" />{" "}
      </div>
    );
  }

  return (
    <div className={props.colValue ? `col-md-${props.colValue}` : "col"}>
      <div className="custom-file">
        <label htmlFor={props.id} className="custom-file-label">
          <span>{props.label || "No File Selected"}</span>{" "}
        </label>
        <input
          disabled={props.disabled}
          accept="image/*"
          type="file"
          id={props.id}
          className="custom-file-input"
          multiple={props.multiple}
          onChange={props.uploadFileHandler}
        />
      </div>
      <div className="container pt-3">
        <div className="row">
          {renderUploadedImage}
          {/* {props.img && props.img.length ? (
            Array.isArray(props.img) ? (
              props.img.map((im, index) => {
                return (
                  <div className="pos_rel mr-3 mb-3" key={index + "ss"}>
                    <i
                      className="fa fa-close close-icon pointer"
                      onClick={() => props.deleteFile(index, im)}
                    ></i>
                    <img
                      alt="Uploaded in"
                      src={im}
                      className="uploaded_img"
                      key={index}
                    />
                  </div>
                );
              })
            ) : (
              <div className="pos_rel mb-3 mr-3">
                {" "}
                <i
                  className="fa fa-close close-icon pointer "
                  onClick={() => props.deleteFile(1, props.img)}
                ></i>{" "}
                <img
                  alt="Uploaded im"
                  src={props.img}
                  className="uploaded_img"
                />{" "}
              </div>
            )
          ) : null} */}
          {props.defaultImage && props.defaultImage.length ? (
            Array.isArray(props.defaultImage) ? (
              props.defaultImage.map((dim, index) => {
                return (
                  <div className="pos_rel mb-3 mr-3" key={index + "mm"}>
                    <i
                      className="fa fa-close close-icon pointer"
                      onClick={() => props.deleteFile(index, dim)}
                    ></i>
                    <img
                      alt="defaulted"
                      src={dim}
                      className="uploaded_img"
                      key={index}
                    />
                  </div>
                );
              })
            ) : (
              <div className="pos_rel mb-3 mr-3">
                {" "}
                <i
                  className="fa fa-close close-icon pointer"
                  onClick={() => props.deleteFile(1, props.defaultImage)}
                >
                  {" "}
                </i>{" "}
                <img
                  alt="Uploaded im"
                  src={props.defaultImage}
                  className="uploaded_img"
                />{" "}
              </div>
            )
          ) : null}
        </div>
      </div>
      <div className="container">
        <p className="text-muted">
          {props.progress === 0 ? null : props.progress + "%"}
        </p>
        <small className="text-danger">
          {props.error ? props.error : null}
        </small>
      </div>
    </div>
  );
};

export default Uploader;

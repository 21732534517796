import React from 'react';
import './style.scss'

export default props => {
    return (
        <div className={props.colValue ? 'col-' + props.colValue : 'col'}>
            <div className="form-group">
                <label htmlFor={props.id}>{props.label} {props.is_optional ? <span className="text-muted">(optional)</span> : null} </label>
                <input min={props.min === undefined ? null : props.min} readOnly={props.readonly || props.disabled} type={props.type ? props.type : 'text'} className="form-control global_input" id={props.id} defaultValue={props.value} onChange={props.onChange} placeholder={props.placeholder} />
                {
                    props.smallMsg ?
                        <small className={props.errorMsg ? 'text-danger' : 'small-message text-muted'}>{props.smallMsg}</small>
                        :
                        null
                }
            </div>
        </div>
    );
};
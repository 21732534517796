import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';

import { AdminRoute } from '../../App';
import Loading from '../shared/loading/spinner';

const SingleHost = lazy(() => import('./singleHostTable'));
const HostsTable = lazy(() => import('./hostsTable'));

const mapStateToProps = (state) => {
    return {
        role: state.profile.role,
        loggedIn: state.auth.loggedIn,
    };
};

export default connect(mapStateToProps)((props) => {

    const { match } = props;
    return (
        <React.Fragment>
            <AdminRoute role={props.role} loggedIn={props.loggedIn} exact path={`${match.url}`} render={({match}) => <HostsTable noRefresh={'back' in  match.params}/>} />
            <AdminRoute role={props.role} loggedIn={props.loggedIn} path={`${match.url}/info/:id`} render={({match}) => <SingleHost hostId={match.params.id}/>} />
        </React.Fragment>
    );
});

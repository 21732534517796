import reduxThunk from "redux-thunk";
import { combineReducers } from 'redux';
import AuthReducer, { otpReducer } from './auth';
import ProfileReducer, {
    uploadDocsReducer
} from './profile';
import {
    withdrawnEarningsReducer,
    creditedEarningsReducer,
} from './earnings';
import ParkingsReducer, {
    adminParkingsBasic,
    newParkingDetailsReducer,
    newParkingSlotReducer,
} from './parkings';
import Events, {
    activeEventsReducer,
    upcomingEventsReducer,
    pastEventsReducer,
    newEvent,
    deleteEventsReducer,
    parkingEventsReducer
} from './events';
import Hosts from './hosts';
import Users from './users';
import { verificationsReducer, verifyReducer } from './verifications';
import app from './app';
import settings from './settings';
import spotCodesReducer from './spotCodes';
import withdrawals, { uploadReceipt } from './withdrawals';
import reviews from './reviews';
import dashboardCountsReducer, 
{   dashboardChartBookingsReducer, 
    dashboardChartEarningsReducer, 
    dashboardChartUsersReducer 
} from './dashboard';
import {createReducerForBookingType} from "./bookings_v2";
import { currentlyEditingEventReducer, eventEditorStatusEventReducer} from "./events_v2/editor";
import {createEventsReducer} from "./events_v2";
import filterReducer from "./tools/filters";
import OverviewReducer from "./dashboard/host-overview";
import WithdrawalsReducer,{WithdrawalProcessReducer} from './earnings/withdrawals';
import { SelectionToolReducer } from './tools/selection';
import EarningsReducer from './earnings/earnings';
import { downloadReducer } from './tools/download';
import { configureStore } from "@reduxjs/toolkit";
import { fieldValueSlice } from "./tools/fieldValue";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseFetchQueryV3 } from "../config/api";
export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: baseFetchQueryV3(),
    endpoints: (builder) => ({})
})
const rootReducer = combineReducers({
    auth: AuthReducer,
    otp: otpReducer,
    profile: ProfileReducer,
    withdrawnEarnings: withdrawnEarningsReducer,
    creditedEarnings: creditedEarningsReducer,
    adminParkingsData: spotCodesReducer,
    parkings: ParkingsReducer,
    parkingsBasic: adminParkingsBasic,
    parkingDetails: newParkingDetailsReducer,
    slotDetails: newParkingSlotReducer,
    uploadDetails: uploadDocsReducer,
    bookings: combineReducers({
        current: createReducerForBookingType('current'),
        upcoming: createReducerForBookingType('upcoming'),
        cancelled: createReducerForBookingType('cancelled'),
        past: createReducerForBookingType('past'),
    }),
    events_v2: combineReducers({
        active: createEventsReducer('active'),
        upcoming: createEventsReducer('upcoming'),
        past: createEventsReducer('past'),
        eventEditor: combineReducers({
            status: eventEditorStatusEventReducer,
            current: currentlyEditingEventReducer
        })
    }),
    events: Events,
    deleteEventsReducer: deleteEventsReducer,
    activeEvents: activeEventsReducer,
    upcomingEvents: upcomingEventsReducer,
    pastEvents: pastEventsReducer,
    parkingEvents : parkingEventsReducer,
    newEvent,
    hosts: Hosts,
    users: Users,
    verifications: verificationsReducer,
    verifyParking: verifyReducer,
    app,
    settings,
    withdrawals,
    uploadReceipt,
    reviews,
    dashboardCounts:dashboardCountsReducer,// deprecated
    dashboard: combineReducers({
        counts: OverviewReducer
    }),
    earnings: combineReducers({
        withdrawals: WithdrawalsReducer,
        earnings: EarningsReducer,
        process: WithdrawalProcessReducer
    }),
    dashboardBookings: dashboardChartBookingsReducer,
    dashboardEarnings: dashboardChartEarningsReducer,
    dashboardUsers: dashboardChartUsersReducer,
    filters: filterReducer, // TODO moved to tools
    tools: combineReducers({
        filters: filterReducer,
        selection: SelectionToolReducer,
        download: downloadReducer,
        searchText: fieldValueSlice.reducer
    }),
    [fieldValueSlice.name]: fieldValueSlice.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
});

export default rootReducer;
export const getStore = () => configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        reduxThunk,
        baseApi.middleware,
        )
});
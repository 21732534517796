import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../lib/use-debounce";
import { useEffect, useState } from "react";

export const fieldValueSlice = createSlice({
    name: "fieldValueStore",
    initialState: {},
    reducers: {
        setValue: (state, action) => {
            if(!action.payload.value) {
                delete state[action.payload.id];
            } else {
                 state[action.payload.id] = action.payload.value
            }
        }
    }
});

/**
 * @usage const [debouncedValue, value, setValue] = useFieldValue('some-id');
 */
export const useFieldValue = (id,defaultValue) => {
    const [defaultValueMemo] = useState(defaultValue);
    const dispatch = useDispatch();
    const value = useSelector(state => state.fieldValueStore[id]);
    return [
        useDebounce(value, 450) || defaultValueMemo,
        value || defaultValueMemo,
        (value) => dispatch(fieldValueSlice.actions.setValue({ id, value})),
    ]
}
export const getFieldValue = (id, state) => state.fieldValueStore[fieldValueSlice.name][id];
import API, { api2 } from "../config/api";
const GET_EARNINGS_ERROR = 'GET_PARKINGS_ERROR';
const GET_WITHDRAWN_EARNINGS_SUCCESS = 'GET_WITHDRAWN_EARNINGS_SUCCESS';
const GET_CREDITED_EARNINGS_SUCCESS = 'GET_CREDITED_EARNINGS_SUCCESS';
const GETTING_EARNINGS = 'GETTING_EARNINGS';
const SELECT_ALL_CREDIT_EARNINGS = "SELECT_ALL_CREDIT_EARNINGS";

const withdrawnEarningsInitState = {
    count: 0,
    earnings: {},
    walletAmount: 0,
    loading: false,
    error: null,
};
export const withdrawnEarningsReducer = (state = withdrawnEarningsInitState, action) => {
    switch (action.type) {
        case GET_WITHDRAWN_EARNINGS_SUCCESS:
            return {
                loading: false,
                error: null,
                ...action.payload.withdrawnEarnings,
                earnings: action.payload.withdrawnEarnings,
                count: action.payload.count,
                walletAmount: action.payload.walletAmount
            };
        default:
            return state;
    }
}


const creditedEarningsInitState = {
    count: 0,
    earnings: {},
    walletAmount: 0,
    loading: false,
    error: null,
};
export const creditedEarningsReducer = (state = creditedEarningsInitState, action) => {
    switch (action.type) {
        case GET_CREDITED_EARNINGS_SUCCESS:
            return {
                ...state,
                ...action.payload.creditedEarnings,
                earnings: action.payload.creditedEarnings,
                count: action.payload.count,
                walletAmount: action.payload.walletAmount
            };


        case SELECT_ALL_CREDIT_EARNINGS:

            const _creditedEarnings = action.payload.earnings;
            let creditedEarningsObj = {};

            for (let earning of _creditedEarnings) {
                creditedEarningsObj[earning.id] = earning;
            }

            return {
                ...state,
                ...creditedEarningsObj
            };

        default:
            return state;
    }
};


export const selectAllCreditEarnings = (token) => {

    return async (dispatch) => {

        const queryParams = {
            offset: 0,
            limit: -1
        };

        const uri = `/earnings/paged`;
        const data = await API.get(uri, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
            params: queryParams
        });

        const _creditedEarnings = data.data.result.credited;

        dispatch({
            type: SELECT_ALL_CREDIT_EARNINGS,
            payload: {
                earnings: _creditedEarnings,
            },
        });
    }
}



export const getEarnings = (token, pageNumber = null, itemsPerPage = null, searchTerm = null) => {

    return async (dispatch, getState) => {
        dispatch({
            type: GETTING_EARNINGS,
        });
        const dateRange = getState().filters.date_filter.value?.map(Number) || [];
        const queryParams = {};
        if (pageNumber !== undefined && pageNumber !== null && itemsPerPage) {
            queryParams.offset = (pageNumber - 1) * itemsPerPage;
        }
        if (itemsPerPage) {
            queryParams.limit = itemsPerPage;
        }
        if (searchTerm) {
            queryParams.search_term = searchTerm;
        }
        if (dateRange && dateRange.length === 2) {
            queryParams.date_range= dateRange.join(",");
        }
        const uri = `/earnings`;
        try {
            const data = await api2.get(uri, {
                headers: {
                    'X-AUTH-TOKEN': token,
                },
                params: queryParams
            })

            const _creditedEarnings = data.result.credited;
            const _withdrawnEarnings = data.result.withdrawals;

            const creditedCount = data.result.creditedCount;
            const withdrawalsCount = data.result.withdrawalsCount;
            const walletAmount = data.result.walletAmount;

            let withdrawnEarnings = {}, creditedEarnings = {};
            for (let earning of _creditedEarnings) {
                creditedEarnings[earning.id] = earning;
            }
            for (let earning of _withdrawnEarnings) {
                withdrawnEarnings[earning.id] = earning;
            }

            dispatch({
                type: GET_WITHDRAWN_EARNINGS_SUCCESS,
                payload: {
                    withdrawnEarnings,
                    count: withdrawalsCount,
                    walletAmount
                },
            });

            dispatch({
                type: GET_CREDITED_EARNINGS_SUCCESS,
                payload: {
                    creditedEarnings,
                    count: creditedCount,
                    walletAmount
                },
            });
        } catch (err) {
                console.error("ERROR OCCURRED WHILE FETCHING EARNINGS", err);
                dispatch({
                    type: GET_EARNINGS_ERROR,
                    payload: {
                        errorMessage: err.message,
                    },
                });
        }
    };
};
import API from '../config/api';
// import { act } from 'react-dom/test-utils';
//Actions
const GETTING_VERIFICATIONS = "GETTING_VERIFICATIONS";
const GET_VERIFICATIONS_SUCCESS = "GET_VERIFICATIONS_SUCCESS";
const GET_VERIFICATIONS_ERROR = "GET_VERIFICATIONS_ERROR";

const VERIFYING_PARKING = "VERIFYING_PARKING";
const VERIFYING_SUCCESS = "VERIFYING_SUCCESS";
const VERIFYING_ERROR = "VERIFYING_ERROR";
const REFETCH_VERIFICATION = 'REFETCH_VERIFICATION';

export const refetchVerification = (bool) => {
    return {
        type: REFETCH_VERIFICATION,
        payload: {
            bool,
        },
    };
};

//Acttion Creators
export const getVerifications = (token) => {
    return dispatch => {
        dispatch({
            type: GETTING_VERIFICATIONS,
        });

        API.get(`/parkings?verification_requests=true`, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
        })
        .then(({ data }) => {
            // console.dir(data);
            dispatch({
                type: GET_VERIFICATIONS_SUCCESS,
                payload: {
                    verification_requests:{
                        ...data
                    }
                },
            });
        })
        .catch(err => {
            console.error('ERROR while receiving parking data', err);
            dispatch({
                type: GET_VERIFICATIONS_ERROR,
                payload: {
                    err: err.response ? err.response.data : err,
                    errorMessage: err.response ? err.response.data ? err.response.data.error ? err.response.data.error.info : "Error" : "Error" : "Error",
                },
            });
        });

    };
};

export const verifyParking = (token, parkingId, status,rejection_reason=null) => {
    return dispatch => {
        dispatch({
            type: VERIFYING_PARKING,
        });
        const parcel = {
            data:{
                addressData:{},
                parkingData:{
                    status,
                    rejection_reason: rejection_reason,
                },
            },
        };
        API.put(`/parkings?parkingId=${parkingId}`, parcel, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
        })
        .then(({data}) => {
            console.dir(data);
            dispatch({
                type: VERIFYING_SUCCESS,
            });
        })
        .catch(e => {
            console.error(e);
            dispatch({
                type: VERIFYING_ERROR,
                payload: {
                    err: e.response ? e.response.data : e,
                    errorMessage: e.response ? e.response.data ? e.response.data.error ? e.response.data.error.info : "Error" : "Error" : "Error",
                },
            });
        });
    };
};

//Reducers
const verificationsInitState = {
    total_verifications: 0,
    loading: false,
    error: null,
    success:false,
    refetch: false,
};
export const verificationsReducer  = (state=verificationsInitState, action) => {
    switch(action.type) {
        case GETTING_VERIFICATIONS:
            return { ...verificationsInitState, loading: true };
        case GET_VERIFICATIONS_SUCCESS:
            let counter = 0;
            Object.keys(action.payload.verification_requests.result).map(i => counter++ );
            return { ...state, loading: false, ...action.payload.verification_requests.result, total_verifications: counter, success:true };
        case GET_VERIFICATIONS_ERROR:
            return { ...state, loading: false, error: 'Unknown Error!', success:false };

        case REFETCH_VERIFICATION:
            
            // let { [action.payload.id] : num, ...modOldState } = state;
            return {
                // ...modOldState,
                ...state,
                refetch: action.payload.bool,
            }
        default:
            return state;
    }
}

export const rejectionReasonUpdate = (val) => {
    return {
        type: 'REJECTION_REASON_UPDATE',
        payload: {
            val,
        },
    };
};

export const clearVerifyFields = () => {
    return {
        type: "CLEAR_VERIFY_FIELDS",
    };
};

const verifyInitState = {
    response: null,
    success: false,
    loading: false,
    error: null,
    rejection_reason: null,
};

export const verifyReducer  = (state=verifyInitState, action) => {
    switch(action.type) {
        case VERIFYING_PARKING:
            return { ...state, loading: true, error: null }
        case VERIFYING_SUCCESS:
            return { ...state, loading: false, success: true, }
        case GET_VERIFICATIONS_ERROR:
            return { ...state, loading: false, error: 'Unknown Error!'};
        case 'CLEAR_VERIFY_FIELDS':
            return {
                ...verifyInitState,
            };
        case 'REJECTION_REASON_UPDATE':
            return {
                ...state,
                rejection_reason: action.payload.val,
            };
        default:
            return state;
    }
}
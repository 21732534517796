import * as moment from "moment";

export const toUTCStamp = (timeString) => {
  return moment(timeString, "HH:mm").utc().format("HH:mm");
};

// export const toLocalStamp = (utcTimeString) => {
//   const utc = moment.utc(utcTimeString, 'HH:mm').toDate();
//   let _utc = moment(utc).local().format('HH:mm');
//   return _utc;
// };

// modified again by reaper for better parsing
export const toLocalStamp = (timeString) => {
  return moment(timeString, "HH:mm").format("HH:mm:ss");
};

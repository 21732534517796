import React, {lazy, useEffect} from 'react';
import Layout from "../../components/shared/layout";
const Dashboard = lazy(() => import('../../components/dashboard'));
// import Loading from '../shared/loading/spinner';
// import { getParkings } from '../../reduxModules/parkings';

export default function Home() {
    // If total_no_parkings is null, that means the app is still loading, wait for GET PARKINGS
    // TO FINISH, THEN total_no_parkings will have the value 0.

    // useEffect(() => {
    //     if(props.role !== 'admin'){
    //         props.getParkings(props.token);
    //     }
    // }, []);

    // if (props.total_no_parkings === null && props.role !== 'admin') {
    //     return <Loading />;
    // }

    return (
        <Layout>
            <Dashboard />
        </Layout>
    );
};

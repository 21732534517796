import API from "../config/api";
const WITHDRAWING_A_BOOKING = "WITHDRAWING_A_BOOKING";
const GET_WITHDRAWALS = "GET_WITHDRAWALS";
const GET_WITHDRAWALS_SUCCESS = "GET_WITHDRAWALS_SUCCESS";
const WITHDRAWING_BOOKINGS = "WITHDRAWING_BOOKINGS";


export const getAllWithdrawals = (
  token,
  status,
  pageNumber = null,
  itemsPerPage = null,
  searchTerm = null
) => {

  return (dispatch) => {

    dispatch({ type: GET_WITHDRAWALS });

    const recordStatuses = status === "pending" ? "PROCESSING" : "PAID,FAILED";

    const queryParams = {};
    if (recordStatuses) {
      queryParams.statuses = recordStatuses;
    }
    if (pageNumber !== undefined && pageNumber !== null && itemsPerPage) {
      queryParams.offset = (pageNumber - 1) * itemsPerPage;
    }
    if (itemsPerPage) {
      queryParams.limit = itemsPerPage;
    }
    if (searchTerm) {
      queryParams.search_term = searchTerm;
    }

    const uri = `/bookings/withdrawals`;

    API.get(uri, {
      headers: {
        "X-AUTH-TOKEN": token,
      },
      params: queryParams
    })
      .then(({ data }) => {

        const withdrawals = {};
        let count = 0;
        if (data && data.success && data.result && data.result.withdrawals) {
          let response = data.result.withdrawals;
          response.forEach((item) => (withdrawals[item.id] = item));
          count = data.result.count;
        }

        dispatch({
          type: GET_WITHDRAWALS_SUCCESS,
          payload: {
            pendingWithdrawals: status === "pending" ? withdrawals : null,
            pendingWithdrawalsCount: status === "pending" ? count : null,
            completedWithdrawalsCount: status === "completed" ? count : null,
            completedWithdrawals: status === "completed" ? withdrawals : null,
            withdrawStatus: status,
            withdrawals
          },
        });
      })
      .catch((err) => {
        console.error("ERROR WHILE GETTING WITHDRAWALS");
        dispatch({
          type: "GET_WITHDRAWALS_ERROR",
          payload: {
            errorMessage: err.message,
          },
        });
      });
  };
};
export const clearWithdrawals = () => {
  return {
    type: 'CLEAR_WITHDRAWALS'
  };
};

export const markAWithdrawalCompleted = (
  token, requestIds = null,
  pic, transactionMainId = null
) => {

  return async (dispatch) => {

    if (!pic) {
      console.log("Invoice Screenshot is neccssary!");
      return
    }

    try {

      let uri = `/bookings/withdraw`;
      const data = new FormData();
      data.append('path', pic);
      if (requestIds) {
        uri += `?transaction_ids=${requestIds}`;
      }
      const config = {
        headers: {
          "X-AUTH-TOKEN": token,
        }
      }
      let response = await API.patch(uri, data, config);

      dispatch({
        type: "MARKING_COMPLETED_SUCCESS",
        payload: {
          booking_id: transactionMainId,
        },
      });


    } catch (error) {
      console.log({ error });
      dispatch({
        type: "MARKING_COMPLETED_ERROR",
        payload: {
          booking_id: transactionMainId,
          errorMessage: error.message,
        },
      });
    }

  };
};


const withdrawABooking = (token, booking_ids, selectAllClicked = false) => {

  if (!booking_ids || !booking_ids.length) {
    return
  }

  return (dispatch) => {

    dispatch({
      type: WITHDRAWING_A_BOOKING,
      payload: { booking_ids }
    });

    let uri = "/bookings/withdraw";

    if (booking_ids) {
      uri += `?booking_ids=${!selectAllClicked ? booking_ids : 'all'}`;
    }

    API.post(uri, null, {
      headers: {
        "X-AUTH-TOKEN": token,
      }
    })
      .then(({ data }) => {
        dispatch({
          type: "WITHDRAWING_A_BOOKING_SUCCESS",
          payload: {
            booking_ids,
          },
        });
      })
      .catch((err) => {
        console.error("ERROR OCURRED WHILE WITHDRAWING A BOOKING");
        dispatch({
          type: "WITHDRAWING_A_BOOKING_FAILED",
          payload: {
            booking_ids,
            errorMessage: err.message,
          },
        });
      });
  };
};

export const withdraw = (token) => {
  return (dispatch, getState) => {

    dispatch({
      type: WITHDRAWING_BOOKINGS,
    });

    const { selectedEarnings, allCreditedEarnings, selectAllClicked } = getState().app;
    const { creditedEarnings } = getState();

    const {
      loading,
      error,
      count: creditedCount,
      earnings: _creditedEarnings,
      walletAmount,
      ...modCreditedEarnings
    } = creditedEarnings;

    const bookingIds = [];
    for (const key in selectedEarnings) {
      if (selectedEarnings[key]) {
        if (allCreditedEarnings[key] || modCreditedEarnings[key]) {
          let singleBooking = allCreditedEarnings[key] || modCreditedEarnings[key];
          bookingIds.push(singleBooking.booking_id)
        }
      }
    }

    bookingIds.forEach((earning_id, index) => {
      dispatch({
        type: "PARTIALS_WITHDRAWALS",
        payload: {
          percentage: Math.round(
            ((index + 1) / bookingIds.length) * 100
          )
        }
      })
    })

    if (bookingIds && bookingIds.length) {
      dispatch(withdrawABooking(token, bookingIds, selectAllClicked));
    }

  };
};

export const clearReqProgress = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_REQ_PROGRESS"
    })
  }
}


const initState = {
  loading: false,
  error: null,
  hostError: [],
  requestsProgress: 0,
  markErrors: {},
  markSuccess: {},
  pics: {},
  withdrawals: {},
  pendingWithdrawals: {},
  completedWithdrawals: {},
  withdrawStatus: "",
  pendingWithdrawalsCount: 0,
  completedWithdrawalsCount: 0,
};

export default function (state = initState, action) {
  switch (action.type) {

    case "GET_WITHDRAWALS":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "GET_WITHDRAWALS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        withdrawals: { ...state.withdrawals, ...action.payload.withdrawals },
        pendingWithdrawals: action.payload.pendingWithdrawals !== null ? action.payload.pendingWithdrawals : state.pendingWithdrawals,
        completedWithdrawals: action.payload.completedWithdrawals !== null ? action.payload.completedWithdrawals : state.completedWithdrawals,
        withdrawStatus: action.payload.withdrawStatus,
        pendingWithdrawalsCount: action.payload.pendingWithdrawalsCount !== null ? action.payload.pendingWithdrawalsCount : state.pendingWithdrawalsCount,
        completedWithdrawalsCount: action.payload.completedWithdrawalsCount !== null ? action.payload.completedWithdrawalsCount : state.completedWithdrawalsCount,
      };

    case "GET_WITHDRAWALS_ERROR":
      return {
        ...state,
        error: action.payload.errorMessage,
        loading: false,
      };
    case "WITHDRAWING_BOOKINGS":
      return {
        ...state,
        error: null,
        loading: false,
        hostError: [],
        requestsProgress: 0,
      };
    case "WITHDRAWING_A_BOOKING_FAILED":
      return {
        ...state,
        error: null,
        loading: false,
        hostError: [...state.hostError, action.payload.booking_id],
      };
    case "RECEIPT_UPLOAD_SUCCESS":
      return {
        ...state,
        pics: {
          ...state.pics,
          [action.payload.id]: action.payload.url,
        },
      };
    case "PARTIALS_WITHDRAWALS":
      return {
        ...state,
        error: null,
        loading: false,
        requestsProgress: action.payload.percentage,
      };

    case "CLEAR_REQ_PROGRESS":

      return {
        ...state,
        requestsProgress: 0
      }

    case "MARKING_COMPLETED_SUCCESS":
      return {
        ...state,
        markSuccess: {
          ...state.markSuccess,
          [action.payload.booking_id]: "SUCCESS"
        },
      };
    case "MARKING_COMPLETED_ERROR":
      return {
        ...state,
        markErrors: {
          ...state.markErrors,
          [action.payload.booking_id]: "FAILED"
        },
      };
    case 'CLEAR_WITHDRAWALS':
      return initState;
    default:
      return state;
  }
}

export const clearUploadReceipt = () => {
  return {
    type: "CLEAR_UPLOAD_RECEIPT",
  };
};

const uploadInitState = {
  uploading: false,
  uploadSuccess: false,
  uploadError: null,
  path: null,
};
export const uploadReceipt = (state = uploadInitState, action) => {
  switch (action.type) {
    case "RECEIPT_UPLOAD_RUNNING":
      return {
        ...uploadInitState,
        uploading: true,
      };
    case "RECEIPT_UPLOAD_ERROR":
      return {
        ...uploadInitState,
        uploadError: action.payload.errorMessage,
      };
    case "RECEIPT_UPLOAD_SUCCESS":
      return {
        ...uploadInitState,
        uploadSuccess: true,
      };
    case "CLEAR_UPLOAD_RECEIPT":
      return {
        ...uploadInitState,
      };
    default:
      return state;
  }
};

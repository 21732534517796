import React, { useEffect, useMemo, useState } from "react"
import DatePicker from "react-calendar"
import 'react-calendar/dist/Calendar.css';
import moment from "moment"
import Select from "react-select"
import { getStyles } from "./byParkingArea";
import { useFieldValue } from "../../reduxModules/tools/fieldValue";
export const GLOBAL_FIELD_FILTER_BY_DATE_RANGE = 'field-filter-by-date-range-global';
export function FilterByDateRange({id=GLOBAL_FIELD_FILTER_BY_DATE_RANGE}) {
    const dateRangeOptions = useDateRangeOptions()
    const [,dateFilter, setdateFilter] = useFieldValue(id, dateRangeOptions[0])
    
    const [localDateFilter, setLocalDateFilter] = useState(deserializeDates(dateFilter));
    const openCalender = localDateFilter.label === 'Custom'
    useEffect(() => {
        if(!openCalender)
            setdateFilter(serializeDates(localDateFilter))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localDateFilter])
    return <>
    <div className="d-flex flex-column filter align-self-baseline">
        <p className="label"> Filter By Date  </p>
        <Select
            styles={getStyles()}
            value={localDateFilter}
            isOptionSelected={(option) => option.index === localDateFilter.index}
            style={{width:'fit-content'}}
            options={dateRangeOptions}
            className="select" 
            onChange={(selected) => {setLocalDateFilter(selected)}}
    ></Select>
    </div>
    { openCalender && <span style={{top:'calc(100% + 5px )', right:0, zIndex: 11}} className={"position-absolute"}>
        <DatePicker
            returnValue="range"
            value={localDateFilter.value.map(x => new Date(x))}
            selectRange={true}
            onChange={range => setLocalDateFilter({...localDateFilter, label:renderFormattedDateRange(range), value: range})}
            />
    </span>
    }

    </>
    function renderFormattedDateRange(dateRange) {
        const formatter = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric"
        })
        return formatter.formatRange(...dateRange)
    }
    function serializeDates(option) {
        return {
            ...option,
            value: option.value.map(x => x && x.toISOString())
        }
    }
    function deserializeDates(option) {
        return {
            ...option,
            value: option.value.map(x => x && new Date(x))
        }
    }
}
function getDateRangeOptions() {
    const thisWeek = moment().startOf("week")
    const thisMonth = moment().startOf("month")
    const last12Months = moment().subtract(12, "months").startOf("month")
    const lastMonth = moment().subtract(1, "month").startOf("month")

    return [
        {
            label: "All Time",
            value: [],
            index: 0
        },
        {
            label: "This Week",
            value: [thisWeek.toDate(), thisWeek.clone().endOf("week").toDate()],
            index: 1
        },
        {
            label: "This Month",
            value: [thisMonth.toDate(), thisMonth.clone().endOf("month").toDate()],
            index: 2
        },
        {
            label: "Last Month",
            value: [lastMonth.toDate(), lastMonth.clone().endOf("month").toDate()],
            index: 3
        },
        {
            label: "Last 12 Months",
            value: [last12Months.toDate(), moment().toDate()],
            index: 4
        },
        { label: "Custom", value: [moment().startOf("day"), moment().endOf("day")], index: 5 }
    ]
}
export function useDateRangeOptions() {
    return useMemo(() => getDateRangeOptions(), [])
}
import { baseFetchQueryV3, qs } from "../../../config/api";
import { baseApi } from "../..";

export const earningsAPI = baseApi.injectEndpoints({
    reducerPath: 'v3/earnings',
    baseQuery: baseFetchQueryV3(),
    endpoints: (builder) => ({
        getEarningsInWallet: builder.query({
            query: ({limit, offset,date_group_by,group_by_host, date_range, parking_ids, host_ids, status}) => {
                return {
                    url: `/transactions/earnings?${qs({
                        limit,
                        offset,
                        date_group_by,
                        group_by_host,
                        date_range,
                        parking_ids,
                        host_ids,
                        status
                    })}`,
                }
            },
            transformResponse: response => ({ count: response.result.count, data: response.result.earnings }),
            providesTags: ['Earnings']
        }),
        getEarningsInWalletAmount: builder.query({
            query: ({date_range, parking_ids, host_ids}) => {
                return {
                    url: `/transactions/earnings/amount?${qs({
                        date_range,
                        parking_ids,
                        host_ids,
                    })}`
                }
            },
            transformResponse: response => response.result,
            providesTags: ['Earnings']
        }),
    })
})

export const { useGetEarningsInWalletQuery, useGetEarningsInWalletAmountQuery } = earningsAPI;
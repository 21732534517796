import React from "react";
import { useHistory } from 'react-router-dom';
import NoData from "../../../Assets/imgs/nodata.svg";
import BlockLoader from "../loading/inProgress";

/**
 * @deprecated Use DataTable instead
*/
const CustomContent = (props) => {
  
const history = useHistory();
  if(props.loading) {
     return <BlockLoader/>
  }
  if (
    props.rows === undefined || props.rows.length === 0 ||
    (Object.keys(props.rows).length === 0 && props.rows.constructor === Object)
  ) {
    return (
      <div className="container text-center no-content">
        <img
          src={NoData}
          alt="No Data"
          className="align-middle"
          width="200px"
        />
        <p className="text-muted align-middle">No results to show!</p>
      </div>
    );
  }

  let table_rows_data = props.rows instanceof Array ? props.rows : Object.values(props.rows)
  if(props.do_sort_by_id){
    table_rows_data = Object.values(props.rows).sort((x, y) => y.id - x.id)
  }

  
  return (
    <div className="table-responsive global_table">
      <table className="table table-hover">
        {/* Column headers */}
        <thead className="">
          <tr>
            {props.columns.map((item, index) => {
              return (
                <th scope="col" key={new Date().getTime() + index}>
                  {item.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {table_rows_data
            .map((item, index) => {
              return (
                <tr key={index}>
                  {props.rowItems.map((flows, index) => {
                    return (
                      <td key={new Date().getTime() + index}>
                          {
                              item ? (item[flows.label] !== undefined || flows.label === '')
                              ? flows.component({...item, navigate: () => history.push(`${props.slug}/${item.id}`), actionFunc: props.apply, secondaryActionFunc: props.reapply , doRefesh : props.retryUpload })
                                : "No data"
                            : "Incorrect Data"}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomContent;
export function DataTable({colDefs, rows = [], actions={}, loading}) {
  return <div className="table-responsive global_table">
      <table className="table table-hover">
      <thead>
        <tr>
          {colDefs.map((item, index) => {
            return (
              <th scope="col" key={index}>
                {typeof item.label === 'function' ? item.label({actions}) : item.label}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
          {
           loading ? <tr><td colSpan={colDefs.length}><BlockLoader/></td></tr>
           : rows.length === 0 ? <NoDataBanner/>
           : rows
            .map((item, index) => {
              return (
                <tr key={index}>
                  {colDefs.map((colDef, index) => {
                    return (
                      <td key={index}>
                          {
                            colDef.component ?
                            <colDef.component {...item} actions={actions} />
                            : null
                          }
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
    </table>
  </div>;

function NoDataBanner(){
  return (
    <tr><td colSpan={colDefs.length}>
      <div className="container text-center no-content">
      <img
        src={NoData}
        alt="No Data"
        className="align-middle"
        width="200px"
      />
      <p className="text-muted align-middle">No results to show!</p>
    </div>
      </td></tr>
  );
}
}


/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import ChevronRight from "./../../../Assets/imgs/Chevron_Right.png";
import ChevronLeft from "./../../../Assets/imgs/Chevron_Left.png";
import { useFieldValue } from '../../../reduxModules/tools/fieldValue';

const propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
}

const defaultProps = {
    initialPage: 1,
    pageSize: 10
}

/**
 * @deprecated Use `Paginator` instead
 */
class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pager: {} };
    }

    componentWillMount() {
        // set page if items array isn't empty
        if (this.props.items && this.props.items.length) {
            this.setPage(this.props.initialPage);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // reset page if items array has changed
        if (this.props.items !== prevProps.items) {
            this.setPage(this.props.initialPage);
        }
    }

    setPage(page) {
        let { items, pageSize, totalItems } = this.props;
        let pager = this.state.pager;

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        // get new pager object for specified page
        pager = this.getPager(totalItems ? totalItems : items.length, page, pageSize);

        // get new page of items from items array
        let pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        this.setState({ pager: pager });

        // call change page function in parent component
        this.props.onChangePage(pageOfItems, page);
        if(page !== this.props.initialPage) {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        let startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    render() {
        let pager = this.state.pager;

        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }

        return (
            <ul className="pagination global_pagination justify-content-center mt-3">
                <li className={`p-2 ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                    <a  onClick={() => this.setPage(1)}>
                        <img src={ChevronLeft} width={7} alt='Left' />
                    </a>
                </li>
                <li className={`p-2 ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                    <a  className="green-text font-m semi-bold" onClick={() => this.setPage(pager.currentPage - 1)}>Prev</a>
                </li>
                {pager.pages.map((page, index) =>
                    <li key={index} className={`p-2 ${pager.currentPage === page ? 'active' : ''}`}>
                        <a  className="font-m" onClick={() => this.setPage(page)}>{page}</a>
                    </li>
                )}
                <li className={`p-2 ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                    <a  className="green-text font-m semi-bold" onClick={() => this.setPage(pager.currentPage + 1)}>Next</a>
                </li>
                <li className={`p-2 ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                    <a  onClick={() => this.setPage(pager.totalPages)}>
                        <img src={ChevronRight} width={7} alt='Right' />
                    </a>
                </li>
            </ul>
        );
    }
}
export const usePaginator = ({
  id = 'field-pagination-'+ Math.random().toString(36).substring(7),
  total = 0,
  onPageChange = null,
  defaultPageNumber = 1,
  defaultPageSize = 10,
  noScrollTop = false
} = {},dependency=[]) => {
    const [,page,setPage] = useFieldValue(id, {pageNumber: defaultPageNumber, pageSize: defaultPageSize, total})
    const maxPage = Math.ceil(page.total / page.pageSize);
    useEffect(() => {
        setPage({...page, total})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total])
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        onPageChange?.(page)
        if(!noScrollTop) {
            scrollToTop();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page,onPageChange])
    useEffect(() => {
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependency)
    return {...page, setPageNumber, maxPage, setPageSize, reset, id}


    // private functions
    function reset() {
        return setPage({ pageNumber: defaultPageNumber, pageSize: defaultPageSize, total })
    }
    function setPageNumber(pageNumber) {
        return setPage({ ...page, pageNumber });
    }
    function setPageSize(pageSize) {
        return setPage({ ...page, pageSize });
    }
}
export function Paginator({pageNumber, setPageNumber, pageSize, maxPage,  maxNeighbors=9}) {
    return <ul className="pagination global_pagination justify-content-center mt-3">
            {renderGotoFirst()}
            {renderPrev()}
            {Array.from({length: maxNeighbors*2 + 1}, (_, i) => pageNumber - (maxNeighbors - i)).filter(page => page > 0 && page <= maxPage).map(renderPage)}
            {renderNext()}
            {renderGotoLast()}
    </ul>
    function renderGotoFirst() {
        return <li className={`p-2 ${pageNumber <= 1 ? 'disabled pe-none' : ''}`}>
            <a onClick={() => setPageNumber(1)}>
                <img src={ChevronLeft} width={7} alt='Left' />
            </a>
        </li>
    }
    function renderGotoLast() {
        return <li className={`p-2 ${pageNumber >= maxPage ? 'disabled pe-none' : ''}`}>
            <a onClick={() => setPageNumber(maxPage)}>
                <img src={ChevronRight} width={7} alt='Right' />
            </a>
        </li>
    }
    function renderPrev() {
        return <li className={`p-2 ${pageNumber <= 1 ? 'disabled pe-none' : ''}`}>
            <a className="green-text font-m semi-bold" onClick={() => setPageNumber(pageNumber - 1)}>Prev</a>
        </li>
    }
    function renderNext() {
        return <li className={`p-2 ${pageNumber >= maxPage ? 'disabled pe-none' : ''}`}>
            <a className="green-text font-m semi-bold" onClick={() => setPageNumber(pageNumber + 1)}>Next</a>
        </li>
    }
    function renderPage(page) {
        return <li key={page} className={`p-2 ${pageNumber === page ? 'active pe-none' : ''}`}>
            <a className="font-m" onClick={() => setPageNumber(page)}>{page}</a>
        </li>
    }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;


function scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'})
}
import { SWITCHED_STATE } from "./parkings";
const SHOW_STATUS = "SHOW_STATUS";
const SET_BASIC_PROFILE = "SET_BASIC_PROFILE";
const SELECT_EARNING = "SELECT_EARNING";
const UNSELECT_EARNING = "UNSELECT_EARNING";
const SELECT_ALL_CREDIT_EARNINGS = "SELECT_ALL_CREDIT_EARNINGS";
const UNSELECT_ALL = "UNSELECT_ALL";
const SHOW_PROFILE_MODAL = "SHOW_PROFILE_MODAL";
const SHOW_EVENT_MODAL = "SHOW_EVENT_MODAL";
const NEW_PARKING_DETAILS = "NEW_PARKING_DETAILS";

// Actions
// firebase deploy --only hosting:host-dev 


export const showStatus = (val) => {
  return {
    type: SHOW_STATUS,
    payload: {
      val,
    },
  };
};

export const setBasicProfile = (val) => {
  return {
    type: SET_BASIC_PROFILE,
    payload: {
      val,
    },
  };
};

export const selectCreditEarning = (id, val) => {
  return {
    type: SELECT_EARNING,
    payload: {
      id,
      val,
    },
  };
};

export const unselectCreditEarning = (id) => {
  return {
    type: UNSELECT_EARNING,
    payload: {
      id,
    },
  };
};

export const showProfileModal = (val) => {
  return {
    type: SHOW_PROFILE_MODAL,
    payload: {
      val,
    },
  };
};

export const showUploadModal = (val, id) => {
  return {
    type: 'SHOW_UPLOAD_MODAL',
    payload: { val, id },
  };
};

export const showEventModal = (val) => {
  return {
    type: SHOW_EVENT_MODAL,
    payload: {
      val,
    },
  };
};

export const showDeclineModal = (val, id) => {
  return {
    type: "SHOW_DECLINE_MODAL",
    payload: {
      val,
      id,
    },
  };
};

/*





    REDUCERS





*/

const appInitState = {
  redirectToStatusPage: false,
  new_parking_id: null,
  redirectToNamePage: false,
  switchToggle: false,

  selectedEarnings: {},
  totalWithdrawAmt: 0,
  numberItemsSelected: 0,
  allCreditedEarnings: {},
  selectAllClicked: false,

  showProfileModal: false,
  show_upload_modal: false,
  show_upload_modal_id: null,
  showEventModal: null,
  show_decline_modal: false,
  declining_id: null,
  loginOnStart: false,
};
export default function appReducer(state = appInitState, action) {
  switch (action.type) {

    case SHOW_STATUS:
      return {
        ...state,
        redirectToStatusPage: action.payload.val,
      };

    case NEW_PARKING_DETAILS:
      return {
        ...state,
        new_parking_id: action.payload.newParking.id,
      };

    case SET_BASIC_PROFILE:
      return {
        ...state,
        redirectToNamePage: action.payload.val,
      };

    case SWITCHED_STATE:
      console.log(state.switchToggle);
      return {
        ...state,
        switchToggle: !state.switchToggle,
      };

    case SELECT_EARNING:

    if(action.payload.val){
      const selectedArry = Object.values(state.selectedEarnings);
      if (selectedArry.length) {
        let noOfSelected = selectedArry.filter(item => item).length;
        if (noOfSelected >= 20) {
          alert("You can select only 20 at once!")
          return state;
        }
      }
    }

      return {
        ...state,
        selectedEarnings: {
          ...state.selectedEarnings,
          [action.payload.id]: action.payload.val,
        },
        numberItemsSelected: state.numberItemsSelected + 1,
        selectAllClicked: false
      };

    case UNSELECT_EARNING:
      let sE = state.selectedEarnings;
      delete sE[action.payload.id];
      return {
        ...state,
        selectedEarnings: sE,
        numberItemsSelected: state.numberItemsSelected - 1,
        selectAllClicked: false
      };

    case SELECT_ALL_CREDIT_EARNINGS:

      let allE = {};
      let amt = 0;

      const _creditedEarnings = action.payload.earnings;
      const _filteredEarnings = _creditedEarnings.filter(item => item.id && item.status === "PAID" && (new Date(item.valid_to) < new Date()));
      let arr = _filteredEarnings.map((item) => item.id);
      let amounts = _filteredEarnings.map((item) => item.transaction_amount_to_host);

      for (let i = 0; i < arr.length; i++) {
        allE[arr[i]] = true;
        amt += Number(amounts[i]);
      }

      let creditedEarningsObj = {};
      for (let earning of _creditedEarnings) {
        creditedEarningsObj[earning.id] = earning;
      }

      return {
        ...state,
        selectedEarnings: allE,
        totalWithdrawAmt: amt,
        numberItemsSelected: arr.length,
        allCreditedEarnings: creditedEarningsObj,
        selectAllClicked: true
      };

    case UNSELECT_ALL:
      return {
        ...state,
        selectedEarnings: {},
        totalWithdrawAmt: 0,
        numberItemsSelected: 0,
        selectAllClicked: false
      };

    case SHOW_PROFILE_MODAL:
      return {
        ...state,
        showProfileModal: action.payload.val,
      };

    case SHOW_EVENT_MODAL:
      return {
        ...state,
        showEventModal: action.payload.val,
      };

    case "SHOW_DECLINE_MODAL":
      return {
        ...state,
        show_decline_modal: action.payload.val,
        declining_id: action.payload.id,
      };

    case "SHOW_UPLOAD_MODAL":
      return {
        ...state,
        show_upload_modal: action.payload.val,
        show_upload_modal_id: action.payload.id,
      };

    default:
      return state;
  }
}

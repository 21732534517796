import { api2 } from "../../config/api";

const GET_WITHDRAWALS_LOADING = "GET_WITHDRAWALS_LOADING";
const GET_WITHDRAWALS_SUCCESS = "GET_WITHDRAWALS_SUCCESS";
const GET_WITHDRAWALS_ERROR = "GET_WITHDRAWALS_ERROR";
/**
 * @example {
 *  loading: false,
 *  error: null,
 *  data: [{
 *      date: "2020-10-10",
 *      amount: 1100,
 *      transactionCount: 2,
 *      receipts_ids: [1,2],
 *      withdrawn_by: [100],
 *      parking_ids: [1,2],
 *  }
 */

const initState = {
    loading: false,
    error: null,
    data: {
      total: 0,
      withdrawals: []
    },
    pageNumber: 1,
    pageSize: 10,
}
export default function WithdrawalsReducer(state = initState, action) {
  switch (action.type) {
    case GET_WITHDRAWALS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload,
      };
    case GET_WITHDRAWALS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      default:
        return state;
  }
}
export function getWithdrawals(pageNumber, pageSize=10) {
  return async (dispatch, getState) => {
    const url = `/bookings/withdrawals`;
    const token = getState().auth.auth_token;
    const parkingsFilter = getState().filters.parking_area.join(",");
    const dateFilter = getState().filters.date_filter.value?.map((date) => Number(date)).join(",");
    try {
        const params = {}
        if (parkingsFilter) {
            params.parkings = parkingsFilter;
        }
        if (dateFilter) {
            params.date_range = dateFilter;
        }
        if (pageNumber) {
            params.limit = pageSize ;
            params.offset = (pageNumber - 1) * pageSize;
        }
        dispatch({ type: GET_WITHDRAWALS_LOADING });
        let response = await api2.get(url, {
            headers: {
                'X-AUTH-TOKEN': token,
            },
            params: params
        });
        dispatch({
            type: GET_WITHDRAWALS_SUCCESS,
            payload: {
              data:response.data.result,
              pageNumber: pageNumber,
              pageSize: pageSize
            }
        });
    } catch (error) {
            dispatch({
                type: GET_WITHDRAWALS_ERROR,
                payload: error.message,
            });
        }
    };
}

const GET_WITHDRAWAL_PROCESS_LOADING = "GET_WITHDRAWAL_PROCESS_LOADING";
const GET_WITHDRAWAL_PROCESS_SUCCESS = "GET_WITHDRAWAL_PROCESS_SUCCESS";
const GET_WITHDRAWAL_PROCESS_ERROR = "GET_WITHDRAWAL_PROCESS_ERROR";
const initWithdrawalProcessState = {
    loading: false,
    completedAt: 0,
    error: null,
    data: []
}

export function WithdrawalProcessReducer(state = initState, action) {
  switch (action.type) {
    case GET_WITHDRAWAL_PROCESS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_WITHDRAWAL_PROCESS_SUCCESS:
      return {
        ...state,
        completedAt: Date.now(),
        loading: false,
        error: null,
        ...action.payload,
      };
    case GET_WITHDRAWAL_PROCESS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      default:
        return state;
  }
}


export function withdraw(ids) {
  // ids default to selection
  return async (dispatch, getState) => {
    const url = `/bookings/withdraw`;
    const token = getState().auth.auth_token;
    const areaFilter = getState().filters.parking_area;
    const dateFilter = getState().filters.date_filter.value?.map((date) => Number(date));
    ids = ids || getState().tools.selection.EARNING_TRANSACTIONS
    try {
        const data = {};
        if(ids.length) {
            data.transactionIds = [...ids];
        }
        if (areaFilter) {
            data.areaIds = areaFilter;
        }
        if (dateFilter) {
            data.dateRange = dateFilter;
        }

        dispatch({ type: GET_WITHDRAWAL_PROCESS_LOADING });
        let response = await api2.post(url,data, {
            headers: {
                'X-AUTH-TOKEN': token,
            }
        });
        dispatch({
            type: GET_WITHDRAWAL_PROCESS_SUCCESS,
            payload: {
              ...response.data.result
            }
        });
    } catch (error) {
            dispatch({
                type: GET_WITHDRAWAL_PROCESS_ERROR,
                payload: error.message,
            });
        }
    };
}